import { createReducer } from '@reduxjs/toolkit';
import { Column, Sort } from '../../../components/table';
import {
  resetCustomerFilters,
  searchCustomers,
  setCustomerFilters,
  sortCustomers,
  toggleCustomerColumn,
} from './actions';

export interface Rule {
  field: string;
  operator: string;
  value: string;
  values: string[];
}

export interface CustomerFilters {
  natures: number[];
  countries: string[];
  statuses: string[];
  priorities: number[];
  accountManagers: number[];
}

export interface State {
  search: string;
  filters: CustomerFilters;
  columns: Column[];
  sort: Sort;
}

export const customerTableColumns: Column[] = [
  { id: 'name', label: 'Name', visible: true },
  { id: 'alias', label: 'Alias', visible: true },
  { id: 'natures', label: 'Natures', visible: true },
  { id: 'countries', label: 'Countries', visible: true },
  { id: 'status', label: 'Status', visible: true },
  { id: 'priority', label: 'Priority', visible: true },
  { id: 'payment_problems', label: 'Payment', visible: true },
  { id: 'subscription_start', label: 'Subscription Date', visible: false },
  { id: 'subscription_end', label: 'Expiration Date', visible: false },
  { id: 'notes', label: 'Notes', visible: true },
  { id: 'platform_fee_start_date', label: 'Platform fee start date', visible: false },
  { id: 'strapi_url', label: 'Strapi url', visible: false },
  { id: 'lead_price', label: 'Lead price', visible: false },
  { id: 'discount', label: 'Discount', visible: false },
  { id: 'visma_id', label: 'Visma ID', visible: false },
  { id: 'account_manager', label: 'Account Manager', visible: false },
  { id: 'created_at', label: 'Created At', visible: false },
  { id: 'modified_at', label: 'Updated At', visible: false },
  { id: 'actions', label: '', visible: true, align: 'right' },
];

export const initialState: State = {
  search: '',
  filters: {
    natures: [],
    countries: [],
    statuses: [],
    priorities: [],
    accountManagers: [],
  },
  columns: customerTableColumns,
  sort: {
    column: 'name',
    direction: 'asc',
  },
};

export const customersTableReducer = createReducer<State>(initialState, (builder) => {
  builder
    .addCase(searchCustomers, (state, { payload }) => ({ ...state, search: payload.search }))
    .addCase(setCustomerFilters, (state, { payload }) => ({ ...state, filters: payload.filters }))
    .addCase(resetCustomerFilters, (state) => ({ ...state, filters: initialState.filters }))
    .addCase(toggleCustomerColumn, (state, { payload }) => ({
      ...state,
      columns: state.columns.map((column) => (column.id === payload.column.id ? payload.column : column)),
    }))
    .addCase(sortCustomers, (state, { payload }) => ({ ...state, sort: payload.sort }));
});
