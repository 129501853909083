import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeEvery } from 'redux-saga/effects';
import { FailurePayload, SuccessPayload } from '../../effects';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from '../../ui/notifications';
import {
  createDistributionSettingFailure,
  createDistributionSettingSuccess,
  loadDistributionSettingsFailure,
  recoverDistributionSettingFailure,
  recoverDistributionSettingRequest,
  recoverDistributionSettingSuccess,
  removeDistributionSettingFailure,
  removeDistributionSettingRequest,
  removeDistributionSettingSuccess,
  restoreDistributionSettingFailure,
  restoreDistributionSettingRequest,
  restoreDistributionSettingSuccess,
  updateDistributionSettingFailure,
  updateDistributionSettingSuccess,
} from './actions';
import { selectHistory } from './selectors';

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load', label: 'Retry', action: request }));
}

export function* addSuccess({ payload: { response } }: PayloadAction<SuccessPayload>) {
  const undoAction = removeDistributionSettingRequest({ id: response.data.id });
  yield put(showSuccessNotification({ message: 'Distribution settings added', label: 'Undo', action: undoAction }));
}

export function* addFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to add', label: 'Retry', action: request }));
}

export function* updateSuccess({ payload: { data } }: PayloadAction<SuccessPayload>): Generator {
  const entity = yield select(selectHistory(data.id));
  const undoAction = restoreDistributionSettingRequest({ id: data.id, body: entity });
  yield put(showSuccessNotification({ message: 'Distribution settings updated', label: 'Undo', action: undoAction }));
}

export function* updateFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to update', label: 'Retry', action: request }));
}

export function* removeSuccess({ payload: { data } }: PayloadAction<SuccessPayload>) {
  const undoAction = recoverDistributionSettingRequest({ id: data.id });
  yield put(showWarningNotification({ message: 'Distribution settings removed', label: 'Undo', action: undoAction }));
}

export function* removeFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to remove', label: 'Retry', action: request }));
}

export function* restoreSuccess() {
  yield put(showSuccessNotification({ message: 'Distribution settings restored' }));
}

export function* restoreFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to restore', label: 'Retry', action: request }));
}

export function* recoverSuccess() {
  yield put(showSuccessNotification({ message: 'Distribution settings recovered' }));
}

export function* recoverFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to recover', label: 'Retry', action: request }));
}

export function* effects() {
  yield takeEvery(loadDistributionSettingsFailure, loadFailure);
  yield takeEvery(createDistributionSettingSuccess, addSuccess);
  yield takeEvery(createDistributionSettingFailure, addFailure);
  yield takeEvery(updateDistributionSettingSuccess, updateSuccess);
  yield takeEvery(updateDistributionSettingFailure, updateFailure);
  yield takeEvery(removeDistributionSettingSuccess, removeSuccess);
  yield takeEvery(removeDistributionSettingFailure, removeFailure);
  yield takeEvery(restoreDistributionSettingSuccess, restoreSuccess);
  yield takeEvery(restoreDistributionSettingFailure, restoreFailure);
  yield takeEvery(recoverDistributionSettingSuccess, recoverSuccess);
  yield takeEvery(recoverDistributionSettingFailure, recoverFailure);
}
