import { createSelector } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { selectAll } from '../../entities/receiverLeads';
import { RootState } from '../../reducer';
import { sortBy } from '../incoming-leads-table';
import { State } from './reducer';

export const selectReceiverLeadsTable = (state: RootState) => state.ui.receiverLeadsTable;
export const selectSelectedReceiverLeads = createSelector(
  selectReceiverLeadsTable,
  ({ selected_leads }: State) => selected_leads
);
export const selectHasFilters = createSelector(
  selectReceiverLeadsTable,
  ({ filters }: State) => !Object.values(filters).every((filter: any) => !filter.length)
);

export const selectReceiverLeadsFromTable = createSelector(
  selectReceiverLeadsTable,
  selectAll,
  ({ sort, filters, filteredIds }: State, receiverLeads) => {
    const filteredOutgoingLeads = filteredIds
      ? receiverLeads.filter(({ id }) => filteredIds.includes(id))
      : receiverLeads;

    const sortColumn =
      receiverLeads.filter((lead) => {
        return sortBy(sort.column) in lead.lead_data;
      }).length > 0
        ? `lead_data[${sortBy(sort.column)}]`
        : receiverLeads.filter((lead) => {
            return sortBy(sort.column) in lead;
          }).length > 0
        ? sortBy(sort.column)
        : '';
    //check to see if sorting column exists in receivingLead or if if is a key in lead_data

    return orderBy(filteredOutgoingLeads, sortColumn, new Array(sortBy(sort.column).length).fill(sort.direction));
  }
);
