import { PayloadAction } from '@reduxjs/toolkit';
import { FailurePayload, SuccessPayload } from '../../effects';
import { put, select, takeEvery } from 'redux-saga/effects';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from '../../ui/notifications';
import {
  createConnectionFailure,
  createConnectionSuccess,
  loadConnectionsFailure,
  recoverConnectionFailure,
  recoverConnectionRequest,
  recoverConnectionSuccess,
  removeConnectionFailure,
  removeConnectionRequest,
  removeConnectionSuccess,
  updateConnectionRequest,
  updateConnectionsFailure,
  updateConnectionSuccess,
} from './actions';
import { selectHistory } from './selectors';
import { history } from "../../../history";

export function* addSuccess({ payload: { response } }: PayloadAction<SuccessPayload>) {
  const undoAction = removeConnectionRequest({ id: response.data.id });
  yield put(showSuccessNotification({ message: 'Connection added', label: 'Undo', action: undoAction }));
  history.push(`/dev-tools/connections/${response.data.id}`);
}

export function* addFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to add connection', label: 'Retry', action: request }));
}

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load connections', label: 'Retry', action: request }));
}

export function* updateSuccess({ payload: { data } }: PayloadAction<SuccessPayload>) {
  const { id, name, code, protocol} = yield select(selectHistory(data.id));
  const undoAction = updateConnectionRequest({
    id,
    body: {
      name,
      code,
      protocol
    }
  })
  yield put(showSuccessNotification({ message: 'Connection updated', label: 'Undo', action: undoAction }));
}

export function* updateFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to update connection', label: 'Retry', action: request }));
}

export function* removeSuccess({ payload: { data } }: PayloadAction<SuccessPayload>) {
  const undoAction = recoverConnectionRequest({ id: data.id });
  yield put(showWarningNotification({ message: 'Connection removed', label: 'Undo', action: undoAction }));
}

export function* removeFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to remove', label: 'Retry', action: request }));
}

export function* recoverSuccess() {
  yield put(showSuccessNotification({ message: 'Connection recovered' }));
}

export function* recoverFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to recover', label: 'Retry', action: request }));
}


export function* effects() {
  yield takeEvery(createConnectionSuccess, addSuccess);
  yield takeEvery(createConnectionFailure, addFailure);
  yield takeEvery(loadConnectionsFailure, loadFailure);
  yield takeEvery(updateConnectionSuccess, updateSuccess);
  yield takeEvery(updateConnectionsFailure, updateFailure)
  yield takeEvery(removeConnectionSuccess, removeSuccess);
  yield takeEvery(removeConnectionFailure, removeFailure);
  yield takeEvery(recoverConnectionSuccess, recoverSuccess);
  yield takeEvery(recoverConnectionFailure, recoverFailure);
}
