import { createRequestActions, makeHttpPayload } from '../../effects';
import { Id } from '../adapter';
import { SpamFilter } from './reducer';

export type SpamFilterData = Partial<Exclude<SpamFilter, 'id'>>;

export const {
  request: loadSpamFiltersRequest,
  success: loadSpamFiltersSuccess,
  failure: loadSpamFiltersFailure,
} = createRequestActions('LOAD_SPAM_FILTERS', () => ({
  http: makeHttpPayload('get', 'spam-filters'),
}));

export const {
  request: addSpamFilterRequest,
  success: addSpamFilterSuccess,
  failure: addSpamFilterFailure,
} = createRequestActions('ADD_SPAM_FILTER', ({ body }: { body: SpamFilterData }) => ({
  http: makeHttpPayload('post', 'spam-filters', body),
}));

export const {
  request: updateSpamFilterRequest,
  success: updateSpamFilterSuccess,
  failure: updateSpamFilterFailure,
} = createRequestActions('UPDATE_SPAM_FILTER', ({ id, body }: { id: Id; body: SpamFilterData }) => ({
  http: makeHttpPayload('post', `spam-filters/${id}`, body),
  data: { id, body },
}));

export const {
  request: removeSpamFilterRequest,
  success: removeSpamFilterSuccess,
  failure: removeSpamFilterFailure,
} = createRequestActions('REMOVE_SPAM_FILTER', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `spam-filters/${id}`, { status: 'deleted' }),
  data: { id },
}));

export const {
  request: restoreSpamFilterRequest,
  success: restoreSpamFilterSuccess,
  failure: restoreSpamFilterFailure,
} = createRequestActions('RESTORE_SPAM_FILTER', ({ id, body }: { id: Id; body: SpamFilterData }) => ({
  http: makeHttpPayload('post', `spam-filters/${id}`, body),
  data: { id, body },
}));

export const {
  request: recoverSpamFilterRequest,
  success: recoverSpamFilterSuccess,
  failure: recoverSpamFilterFailure,
} = createRequestActions('RECOVER_SPAM_FILTER', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `spam-filters/${id}`, { status: 'active' }),
  data: { id },
}));
