import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#123c69',
    },
    info: {
      main: '#2196f3',
      dark: '#1976d2',
    },
    warning: {
      main: '#fc8b00',
      light: '#ffb74d',
    },
    success: {
      main: '#4caf50',
      dark: '#388e3c',
    },
    grey: {
      300: '#e0e0e0',
      500: '#9e9e9e',
      700: '#616161',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '3.5rem',
      fontWeight: 800,
    },
    h2: {
      fontSize: '2.8rem',
      fontWeight: 800,
    },
    h3: {
      fontSize: '2.2rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.8rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1.4rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.2rem',
      fontWeight: 600,
    },
  },
});
