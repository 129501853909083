import { createReducer } from "@reduxjs/toolkit";
import { EntityName as EntityNames, Id } from "../entities";
import { map, merge, mergeWith, snakeCase, uniq } from "lodash";
import {
  AddAssociationsPayload,
  addAssociationsRequest,
  addAssociationsSuccess,
  AssociationsPayload,
  deleteAssociationsRequest,
  deleteAssociationsSuccess,
  loadAssociatedEntitiesRequest,
  loadAssociatedEntitiesSuccess,
  loadAssociationsBulkSuccess
} from "./actions";

/** The REST-endpoints do not use nouns in the plural form. **/
export const makeNameSingular = (name: string) => name.slice(0, -1);

type EntityName = EntityNames;
type AssociatedEntityName = EntityNames;
type EntityId = number;
type AssociatedEntityId = number;

export type AssociationsState = Record<EntityName,
  Record<AssociatedEntityName,
    Record<EntityId,
      {
        ids: AssociatedEntityId[];
        error: unknown;
        loaded: boolean;
        loading: boolean;
      }
    > & { loaded: boolean }
  > & { loaded: boolean }
>;


const mergeAssociationState = (
  id: Id,
  entityName: EntityName,
  associatedEntityName: EntityName,
  ids: Id[],
  loaded: boolean = false,
  state: AssociationsState,
  mergeFunction: (objValue: any, srcValue: any) => any
) => {
  return mergeWith(
    state,
    {
      [entityName]: {
        [associatedEntityName]: {
          [id]: {
            ids,
            loaded,
            error: null,
            loading: false,
          },
        },
      },
      [associatedEntityName]: {
        [entityName]: {
          ...ids.reduce((acc, associatedEntityId) => {
            return {
              ...acc,
              [associatedEntityId]: {
                loaded,
                ids: [id],
                error: null,
                loading: false,
              },
            };
          }, {}),
        },
      },
    },
    mergeFunction
  );
};

const addAssociations = (
  id: Id,
  entityName: EntityName,
  associatedEntityName: EntityName,
  ids: Id[],
  loaded: boolean = true,
  state: AssociationsState
) => {
  return mergeAssociationState(id, entityName, associatedEntityName, ids, loaded, state, (objValue, srcValue) => {
    if (Array.isArray(objValue) && Array.isArray(srcValue)) {
      return uniq([...srcValue, ...objValue]);
    }
  });
};

const deleteAssociations = (
  id: Id,
  entityName: EntityName,
  associatedEntityName: EntityName,
  ids: Id[],
  state: AssociationsState
) => {
  return mergeAssociationState(id, entityName, associatedEntityName, ids, true, state, (objValue, srcValue) => {
    if (Array.isArray(objValue) && Array.isArray(srcValue)) {
      return srcValue;
    }
  });
};

/**
 * Adds all available associations for all entities of a given type.
 * @param entityName Name of entity-type.
 * @param associations List of associations. E.g. if you fetch all associations for customers, and the customer with id 1
 * is associated with receivers with id 2 and 3 an entry in the list wil look like this: [{ id: 1, receiver_ids: [2, 3] }].
 * @param state Associations state.
 */
const addAssociationsBulk = (
  entityName: EntityName,
  associations: Array<{ id: number } & Record<`${string}_ids`, number[]>>,
  state: AssociationsState
) => {
  const [associationState] = associations
    .map((association) => {
      const { id, ...ids } = association;

      return Object.entries(ids).map(([key, value]) => {
        const associatedEntityName = `${key.replace('_ids', '')}s` as EntityName;
        return addAssociations(id, entityName, associatedEntityName, value, false, state);
      });
    })
    .flat();

  return merge(associationState, {
    [entityName]: {
      loaded: true,
    },
  });
};

const setLoading = (id: Id, entityName: string, associatedEntityName: string, state: AssociationsState) => {
  return merge(state, {
    [entityName]: {
      [associatedEntityName]: {
        [id]: {
          ids: [],
          loading: true,
          loaded: false,
        },
      },
    },
  });
};

export const reducer = createReducer<AssociationsState>({} as AssociationsState, (builder) => {
  builder
    .addCase(loadAssociatedEntitiesRequest, (state, { payload }) => {
      const { entityId, entityName, associatedEntityName } = payload.meta as AddAssociationsPayload;
      setLoading(entityId, entityName, associatedEntityName, state);
    })
    .addCase(loadAssociatedEntitiesSuccess, (state, { payload }) => {
      const { entityId, entityName, associatedEntityName } = payload.meta as AssociationsPayload;
      return addAssociations(
        entityId,
        entityName,
        associatedEntityName,
        map(payload.response.items, 'id'),
        true,
        state
      );
    })
    .addCase(addAssociationsRequest, (state, { payload }) => {
      const { entityId, entityName, associatedEntityName } = payload.meta as AddAssociationsPayload;
      setLoading(entityId, entityName, associatedEntityName, state);
    })
    .addCase(addAssociationsSuccess, (state, { payload }) => {
      const { entityId, entityName, associatedEntityName } = payload.meta as AddAssociationsPayload;
      return addAssociations(
        entityId,
        entityName,
        associatedEntityName,
        payload.response.data[`${makeNameSingular(snakeCase(associatedEntityName))}_ids`],
        true,
        state
      );
    })
    .addCase(deleteAssociationsRequest, (state, { payload }) => {
      const { entityId, entityName, associatedEntityName } = payload.meta as AddAssociationsPayload;
      setLoading(entityId, entityName, associatedEntityName, state);
    })
    .addCase(loadAssociationsBulkSuccess, (state, { payload }) =>
      addAssociationsBulk(payload.meta.entityName, payload.response.items, state)
    )
    .addCase(deleteAssociationsSuccess, (state, { payload }) => {
      const { entityId, entityName, associatedEntityName } = payload.meta as AddAssociationsPayload;
      return deleteAssociations(
        entityId,
        entityName,
        associatedEntityName,
        payload.response.data[`${makeNameSingular(snakeCase(associatedEntityName))}_ids`],
        state
      );
    });
});
