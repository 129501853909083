import { createReducer } from '@reduxjs/toolkit';
import {
  createSupportEmailFailure,
  createSupportEmailRequest,
  createSupportEmailSuccess,
  resetSupportMailState,
} from './actions';

export interface State {
  loading: boolean;
  receivers: { successfulReceivers: string[]; failedReceivers: string[] } | null;
  error: string | null;
}

const initialState: State = { loading: false, receivers: null, error: null };

export const supportMailReducer = createReducer<State>(initialState, (builder) => {
  builder
    .addCase(createSupportEmailRequest, (state) => ({ ...state, loading: true }))
    .addCase(createSupportEmailSuccess, (state, { payload }) => {
      return {
        ...state,
        loading: false,
        receivers: payload.response as any
      }
    })
    .addCase(createSupportEmailFailure, (state, { payload: { error } }) => ({
      ...state,
      loading: false,
      error,
    }))
    .addCase(resetSupportMailState, () => ({
      ...initialState
    }));
});
