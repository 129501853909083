import { orderBy } from 'lodash';

import { createReducer } from '@reduxjs/toolkit';

import { NatureIconType } from '../../../components/data';
import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import {
  createNatureSuccess,
  deleteNatureSuccess,
  loadNaturesFailure,
  loadNaturesRequest,
  loadNaturesSuccess,
  recoverNatureSuccess,
  restoreNatureSuccess,
  updateNatureSuccess,
} from './actions';

export type NatureStatus = 'active' | 'disabled' | 'deleted';

export interface Nature {
  id: number;
  nature_id: number;
  icon: NatureIconType;
  name: string;
  status: NatureStatus;
}

export interface State extends EntityState<Nature> {}

export const adapter: EntityAdapter<Nature> = createEntityAdapter<Nature>({
  sort: (entities) => orderBy(entities, 'name'),
});

export const reducer = createReducer<State>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadNaturesRequest, (state) => adapter.setLoading(state))
    .addCase(loadNaturesSuccess, (state, { payload }) => adapter.addMany(payload.response.items, state))
    .addCase(loadNaturesFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(createNatureSuccess, (state, { payload }) => adapter.add(payload.response.data, state))
    .addCase(updateNatureSuccess, (state, { payload }) => adapter.update(payload.data.id, payload.data.body, state))
    .addCase(restoreNatureSuccess, (state, { payload }) => adapter.update(payload.data.id, payload.data.body, state))
    .addCase(recoverNatureSuccess, (state, { payload }) => adapter.update(payload.data.id, { status: 'active' }, state))
    .addCase(deleteNatureSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: 'deleted' }, state)
    );
});
