import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { captureException } from '@sentry/browser';
import createSagaMiddleware from 'redux-saga';
import { all, setContext } from 'redux-saga/effects';
import { ApiClient, createApiClient } from './api-client';
import { AuthClient, createAuthClient } from './auth-client';
import { effects as authEffects } from './store/auth';
import { effects as rootEffects } from './store/effects';
import { effects as accountManagerEffects } from './store/entities/account-managers';
import { effects as countryEffects } from './store/entities/countries';
import { effects as customerEffects } from './store/entities/customers';
import { effects as distributionGroupEffects } from './store/entities/distribution-groups';
import { effects as distributionSettingsEffects } from './store/entities/distribution-settings';
import { effects as fieldAccessEffects } from './store/entities/field-access';
import { effects as fieldTranslationsEffects } from './store/entities/field-translations';
import { effects as natureEffects } from './store/entities/natures';
import { effects as ruleMappingsEffects } from './store/entities/rule-mappings';
import { effects as ruleEffects } from './store/entities/rules';
import { effects as spamFilterEffects } from './store/entities/spam-filters';
import { effects as fieldsEffects } from './store/enums/fields';
import { rootReducer } from './store/reducer';
import { effects as insightsEffects } from './store/tools/insights';
import { effects as customerFilterEffects } from './store/ui/customers-table';
import { effects as connectionsEffects } from './store/entities/connections';
import { effects as receiverEffects } from './store/entities/receivers';
import { effects as associationEffects } from './store/associations';
import { effects as incomingLeadsEffects } from './store/entities/incomingLeads';
import { effects as outgoingLeadsEffects } from './store/entities/outgoingLeads';
import { effects as customerLeadsEffects } from './store/entities/customerLeads';
import { effects as receiverLeadsEffects } from './store/entities/receiverLeads';
import { effects as incomingLeadEffects } from './store/entities/incomingLead';
import { effects as receiverLeadEffects } from './store/entities/receiverLead';
import { effects as outgoingLeadsIncomingIdEffects } from './store/entities/outgoingLeadsIncomingId';
import { effects as customerLeadsIncomingIdEffects } from './store/entities/customerLeadsIncomingId';
import { effects as crmEffects } from './store/entities/crm/effects';

export interface Context {
  api: ApiClient | null;
  auth: AuthClient | null;
}

const saga = createSagaMiddleware<Context>({
  context: { api: null, auth: null },
  onError(error: Error) {
    console.log(error);
    captureException(error);
  },
});

export const store = configureStore({ reducer: rootReducer, middleware: [...getDefaultMiddleware(), saga] });

/**
 * Type configured with the Thunk middleware types
 *
 * More info: https://redux.js.org/recipes/usage-with-typescript#typing-configurestore
 */
export type AppDispatch = typeof store.dispatch;

async function makeContext(): Promise<Context> {
  const auth: AuthClient = await createAuthClient();
  const api: ApiClient = createApiClient(auth);

  return { auth, api };
}

saga.run<any>(function* rootSaga() {
  const context: Context = yield makeContext();
  yield setContext(context);

  yield all([
    rootEffects(),
    authEffects(),
    countryEffects(),
    customerEffects(),
    natureEffects(),
    spamFilterEffects(),
    customerFilterEffects(),
    fieldsEffects(),
    fieldAccessEffects(),
    fieldTranslationsEffects(),
    insightsEffects(),
    accountManagerEffects(),
    ruleMappingsEffects(),
    distributionSettingsEffects(),
    distributionGroupEffects(),
    ruleEffects(),
    connectionsEffects(),
    receiverEffects(),
    associationEffects(),
    incomingLeadsEffects(),
    outgoingLeadsEffects(),
    customerLeadsEffects(),
    receiverLeadsEffects(),
    incomingLeadEffects(),
    receiverLeadEffects(),
    outgoingLeadsIncomingIdEffects(),
    customerLeadsIncomingIdEffects(),
    crmEffects(),
  ]);
});
