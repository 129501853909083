import { authorizedFetch } from './authorization';

const API_URL = window.location.hostname.startsWith('test.')
  ? process.env.REACT_APP_API_URL_TEST
  : process.env.REACT_APP_API_URL;

export async function fetchData(path, config = {}) {
  const { query } = config;
  const url = createURL(path, query);

  const response = await authorizedFetch(url, config);
  if (!response.ok) {
    return { ok: false, response: response.text() };
  }

  const contentType = response.headers.get('Content-Type') || '';
  return {
    ok: true,
    response: contentType.includes('application/json') ? response.json() : response.text(),
  };
}

export async function get(path, config = {}) {
  const { ok, response } = await fetchData(path, config);
  const results = await response;

  if (!ok) {
    throw new Error(results);
  }

  return results;
}

export function postData(path, data) {
  return fetchData(path, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json',
    },
  });
}

export async function post(path, data) {
  const { ok, response } = await postData(path, data);
  const results = await response;

  if (!ok) {
    throw new Error(results);
  }

  return results;
}

export function createURL(path, query = {}) {
  const queryString = Object.entries(query).filter(nonEmptyValue).map(encodeParameter).join('&');
  return queryString ? `${API_URL}/${path}?${queryString}` : `${API_URL}/${path}`;
}

function nonEmptyValue([, value]) {
  return value !== null && value !== undefined && value !== '' && !(Array.isArray(value) && value.length === 0);
}

function encodeParameter([key, value]) {
  return `${key}=${encodeValue(value)}`;
}

function encodeValue(value) {
  if (Array.isArray(value)) {
    return value.join();
  } else if (typeof value === 'object') {
    return JSON.stringify(value);
  } else {
    return String(value);
  }
}
