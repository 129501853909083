import { createRequestActions, makeHttpPayload } from '../effects';
import { createAction } from '@reduxjs/toolkit';
import { pick } from 'lodash';

export enum Action {
  Missing = 'waiting-for-communication',
  Cancel = 'cancel',
  Delete = 'delete',
  Chosen = 'stop-communication',
  WrittenOffer = 'written-communication-only',
  SpamLead = 'spam',
  Custom = 'custom',
}

export type EmailRequest = {
  leadId: number;
  action: Action;
  receiver_ids?: number[];
  customMessage?: string;
};

export const resetSupportMailState = createAction('RESET_SUPPORT_MAIL_STATE');

export const {
  request: createSupportEmailRequest,
  success: createSupportEmailSuccess,
  failure: createSupportEmailFailure,
} = createRequestActions(
  'CREATE_SUPPORT_EMAIL_REQUEST',
  ({ body }: { body: EmailRequest }) => ({
    http: makeHttpPayload(
      'post',
      `email/lead`,
      pick(body, [
          'action',
          'receiverIds',
          'incomingLeadId',
          body.action === Action.Custom && 'customMessage',
        ].filter(Boolean) as string[]
      )
    ),
  })
);
