import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import { redistributeIncomingLeadsFailure } from '.';
import { FailurePayload, SuccessPayload } from '../../effects';
import { showErrorNotification, showSuccessNotification } from '../../ui/notifications';
import { loadIncomingLeadsFailure, redistributeIncomingLeadsSuccess, searchIncomingLeadsFailure } from './actions';

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load', label: 'Retry', action: request }));
}

export function* redistributeFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(
    showErrorNotification({ message: 'Failed to redistribute incoming leads', label: 'Retry', action: request })
  );
}

export function* redistributeSuccess({ payload: { response } }: PayloadAction<SuccessPayload>) {
  const failed: number[] = response.data.failed;
  const success: number[] = response.data.success;
  const matchedNewCustomers: number[] = response.data.matched_new_customers;
  const matchedWithAnyCustomers: number[] = response.data.matched_any_customers;
  const leadboxMessage: string = response.data.message;

  if (leadboxMessage === 'Redistribute progress started') {
    yield put(
      showSuccessNotification({
        message: `${leadboxMessage}, check your email for result`,
      })
    );
  } else if (failed.length === 0 && success.length === 0 && !matchedNewCustomers && !matchedWithAnyCustomers) {
    yield put(
      showErrorNotification({
        message: `Failed to redistribute any leads`,
      })
    );
  } else if (failed.length === 0) {
    yield put(
      showSuccessNotification({
        message: `Successfully redistributed number of leads: ${
          success.length
        }, redistributed leads that matched with customers (id): ${
          matchedNewCustomers.length > 0
            ? matchedNewCustomers.length
            : matchedWithAnyCustomers.length > 0
            ? matchedWithAnyCustomers.length
            : 0
        }`,
      })
    );
  } else {
    yield put(
      showSuccessNotification({
        message: `Failed to redistribute (id): ${failed.length}, successfully redistributed (id): ${
          success.length
        }, redistrbuted leads that matched with customers (id): ${
          matchedNewCustomers.length > 0
            ? matchedNewCustomers.length
            : matchedWithAnyCustomers.length > 0
            ? matchedWithAnyCustomers.length
            : 0
        }`,
      })
    );
  }
}

export function* effects() {
  yield takeEvery(loadIncomingLeadsFailure, loadFailure);
  yield takeEvery(searchIncomingLeadsFailure, loadFailure);
  yield takeEvery(redistributeIncomingLeadsFailure, redistributeFailure);
  yield takeEvery(redistributeIncomingLeadsSuccess, redistributeSuccess);
}
