import { createReducer } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import {
  addRuleSuccess,
  copyCustomerRulesSuccess,
  copyReceiverRulesSuccess,
  loadRulesFailure,
  loadRulesRequest,
  loadRulesSuccess,
  recoverRuleSuccess,
  removeRuleSuccess,
  restoreRuleSuccess,
  updateRuleSuccess,
} from './actions';
import { DateRange, TimespanAttribute } from '../customers/types';

export type UUID = string;

export type Status = 'active' | 'disabled' | 'deleted';

export type RuleOperator =
  | 'equal'
  | 'includes'
  | 'begins-with'
  | 'ends-with'
  | 'all'
  | 'less-than'
  | 'more-than'
  | 'empty'
  | 'contains'
  | 'regex';

export type RuleRow = RuleCondition | RuleCriteria;

export interface RuleCondition {
  key?: UUID;
  operator: 'and' | 'or';
  criteria: RuleRow[];
}

export interface RuleCriteria {
  key?: UUID;
  field: string;
  operator: RuleOperator;
  value: string;
  negate?: boolean;
}

export interface LeadLimit {
  timespan: Timespan;
  limit: number;
}

export type Timespan = 'all-time' | 'year' | 'month' | 'week' | 'day';

export interface Rule {
  id: number;
  status: Status;
  name: string;
  condition: RuleCondition;
  priority: number;
  round: 1 | 2 | 3;
  scope: 'customer' | 'global';
  customer_id: number | null;
  receiver_id: number | null;
  settings_id: number | null;
  lead_price: number | null;
  discount: number | null;
  exclusive: boolean | null;
  lead_limits: LeadLimit[];
  customer_priority: number | null;
  product_description: string | null;
  date_ranges: DateRange[];
  active_days: number[];
  timespan_attributes: TimespanAttribute[];
}

export const adapter: EntityAdapter<Rule> = createEntityAdapter<Rule>({
  selectId: (entity) => entity.id,
  sort: (entities) => orderBy(entities, (entity) => entity.name),
});

export const reducer = createReducer<EntityState<Rule>>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadRulesRequest, (state) => adapter.setLoading(state))
    .addCase(loadRulesSuccess, (state, { payload }) => adapter.addMany(payload.response.items, state))
    .addCase(loadRulesFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(addRuleSuccess, (state, { payload }) => adapter.add({ ...payload.response.data, status: 'active' }, state))
    .addCase(copyCustomerRulesSuccess, (state, { payload }) => adapter.addMany(payload.response.items, state))
    .addCase(copyReceiverRulesSuccess, (state, { payload }) => adapter.addMany(payload.response.items, state))
    .addCase(updateRuleSuccess, (state, { payload }) => adapter.update(payload.data.id, payload.data.body, state))
    .addCase(restoreRuleSuccess, (state, { payload }) => adapter.update(payload.data.id, payload.data.body, state))
    .addCase(recoverRuleSuccess, (state, { payload }) => adapter.update(payload.data.id, { status: 'active' }, state))
    .addCase(removeRuleSuccess, (state, { payload }) => adapter.update(payload.data.id, { status: 'deleted' }, state));
});
