import { Box, CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactNode, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { CardSkeleton, drawerWidth, ErrorBoundary, ErrorScreen, Header, Sidebar } from './components/layout';
import { getNavigation, NavigationItem } from './routes/navigation';
import { redirects } from './routes/redirects';
import { logOut } from './store/auth';
import { selectAuth } from './store/auth';

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  main: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
}));

export function App() {
  const classes = useStyles();
  const { loading, user, error } = useSelector(selectAuth);
  const [mainRoute, setMainRoute] = useState<string>('/');
  const [routes, setRoutes] = useState<ReactNode[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      const { main, items } = getNavigation(user.securityGroups);
      setMainRoute(main);
      setRoutes(
        items.flatMap((item) => {
          return (item.children || [])
            .concat(item)
            .filter((item): item is Required<Pick<NavigationItem, 'name' | 'url' | 'component'>> => !!item.component)
            .map((item) => {
              return (
                <Route
                  key={item.name}
                  path={item.url}
                  render={(route) => {
                    return React.createElement(item.component, {
                      key: item.name,
                      ...route,
                    });
                  }}
                />
              );
            });
        })
      );
    }
  }, [user]);

  return (
    <Box display="flex" style={{ minHeight: '100vh' }}>
      <CssBaseline />
      <Header />
      {routes.length && <Sidebar />}
      <Box className={classes.main} flexGrow="1" display="flex" flexDirection="column">
        <div className={classes.toolbar}></div>
        <Box flexGrow="1">
          <ErrorBoundary>
            {loading ? null : error ? (
              <ErrorScreen
                title="Unauthenticated"
                description={error}
                label="Try again"
                onAction={() => dispatch(logOut())}
              />
            ) : !routes.length ? (
              <ErrorScreen
                title="Unauthorized"
                description="Sorry, you don't have access to this page."
                label="Switch account"
                onAction={() => dispatch(logOut())}
              />
            ) : (
              <Box flexGrow="1" display="flex" flexDirection="column">
                <main>
                  <Box m={3}>
                    <ToastContainer position="top-right" autoClose={5000} style={{ zIndex: 1999 }} />
                    <Suspense fallback={<CardSkeleton />}>
                      <Switch>
                        {routes}
                        {redirects}
                        <Redirect to={mainRoute} />
                      </Switch>
                    </Suspense>
                  </Box>
                </main>
              </Box>
            )}
          </ErrorBoundary>
        </Box>
      </Box>
    </Box>
  );
}
