import { createAction } from '@reduxjs/toolkit';
import { Column, Sort } from '../../../components/table';
import { CustomerLeadsFilters } from './reducer';

export const setCustomerLeadsSearch = createAction<{ search: string }>('SET_CUSTOMER_LEADS_SEARCH');
export const setSelectedCustomerLeads = createAction<{ selected_leads: number[] }>('SET_SELECTED_CUSTOMER_LEADS');
export const setCustomerLeadsFilters = createAction<{ filters: CustomerLeadsFilters }>('SET_CUSTOMER_LEADS_FILTERS');
export const setCustomerLeadsColumns = createAction<{ columns: Column[] }>('SET_CUSTOMER_LEADS_COLUMNS');
export const resetCustomerLeadsFilters = createAction('RESET_CUSTOMER_LEADS_FILTERS');
export const toggleCustomerLeadsColumn = createAction<{ column: Column }>('TOGGLE_CUSTOMER_LEADS_COLUMN');
export const sortCustomerLeads = createAction<{ sort: Sort }>('SORT_CUSTOMER_LEADS');
