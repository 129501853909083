import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeEvery } from 'redux-saga/effects';
import { FailurePayload, SuccessPayload } from '../../effects';
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from '../../ui/notifications/actions';
import {
  addRuleMappingFailure,
  addRuleMappingSuccess,
  loadRuleMappingsFailure,
  recoverRuleMappingFailure,
  recoverRuleMappingRequest,
  recoverRuleMappingSuccess,
  removeRuleMappingFailure,
  removeRuleMappingRequest,
  removeRuleMappingSuccess,
  restoreRuleMappingFailure,
  restoreRuleMappingRequest,
  restoreRuleMappingSuccess,
  updateRuleMappingFailure,
  updateRuleMappingSuccess,
} from './actions';
import { selectHistory } from './selectors';

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load', label: 'Retry', action: request }));
}

export function* addSuccess({ payload: { response } }: PayloadAction<SuccessPayload>) {
  const undoAction = removeRuleMappingRequest({ id: response.data.id });
  yield put(showSuccessNotification({ message: 'Rule mappings added', label: 'Undo', action: undoAction }));
}

export function* addFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to add', label: 'Retry', action: request }));
}

export function* updateSuccess({ payload: { data } }: PayloadAction<SuccessPayload>): Generator {
  const entity = yield select(selectHistory(data.id));
  const undoAction = restoreRuleMappingRequest({ id: data.id, body: entity });
  yield put(showSuccessNotification({ message: 'Rule mappings updated', label: 'Undo', action: undoAction }));
}

export function* updateFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to update', label: 'Retry', action: request }));
}

export function* removeSuccess({ payload: { data } }: PayloadAction<SuccessPayload>) {
  const undoAction = recoverRuleMappingRequest({ id: data.id });
  yield put(showWarningNotification({ message: 'Rule mappings removed', label: 'Undo', action: undoAction }));
}

export function* removeFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to remove', label: 'Retry', action: request }));
}

export function* restoreSuccess() {
  yield put(showSuccessNotification({ message: 'Rule mappings restored' }));
}

export function* restoreFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to restore', label: 'Retry', action: request }));
}

export function* recoverSuccess() {
  yield put(showSuccessNotification({ message: 'Rule mappings recovered' }));
}

export function* recoverFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to recover', label: 'Retry', action: request }));
}

export function* effects() {
  yield takeEvery(loadRuleMappingsFailure, loadFailure);
  yield takeEvery(addRuleMappingSuccess, addSuccess);
  yield takeEvery(addRuleMappingFailure, addFailure);
  yield takeEvery(updateRuleMappingSuccess, updateSuccess);
  yield takeEvery(updateRuleMappingFailure, updateFailure);
  yield takeEvery(removeRuleMappingSuccess, removeSuccess);
  yield takeEvery(removeRuleMappingFailure, removeFailure);
  yield takeEvery(restoreRuleMappingSuccess, restoreSuccess);
  yield takeEvery(restoreRuleMappingFailure, restoreFailure);
  yield takeEvery(recoverRuleMappingSuccess, recoverSuccess);
  yield takeEvery(recoverRuleMappingFailure, recoverFailure);
}
