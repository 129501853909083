import { createRequestActions, makeHttpPayload } from '../../effects';
import { Id } from '../adapter';
import { Country } from './reducer';

export type CountryData = Partial<Exclude<Country, 'id'>>;

export const {
  request: loadCountriesRequest,
  success: loadCountriesSuccess,
  failure: loadCountriesFailure,
} = createRequestActions('LOAD_COUNTRIES', () => ({
  http: makeHttpPayload('get', 'countries'),
}));

export const {
  request: addCountryRequest,
  success: addCountrySuccess,
  failure: addCountryFailure,
} = createRequestActions('ADD_COUNTRY', ({ body }: { body: CountryData }) => ({
  http: makeHttpPayload('post', 'countries', body),
}));

export const {
  request: updateCountryRequest,
  success: updateCountrySuccess,
  failure: updateCountryFailure,
} = createRequestActions('UPDATE_COUNTRY', ({ id, body }: { id: Id; body: CountryData }) => ({
  http: makeHttpPayload('post', `countries/${id}`, body),
  data: { id, body },
}));

export const {
  request: removeCountryRequest,
  success: removeCountrySuccess,
  failure: removeCountryFailure,
} = createRequestActions('REMOVE_COUNTRY', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `countries/${id}`, { status: 'deleted' }),
  data: { id },
}));

export const {
  request: restoreCountryRequest,
  success: restoreCountrySuccess,
  failure: restoreCountryFailure,
} = createRequestActions('RESTORE_COUNTRY', ({ id, body }: { id: Id; body: CountryData }) => ({
  http: makeHttpPayload('post', `countries/${id}`, body),
  data: { id, body },
}));

export const {
  request: recoverCountryRequest,
  success: recoverCountrySuccess,
  failure: recoverCountryFailure,
} = createRequestActions('RECOVER_COUNTRY', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `countries/${id}`, { status: 'active' }),
  data: { id },
}));
