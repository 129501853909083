export const DATE_SEARCH = "DATE_SEARCH";
export function setDateRange(after_date: string, before_date: string) {
  return {
    type: DATE_SEARCH,
    after_date,
    before_date
  };
}

export function isDateRangeAction(
  action: any
): action is ReturnType<typeof setDateRange> {
  return action.type && action.type === DATE_SEARCH;
}
