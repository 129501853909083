import moment from 'moment';
import { Column } from '../../../components/table';
import {
  buildFieldQueryStringForLeads,
  nonEmptyFieldIds,
} from '../../../views/Leads/LeadUtils/components/LeadsHelpers';
import { createRequestActions, get, post } from '../../effects';
import { LeadsFilters } from '../../ui/incoming-leads-table';
import { OutgoingLeadsFilters } from '../../ui/outgoing-leads-table/reducer';

export const {
  request: loadOutgoingLeadsRequest,
  success: loadOutgoingLeadsSuccess,
  failure: loadOutgoingLeadsFailure,
} = createRequestActions(
  'LOAD_OUTGOING_LEADS',
  ({ filters, receiverId }: { filters: OutgoingLeadsFilters; receiverId?: number }) => {
    const fieldIdSearch = nonEmptyFieldIds(filters.field_ids);
    return {
      http: get(`leads/${filters.type === 'failed' ? 'failed' : 'outgoing'}`, {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        count: true,
        receiver_id: receiverId,
        limit: filters.limit,
        after_date: moment(filters.after_date).format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment(filters.before_date).format('YYYY-MM-DD HH:mm:ss'),
        offset: filters.offset,
        countries: filters.countries.length > 0 ? filters.countries : undefined,
        natures: filters.natures.length > 0 ? filters.natures : undefined,
        field_ids: fieldIdSearch.length > 0 ? buildFieldQueryStringForLeads(filters.field_ids) : '',
      }),
    };
  }
);

export const {
  request: countOutgoingLeadsRequest,
  success: countOutgoingLeadsSuccess,
  failure: countOutgoingLeadsFailure,
} = createRequestActions('COUNT_OUTGOING_LEADS', ({ filters }: { filters: OutgoingLeadsFilters }) => {
  const fieldIdSearch = nonEmptyFieldIds(filters.field_ids);
  return {
    http: get(`leads/outgoing/count`, {
      count: true,
      limit: filters.limit,
      after_date: moment(filters.after_date).format('YYYY-MM-DD HH:mm:ss'),
      before_date: moment(filters.before_date).format('YYYY-MM-DD HH:mm:ss'),
      offset: fieldIdSearch.length > 0 ? 0 : filters.offset,
      countries: filters.countries.length > 0 ? filters.countries : undefined,
      natures: filters.natures.length > 0 ? filters.natures : undefined,
      field_ids: fieldIdSearch.length > 0 ? buildFieldQueryStringForLeads(filters.field_ids) : '',
    }),
  };
});

export const downloadOutgoingLeads = createRequestActions(
  'DOWNLOAD_OUTGOING_LEADS',
  ({
    format,
    filters,
    columns,
    id,
  }: {
    format: 'xlsx' | 'csv' | 'json';
    filters: OutgoingLeadsFilters;
    columns: Column[];
    id: number;
  }) => {
    return {
      http: get(`leads/${filters.type === 'failed' ? 'failed' : 'outgoing'}`, {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        after_date: moment(filters.after_date).format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment(filters.before_date).format('YYYY-MM-DD HH:mm:ss'),
        export_fields: columns
          .filter((column) => column.visible === true)
          .map((column) => {
            return column.label;
          }),
        countries: filters.countries.length > 0 ? filters.countries : undefined,
        natures: filters.natures.length > 0 ? filters.natures : undefined,
        format,
        limit: 5000,
        offset: 0,
        medium: 'file',
        receiver_id: id,
      }),
    };
  }
);

export const {
  request: searchOutgoingLeadsRequest,
  success: searchOutgoingLeadsSuccess,
  failure: searchOutgoingLeadsFailure,
} = createRequestActions(
  'SEARCH_OUTGOING_LEADS',
  ({ search, filters }: { search: string; filters: OutgoingLeadsFilters }) => {
    return {
      http: get(`leads/outgoing`, {
        limit: filters.limit,
        search: search,
        countries: filters.countries.length > 0 ? filters.countries : undefined,
        natures: filters.natures.length > 0 ? filters.natures : undefined,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        after_date: moment(filters.after_date).format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment(filters.before_date).format('YYYY-MM-DD HH:mm:ss'),
        offset: filters.offset,
      }),
    };
  }
);

export const {
  request: retryOutgoingLeadsRequest,
  success: retryOutgoingLeadsSuccess,
  failure: retryOutgoingLeadsFailure,
} = createRequestActions(
  'RETRY_OUTGOING_LEADS',
  ({
    ids,
    filters,
    failedLeads,
  }: {
    ids: number | number[] | undefined;
    filters: LeadsFilters | undefined;
    failedLeads: boolean;
  }) => {
    const formattedOutgoingIds = Array.isArray(ids) ? ids : ids ? [ids] : [];
    return {
      http: post('dispatch/retry-connections', {
        outgoing_ids: formattedOutgoingIds,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        after_date: filters ? moment(filters.after_date).format('YYYY-MM-DD HH:mm:ss') : '-',
        before_date: filters ? moment(filters.before_date).format('YYYY-MM-DD HH:mm:ss') : '-',
        countries: filters?.countries && filters.countries?.length > 0 ? filters.countries : undefined,
        natures: filters?.natures && filters.natures.length > 0 ? filters.natures : undefined,
        failed_leads: failedLeads ?? false,
      }),
    };
  }
);

export const {
  request: retryOutgoingLeadsForReceiverRequest,
  success: retryOutgoingLeadsForReceiverSuccess,
  failure: retryOutgoingLeadsForReceiverFailure,
} = createRequestActions(
  'RETRY_OUTGOING_LEADS_FOR_RECEIVER',
  ({
    ids,
    filters,
    receiverId,
    failedLeads,
  }: {
    ids: number | number[] | undefined;
    filters: LeadsFilters | undefined;
    receiverId: number;
    failedLeads: boolean;
  }) => {
    const formattedOutgoingIds = Array.isArray(ids) ? ids : ids ? [ids] : [];
    return {
      http: post('dispatch/retry-connection-for-receiver', {
        outgoing_ids: formattedOutgoingIds,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        after_date: filters ? moment(filters.after_date).format('YYYY-MM-DD HH:mm:ss') : '-',
        before_date: filters ? moment(filters.before_date).format('YYYY-MM-DD HH:mm:ss') : '-',
        countries: filters?.countries && filters.countries?.length > 0 ? filters.countries : undefined,
        natures: filters?.natures && filters.natures.length > 0 ? filters.natures : undefined,
        receiver_id: receiverId,
        failed_leads: failedLeads ?? false,
      }),
    };
  }
);
