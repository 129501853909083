import { createReducer } from '@reduxjs/toolkit';
import moment from 'moment';
import { Column, Sort } from '../../../components/table';
import { LeadsRule } from '../../../views/Leads/LeadUtils/components/LeadsHelpers';
import { Nature } from '../../entities/natures';
import { baseFieldIds } from '../incoming-leads-table';
import {
  resetCustomerLeadsFilters,
  setCustomerLeadsColumns,
  setCustomerLeadsFilters,
  setCustomerLeadsSearch,
  setSelectedCustomerLeads,
  sortCustomerLeads,
  toggleCustomerLeadsColumn,
} from './actions';

export interface CustomerLeadsFilters {
  natures: Nature[];
  countries: string[];
  statuses: string[];
  field_ids: LeadsRule[];
  before_date: string;
  after_date: string;
  limit: number;
  offset: number;
  page: number;
  customer_id?: number;
}

export interface State {
  search: string;
  selected_leads: number[];
  filters: CustomerLeadsFilters;
  filteredIds: number[] | null;
  columns: Column[];
  sort: Sort;
}

export const initialState: State = {
  search: '',
  selected_leads: [],
  filters: {
    natures: [],
    countries: [],
    statuses: [],
    field_ids: baseFieldIds,
    after_date: moment
      .utc()
      .startOf('day')
      .subtract(1, 'months')
      .locale('YYYY-MM-DD HH:mm:ss')
      .format('YYYY-MM-DD HH:mm:ss'),
    before_date: moment.utc().endOf('day').locale('YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
    limit: 25,
    offset: 0,
    page: 0,
  },
  filteredIds: null,
  columns: [
    { id: 'id', label: 'Id', visible: true },
    { id: 'remarks', label: 'Remarks', visible: true },
    { id: 'date', label: 'Date', visible: true },
    { id: 'customer_name', label: 'Customer name', visible: true },
    { id: 'rule_name', label: 'Rule name', visible: true },
    { id: 'lead_price', label: 'Lead price', visible: true },
    { id: 'exclusive', label: 'Exclusive', visible: true },
    { id: 'received_by', label: 'Received by', visible: true },
    { id: 'flag', label: 'Flags', visible: true },
  ],
  sort: {
    column: 'id',
    direction: 'desc',
  },
};

export const customerLeadsTableReducer = createReducer<State>(initialState, (builder) => {
  builder
    .addCase(setCustomerLeadsSearch, (state, { payload }) => ({ ...state, search: payload.search }))
    .addCase(setSelectedCustomerLeads, (state, { payload }) => ({ ...state, selected_leads: payload.selected_leads }))
    .addCase(setCustomerLeadsFilters, (state, { payload }) => ({
      ...state,
      filters: {
        ...payload.filters,
        after_date: moment(payload.filters.after_date).format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment(payload.filters.before_date).format('YYYY-MM-DD HH:mm:ss'),
      },
      filteredIds: payload.filters.field_ids.length ? state.filteredIds : null,
    }))
    .addCase(setCustomerLeadsColumns, (state, { payload }) => ({ ...state, columns: payload.columns }))
    .addCase(resetCustomerLeadsFilters, (state) => ({ ...state, filters: initialState.filters, filteredIds: null }))
    .addCase(toggleCustomerLeadsColumn, (state, { payload }) => ({
      ...state,
      columns: state.columns.map((column) => (column.id === payload.column.id ? payload.column : column)),
    }))
    .addCase(sortCustomerLeads, (state, { payload }) => ({ ...state, sort: payload.sort }));
});
