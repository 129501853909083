import { put, takeEvery } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';

import { FailurePayload } from '../../effects';
import { showErrorNotification } from '../notifications';
import { filterCustomersFailure } from './actions';

export function* filterFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to filter', label: 'Retry', action: request }));
}

export function* effects() {
  yield takeEvery(filterCustomersFailure, filterFailure);
}
