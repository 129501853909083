import { createRequestActions, makeHttpPayload } from '../../effects';
import { Id } from '../adapter';
import { DistributionGroup } from './reducer';

export type DistributionGroupData = Partial<Exclude<DistributionGroup, 'id'>>;

export const {
  request: loadDistributionGroupsRequest,
  success: loadDistributionGroupsSuccess,
  failure: loadDistributionGroupsFailure,
} = createRequestActions('LOAD_DISTRIBUTION_GROUP', () => ({
  http: makeHttpPayload('get', 'distribution-groups'),
}));

export const {
  request: addDistributionGroupRequest,
  success: addDistributionGroupSuccess,
  failure: addDistributionGroupFailure,
} = createRequestActions('CREATE_DISTRIBUTION_GROUP', ({ body }: { body: DistributionGroupData }) => ({
  http: makeHttpPayload('post', 'distribution-groups', body),
}));

export const {
  request: updateDistributionGroupRequest,
  success: updateDistributionGroupSuccess,
  failure: updateDistributionGroupFailure,
} = createRequestActions('UPDATE_DISTRIBUTION_GROUP', ({ id, body }: { id: Id; body: DistributionGroupData }) => ({
  http: makeHttpPayload('post', `distribution-groups/${id}`, body),
  data: { id, body },
}));

export const {
  request: removeDistributionGroupRequest,
  success: removeDistributionGroupSuccess,
  failure: removeDistributionGroupFailure,
} = createRequestActions('REMOVE_DISTRIBUTION_GROUP', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `distribution-groups/${id}`, { status: 'deleted' }),
  data: { id },
}));

export const {
  request: restoreDistributionGroupRequest,
  success: restoreDistributionGroupSuccess,
  failure: restoreDistributionGroupFailure,
} = createRequestActions('RESTORE_DISTRIBUTION_GROUP', ({ id, body }: { id: Id; body: DistributionGroupData }) => ({
  http: makeHttpPayload('post', `distribution-groups/${id}`, body),
  data: { id, body },
}));

export const {
  request: recoverDistributionGroupRequest,
  success: recoverDistributionGroupSuccess,
  failure: recoverDistributionGroupFailure,
} = createRequestActions('RECOVER_DISTRIBUTION_GROUP', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `distribution-groups/${id}`, { status: 'active' }),
  data: { id },
}));
