import { omit } from 'lodash';
import { createRequestActions, makeHttpPayload } from '../../effects';
import { Id } from '../adapter';
import { Rule } from './reducer';
import { TimespanAttribute } from '../../../store/entities/customers/types';

export type RuleData = Partial<Exclude<Rule, 'id'>>;

export const {
  request: loadRulesRequest,
  success: loadRulesSuccess,
  failure: loadRulesFailure,
} = createRequestActions('LOAD_RULES', ({ ids, idType }: { ids: number | number[]; idType: string }) => ({
  http: makeHttpPayload('get', `rules?${idType}=${Array.isArray(ids) ? ids.join(',') : ids}`),
}));

export const {
  request: addRuleRequest,
  success: addRuleSuccess,
  failure: addRuleFailure,
} = createRequestActions('ADD_RULE', ({ body }: { body: RuleData }) => {
  return {
    http: makeHttpPayload('post', 'rules', {
      ...body,
      timespan_attributes: (body.timespan_attributes ?? []).map((item: TimespanAttribute) => omit(item, 'id')),
    }),
  };
});

export const {
  request: updateRuleRequest,
  success: updateRuleSuccess,
  failure: updateRuleFailure,
} = createRequestActions('UPDATE_RULE', ({ id, body }: { id: Id; body: RuleData }) => ({
  http: makeHttpPayload('post', `rules/${id}`, body),
  data: { id, body },
}));

export const {
  request: removeRuleRequest,
  success: removeRuleSuccess,
  failure: removeRuleFailure,
} = createRequestActions('REMOVE_RULE', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `rules/${id}`, { status: 'deleted' }),
  data: { id },
}));

export const {
  request: copyCustomerRulesRequest,
  success: copyCustomerRulesSuccess,
  failure: copyCustomerRulesFailure,
} = createRequestActions('COPY_CUSTOMER_RULES', ({ body, meta }: { body: RuleData; meta: { copyTo: string } }) => ({
  http: makeHttpPayload('post', 'rules/copy/customer', body),
  meta,
}));

export const {
  request: copyReceiverRulesRequest,
  success: copyReceiverRulesSuccess,
  failure: copyReceiverRulesFailure,
} = createRequestActions('COPY_RECEIVER_RULES', ({ body, meta }: { body: RuleData; meta: { copyTo: string } }) => ({
  http: makeHttpPayload('post', 'rules/copy/receiver', body),
  meta,
}));

export const {
  request: restoreRuleRequest,
  success: restoreRuleSuccess,
  failure: restoreRuleFailure,
} = createRequestActions('RESTORE_RULE', ({ id, body }: { id: Id; body: RuleData }) => ({
  http: makeHttpPayload('post', `rules/${id}`, body),
  data: { id, body },
}));

export const {
  request: recoverRuleRequest,
  success: recoverRuleSuccess,
  failure: recoverRuleFailure,
} = createRequestActions('RECOVER_RULE', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `rules/${id}`, { status: 'active' }),
  data: { id },
}));
