import { RootState } from '../../reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { loadFieldsRequest } from './actions';

export const selectFields = (state: RootState) => state.enums.fields;

export function useFields() {
  const fields = useSelector(selectFields);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(fields)) {
      dispatch(loadFieldsRequest());
    }
  }, [fields, dispatch]);

  return fields;
}
