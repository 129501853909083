import { createReducer } from '@reduxjs/toolkit';
import { Column, Sort } from '../../../components/table';
import {
  resetConnectionsFilters,
  searchConnections,
  setConnectionsFilter,
  sortConnections,
  toggleConnectionsColumn,
} from './actions';

export interface ConnectionsTableState {
  search: string;
  filters: ConnectionsFilter;
  columns: Column[];
  sort: Sort;
}

export interface ConnectionsFilter {
  protocols: string[];
  statuses: string[];
}

export const connectionTableColumns: Column[] = [
  { id: 'name', label: 'Name', visible: true },
  { id: 'protocol', label: 'Protocol', visible: true },
  { id: 'status', label: 'Status', visible: true },
  { id: 'receiver_ids', label: 'Total Receivers', visible: true },
  { id: 'customer_count', label: 'Active Customers', visible: true },
  { id: 'actions', label: '', visible: true }
];

export const initialState: ConnectionsTableState = {
  search: '',
  filters: {
    protocols: [],
    statuses: [],
  },
  columns: connectionTableColumns,
  sort: {
    column: 'name',
    direction: 'asc',
  },
};

export const connectionsTableReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(searchConnections, (state, { payload }) => ({ ...state, search: payload.search }) )
    .addCase(setConnectionsFilter, (state, { payload }) => ({ ...state, filters: payload.filters }))
    .addCase(toggleConnectionsColumn, (state, { payload }) => ({
      ...state,
      columns: state.columns.map((column) => column.id === payload.column.id ? payload.column : column)
    }))
    .addCase(resetConnectionsFilters, (state) => ({ ...state, filters: initialState.filters }))
    .addCase(sortConnections, (state, { payload }) => ({ ...state, sort: payload.sort }));
});
