import { Box, Button, Grid } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { Information } from './Information';

export interface EmptyScreenProps {
  title: string;
  description: ReactNode;
  label?: string;
  onAction?: () => any;
}

export function EmptyScreen({ title, description, label, onAction }: EmptyScreenProps) {
  return (
    <Box textAlign="center" p={4}>
      <Grid container alignItems="center" direction="column" spacing={1}>
        <img src="/img/no-data.svg" alt="No data found" height={160} />
        <Box textAlign="center" mt={4}>
          <Information title={title} description={description} />
        </Box>
        {label ? (
          <Box textAlign="center" mt={4}>
            <Button variant="contained" size="large" color="primary" onClick={onAction}>
              {label}
            </Button>
          </Box>
        ) : null}
      </Grid>
    </Box>
  );
}
