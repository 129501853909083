import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import { deletePersonalDataOnIncomingLeadSuccess, updateIncomingLeadSuccess } from '.';
import { FailurePayload, SuccessPayload } from '../../effects';
import { showErrorNotification, showSuccessNotification } from '../../ui/notifications';
import {
  deletePersonalDataOnIncomingLeadFailure,
  deletePersonalDataOnIncomingLeadRequest,
  loadIncomingLeadFailure,
  updateIncomingLeadFailure,
  updateIncomingLeadRequest,
} from './actions';

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load', label: 'Retry', action: request }));
}

export function* deleteFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to delete personal data', label: 'Retry', action: request }));
}

export function* updateSuccess({ payload: { response } }: PayloadAction<SuccessPayload>) {
  const undoAction = updateIncomingLeadRequest({ id: response.data.id, flag: response.data.flag });
  yield put(showSuccessNotification({ message: 'Updated incoming lead', label: 'Undo', action: undoAction }));
}

export function* deleteSuccess({ payload: { response } }: PayloadAction<SuccessPayload>) {
  const undoAction = deletePersonalDataOnIncomingLeadRequest({ incoming_id: response.data.incomingLeadId });
  yield put(showSuccessNotification({ message: 'Deleted personal data', label: 'Undo', action: undoAction }));
}

export function* redistributeFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to redistribute', label: 'Retry', action: request }));
}

export function* effects() {
  yield takeEvery(loadIncomingLeadFailure, loadFailure);
  yield takeEvery(updateIncomingLeadFailure, loadFailure);
  yield takeEvery(updateIncomingLeadSuccess, updateSuccess);
  yield takeEvery(deletePersonalDataOnIncomingLeadFailure, deleteFailure);
  yield takeEvery(deletePersonalDataOnIncomingLeadSuccess, deleteSuccess);
}
