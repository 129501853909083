import { createSelector } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { EntityState } from '../adapter';
import { loadFieldTranslationsRequest } from './actions';
import { adapter, FieldTranslation } from './reducer';

export const selectFieldTranslations = (state: RootState): EntityState<FieldTranslation> =>
  state.entities.fieldTranslations;
export const { selectAll, selectById, selectHistory } = adapter.selectors(selectFieldTranslations);
export const selectExisting = createSelector(selectAll, (entities: FieldTranslation[]) =>
  entities.filter((entity) => entity.status !== 'deleted')
);

export function useFieldTranslations() {
  const { loaded, loading, error } = useSelector(selectFieldTranslations);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded && !loading && !error) {
      dispatch(loadFieldTranslationsRequest());
    }
  }, [dispatch, loaded, loading, error]);

  const entities = useSelector<RootState, FieldTranslation[]>(selectExisting);
  return { loaded, loading, error, entities };
}
