import { createRequestActions, makeHttpPayload } from '../../effects';
import { ReceiverPermissionData } from '../receivers/types';

export const {
  request: loadUsersInOrganizationRequest,
  success: loadUsersInOrganizationSuccess,
  failure: loadUsersInOrganizationFailure,
} = createRequestActions('LOAD_USERS_IN_ORGANIZATION', (body: ReceiverPermissionData) => ({
  http: makeHttpPayload('post', `crm/permissions`, body),
}));

export const {
  request: updateCrmPermissionRequest,
  success: updateCrmPermissionSuccess,
  failure: updateCrmPermissionFailure,
} = createRequestActions('UPDATE_CRM_PERMISSION', (body: ReceiverPermissionData) => ({
  http: makeHttpPayload('post', `crm/create`, body),
}));
