import { RootState } from '../../reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { loadReceiverLeadRequest } from './actions';
import { adapter } from './reducer';

export const selectReceiverLead = (state: RootState) => state.entities.receiverLead;

export const { selectAll } = adapter.selectors(selectReceiverLead);

export function useReceiverLead(receiverLeadId: string) {
  const { loaded, loading, error } = useSelector(selectReceiverLead);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!loaded && !loading && !error) {
      dispatch(loadReceiverLeadRequest({ receiverLeadId }));
    }
  }, [dispatch, loaded, loading, error]);

  const entity = useSelector(selectAll);
  return { loaded, loading, error, entity };
}
