import { Dispatch, useEffect, useRef, useState } from 'react';
import { uniqueId } from 'lodash';

export function useDerivedState<T>(initialValue: T): [T, Dispatch<T>] {
  const [value, setValue] = useState(initialValue);

  useEffect(() => setValue(initialValue), [initialValue]);

  return [value, setValue];
}

export function useForm(): { formId: string, setFormId: (ref: HTMLElement | null) => void } {
  const [ id ] = useState(uniqueId('form-'));

  return {
    formId: id,
    setFormId: (ref: HTMLElement | null) => {
      if(!ref) return;
      const form = ref.querySelector('form');

      if (form) {
        form.id = id;
      }
    }
  };
}

export function useDebouncedEffect(callback: () => void, delay: number, deps: any[] = []) {
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    const handler = setTimeout(callback, delay);

    return () => clearTimeout(handler);

  }, [delay, ...deps]);
}

export function useToggle(enabled = false): [boolean, () => void] {
  const [isEnabled, setIsEnabled] = useState(enabled);
  const toggle = (shouldEnable: boolean | null = null) =>
    setIsEnabled(isEnabled => (shouldEnable ? shouldEnable : !isEnabled));
  return [isEnabled, toggle];
}
