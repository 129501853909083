import { postData } from '../../api';
import uuid from 'uuid/v4';

export const INVOICE_FINISHED = 'INVOICE_FINISHED';
function invoiceFinished(success, result) {
  return {
    type: INVOICE_FINISHED,
    success,
    result: result.map((data) => ({ ...data, key: uuid(), sum: addSum(data) })),
  };
}

function addSum({ product_count, product_price, product_discount }) {
  return (1 - product_discount / 100) * product_price * product_count;
}

export const INVOICE_LOADING = 'INVOICE_LOADING';
function invoiceLoading(bool) {
  return {
    type: INVOICE_LOADING,
    loading: bool,
  };
}

export function getInvoice(natures, country_code, country_timezone, after_date, before_date, auto_invoice) {
  return (dispatch) => {
    dispatch(invoiceLoading(true));
    return postData('generate-invoice', {
      natures,
      country_code,
      country_timezone,
      after_date,
      before_date,
      auto_invoice,
    }).then(({ ok, response }) => {
      response.then((invoice) => {
        dispatch(invoiceFinished(ok, invoice));
      });
    });
  };
}

export const INVOICE_DELETE_ROWS = 'INVOICE_DELETE_ROWS';
export function deleteRows(rowList) {
  return {
    type: INVOICE_DELETE_ROWS,
    rowsToDelete: rowList,
  };
}
export const INVOICE_CHANGE_ROW = 'INVOICE_CHANGE_ROW';
export function changeRow(row) {
  return {
    type: INVOICE_CHANGE_ROW,
    rowToChange: row,
  };
}
export const INVOICE_CLEAR_RESULT = 'INVOICE_CLEAR_RESULT';
export function clearResult(row) {
  return {
    type: INVOICE_CLEAR_RESULT,
  };
}
