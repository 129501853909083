import { createRequestActions, get } from '../../effects';

export const {
  request: loadOutgoingLeadsFromIncomingIdRequest,
  success: loadOutgoingLeadsFromIncomingIdSuccess,
  failure: loadOutgoingLeadsFromIncomingIdFailure,
} = createRequestActions('LOAD_OUTGOING_LEADS_FROM_INCOMING_ID', ({ incomingLeadId }: { incomingLeadId: string }) => {
  return {
    http: get('leads/outgoing/incoming-id', {
      incoming_id: incomingLeadId,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }),
  };
});
