import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeEvery } from 'redux-saga/effects';
import { FailurePayload, SuccessPayload } from '../../effects';
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from '../../ui/notifications/actions';
import {
  addSpamFilterFailure,
  addSpamFilterSuccess,
  loadSpamFiltersFailure,
  recoverSpamFilterFailure,
  recoverSpamFilterRequest,
  recoverSpamFilterSuccess,
  removeSpamFilterFailure,
  removeSpamFilterRequest,
  removeSpamFilterSuccess,
  restoreSpamFilterFailure,
  restoreSpamFilterRequest,
  restoreSpamFilterSuccess,
  updateSpamFilterFailure,
  updateSpamFilterSuccess,
} from './actions';
import { selectHistory } from './selectors';

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load', label: 'Retry', action: request }));
}

export function* addSuccess({ payload: { response } }: PayloadAction<SuccessPayload>) {
  const { data, meta } = response;
  const { error, message } = meta;
  const undoAction = removeSpamFilterRequest({ id: data.id });

  if (error) {
    yield put(showWarningNotification({ message: `Spam filter added, ${message}`, label: 'Undo', action: undoAction }));
  } else {
    yield put(showSuccessNotification({ message: `Spam filter added, ${message}`, label: 'Undo', action: undoAction }));
  }
}

export function* addFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to add', label: 'Retry', action: request }));
}

export function* updateSuccess({ payload: { response } }: PayloadAction<SuccessPayload>): Generator {
  const { data, meta } = response;
  const { error, message } = meta;
  const entity = yield select(selectHistory(data.id));
  const undoAction = restoreSpamFilterRequest({ id: data.id, body: entity });

  if (error) {
    yield put(
      showWarningNotification({ message: `Spam filter updated, ${message}`, label: 'Undo', action: undoAction })
    );
  } else {
    yield put(
      showSuccessNotification({ message: `Spam filter updated, ${message}`, label: 'Undo', action: undoAction })
    );
  }
}

export function* updateFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to update', label: 'Retry', action: request }));
}

export function* removeSuccess({ payload: { data } }: PayloadAction<SuccessPayload>) {
  const undoAction = recoverSpamFilterRequest({ id: data.id });
  yield put(showWarningNotification({ message: 'Spam filter removed', label: 'Undo', action: undoAction }));
}

export function* removeFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to remove', label: 'Retry', action: request }));
}

export function* restoreSuccess() {
  yield put(showSuccessNotification({ message: 'Spam filter restored' }));
}

export function* restoreFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to restore', label: 'Retry', action: request }));
}

export function* recoverSuccess() {
  yield put(showSuccessNotification({ message: 'Spam filter recovered' }));
}

export function* recoverFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to recover', label: 'Retry', action: request }));
}

export function* effects() {
  yield takeEvery(loadSpamFiltersFailure, loadFailure);
  yield takeEvery(addSpamFilterSuccess, addSuccess);
  yield takeEvery(addSpamFilterFailure, addFailure);
  yield takeEvery(updateSpamFilterSuccess, updateSuccess);
  yield takeEvery(updateSpamFilterFailure, updateFailure);
  yield takeEvery(removeSpamFilterSuccess, removeSuccess);
  yield takeEvery(removeSpamFilterFailure, removeFailure);
  yield takeEvery(restoreSpamFilterSuccess, restoreSuccess);
  yield takeEvery(restoreSpamFilterFailure, restoreFailure);
  yield takeEvery(recoverSpamFilterSuccess, recoverSuccess);
  yield takeEvery(recoverSpamFilterFailure, recoverFailure);
}
