import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeEvery } from 'redux-saga/effects';
import { FailurePayload, SuccessPayload } from '../../effects';
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from '../../ui/notifications/actions';
import {
  addDistributionGroupFailure,
  addDistributionGroupSuccess,
  loadDistributionGroupsFailure,
  recoverDistributionGroupFailure,
  recoverDistributionGroupRequest,
  recoverDistributionGroupSuccess,
  removeDistributionGroupFailure,
  removeDistributionGroupRequest,
  removeDistributionGroupSuccess,
  restoreDistributionGroupFailure,
  restoreDistributionGroupRequest,
  restoreDistributionGroupSuccess,
  updateDistributionGroupFailure,
  updateDistributionGroupSuccess,
} from './actions';
import { selectHistory } from './selectors';

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load', label: 'Retry', action: request }));
}

export function* addSuccess({ payload: { response } }: PayloadAction<SuccessPayload>) {
  const undoAction = removeDistributionGroupRequest({ id: response.data.id });
  yield put(showSuccessNotification({ message: 'Distribution group added', label: 'Undo', action: undoAction }));
}

export function* addFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to add', label: 'Retry', action: request }));
}

export function* updateSuccess({ payload: { data } }: PayloadAction<SuccessPayload>): Generator {
  const entity = yield select(selectHistory(data.id));
  const undoAction = restoreDistributionGroupRequest({ id: data.id, body: entity });
  yield put(showSuccessNotification({ message: 'Distribution group updated', label: 'Undo', action: undoAction }));
}

export function* updateFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to update', label: 'Retry', action: request }));
}

export function* removeSuccess({ payload: { data } }: PayloadAction<SuccessPayload>) {
  const undoAction = recoverDistributionGroupRequest({ id: data.id });
  yield put(showWarningNotification({ message: 'Distribution group removed', label: 'Undo', action: undoAction }));
}

export function* removeFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to remove', label: 'Retry', action: request }));
}

export function* restoreSuccess() {
  yield put(showSuccessNotification({ message: 'Distribution group restored' }));
}

export function* restoreFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to restore', label: 'Retry', action: request }));
}

export function* recoverSuccess() {
  yield put(showSuccessNotification({ message: 'Distribution group recovered' }));
}

export function* recoverFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to recover', label: 'Retry', action: request }));
}

export function* effects() {
  yield takeEvery(loadDistributionGroupsFailure, loadFailure);
  yield takeEvery(addDistributionGroupSuccess, addSuccess);
  yield takeEvery(addDistributionGroupFailure, addFailure);
  yield takeEvery(updateDistributionGroupSuccess, updateSuccess);
  yield takeEvery(updateDistributionGroupFailure, updateFailure);
  yield takeEvery(removeDistributionGroupSuccess, removeSuccess);
  yield takeEvery(removeDistributionGroupFailure, removeFailure);
  yield takeEvery(restoreDistributionGroupSuccess, restoreSuccess);
  yield takeEvery(restoreDistributionGroupFailure, restoreFailure);
  yield takeEvery(recoverDistributionGroupSuccess, recoverSuccess);
  yield takeEvery(recoverDistributionGroupFailure, recoverFailure);
}
