import { createSelector } from '@reduxjs/toolkit';
import { intersection, isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import { selectAll, selectCustomerTimeEventStatus } from '../../entities/customers';
import { CustomerState } from '../../entities/customers/types';
import { RootState } from '../../reducer';
import { CustomerFilters, State } from './reducer';
import { AssociationsState, selectAssociations } from '../../associations';

export const selectCustomerTable = (state: RootState) => state.ui.customerTable;
export const selectHasFilters = createSelector(
  selectCustomerTable,
  ({ filters }: State) => !Object.values(filters).every((filter: any) => !filter.length)
);

export const selectFilteredCustomers = createSelector(
  selectCustomerTable,
  selectAll,
  selectAssociations,
  ({ sort, filters }: State, customers, associations) => {
    return orderBy(
      filter(customers, filters, associations),
      sortBy(sort.column),
      new Array(sortBy(sort.column).length).fill(sort.direction)
    );
  }
);

export const sortBy = (column: string): any => {
  switch (column) {
    case 'countries':
      return 'country_codes';
    case 'status':
      return [
        (customer: CustomerState) =>
          ['active', 'scheduled', 'expired', 'disabled'].indexOf(
            selectCustomerTimeEventStatus(customer)?.type ?? customer.status
          ),
        (customer: CustomerState) =>
          selectCustomerTimeEventStatus(customer)?.type === 'expired'
            ? -moment(customer.subscription_end ?? undefined).unix()
            : moment(customer.subscription_start ?? undefined).unix(),
      ];
    default:
      return column;
  }
};

const filter = (
  customers: CustomerState[],
  filters: CustomerFilters,
  associations: AssociationsState
): CustomerState[] => {
  return customers.filter((customer) => {
    customerHasNatures(customer, filters.natures, associations);
    const timeEventStatus = selectCustomerTimeEventStatus(customer);
    if (filters.natures.length && !customerHasNatures(customer, filters.natures, associations)) {
      return false;
    } else if (filters.countries.length && !intersection(customer.languages, filters.countries).length) {
      return false;
    } else if (filters.statuses.length && !filters.statuses.includes(timeEventStatus?.type ?? customer.status)) {
      return false;
    } else if (filters.priorities.length && !filters.priorities.includes(customer.priority)) {
      return false;
    } else if (
      filters.accountManagers.length &&
      (!customer.account_manager_id || !filters.accountManagers.includes(customer.account_manager_id))
    ) {
      return false;
    }

    return true;
  });
};

const customerHasNatures = ({ id }: CustomerState, natureIds: number[], associations: AssociationsState) => {
  const customerNatureAssociation = associations?.customers?.natures?.[id]?.ids;

  if (isEmpty(customerNatureAssociation)) {
    return false;
  } else {
    return intersection(customerNatureAssociation, natureIds).length > 0;
  }
};
