import { createReducer } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import {
  addRuleMappingSuccess,
  loadRuleMappingsFailure,
  loadRuleMappingsRequest,
  loadRuleMappingsSuccess,
  recoverRuleMappingSuccess,
  removeRuleMappingSuccess,
  restoreRuleMappingSuccess,
  updateRuleMappingSuccess,
} from './actions';

export type Status = 'active' | 'disabled' | 'deleted';

export interface RuleMapping {
  id: number;
  status: Status;
  name: string;
  language: string | null;
  origin_field: string;
  origin_value: string;
  target_field: string;
  target_value: string;
  target_operator: string;
}

export const adapter: EntityAdapter<RuleMapping> = createEntityAdapter<RuleMapping>({
  selectId: (entity) => entity.id,
  sort: (entities) => orderBy(entities, (entity) => entity.name),
});

export const reducer = createReducer<EntityState<RuleMapping>>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadRuleMappingsRequest, (state) => adapter.setLoading(state))
    .addCase(loadRuleMappingsSuccess, (state, { payload }) => adapter.addMany(payload.response.items, state))
    .addCase(loadRuleMappingsFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(addRuleMappingSuccess, (state, { payload }) => adapter.add(payload.response.data, state))
    .addCase(updateRuleMappingSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, payload.data.body, state)
    )
    .addCase(restoreRuleMappingSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, payload.data.body, state)
    )
    .addCase(recoverRuleMappingSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: 'active' }, state)
    )
    .addCase(removeRuleMappingSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: 'deleted' }, state)
    );
});
