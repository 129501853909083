import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import { FailurePayload } from '../../effects';
import { showErrorNotification } from '../../ui/notifications';
import { loadReceiverLeadFailure } from './actions';

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load', label: 'Retry', action: request }));
}

export function* effects() {
  yield takeEvery(loadReceiverLeadFailure, loadFailure);
}
