import { RootState } from '../../reducer';
import { createSelector } from '@reduxjs/toolkit';
import { CustomerLeadsFilters, State } from './reducer';
import { CustomerLead, selectAll } from '../../entities/customerLeads';
import { intersection, orderBy } from 'lodash';
import { sortBy } from '../incoming-leads-table';

export const selectCustomerLeadsTable = (state: RootState) => state.ui.customerLeadsTable;
export const selectSelectedCustomerLeads = createSelector(selectCustomerLeadsTable, ({ selected_leads }: State) => {
  return selected_leads;
});
export const selectHasFilters = createSelector(
  selectCustomerLeadsTable,
  ({ filters }: State) => !Object.values(filters).every((filter: any) => !filter.length)
);

export const selectCustomerLeadsFromTable = createSelector(
  selectCustomerLeadsTable,
  selectAll,
  ({ sort, filters, filteredIds }: State, customerLeads) => {
    const filteredCustomerLeads = filteredIds
      ? customerLeads.filter(({ id }) => filteredIds.includes(id))
      : customerLeads;

    const sortColumn =
      customerLeads.filter((lead) => {
        return sortBy(sort.column) in lead.lead_data;
      }).length > 0
        ? `lead_data[${sortBy(sort.column)}]`
        : customerLeads.filter((lead) => {
            return sortBy(sort.column) in lead;
          }).length > 0
        ? sortBy(sort.column)
        : '';
    //check to see if sorting column exists in customerLead or if if is a key in lead_data

    return orderBy(filter(filteredCustomerLeads, filters), sortColumn, [sort.direction]);
  }
);

const filter = (customerLeads: CustomerLead[], filters: CustomerLeadsFilters): CustomerLead[] => {
  return customerLeads.filter((customerLead) => {
    if (
      filters &&
      filters.natures &&
      filters.natures.length &&
      !filters.natures.filter((nature) => nature.nature_id === customerLead.nature_id)
    ) {
      return false;
    } else if (filters.countries.length && intersection(customerLead.country_codes, filters.countries).length) {
      return false;
    }
    return true;
  });
};
