import { createReducer } from '@reduxjs/toolkit';
import { User } from '../../auth-client';
import { logIn, logInFailure, logInSuccess, logOut } from './actions';

export interface AuthenticatedUser extends User {
  receiverId: number;
  securityGroups: Array<'admin' | 'customer' | 'dev' | 'sales'>;
}

export interface State {
  loading: boolean;
  token: string | null;
  user: AuthenticatedUser | null;
  error: string | null;
}

const initialState: State = { loading: true, token: null, user: null, error: null };

export const authReducer = createReducer<State>(initialState, (builder) => {
  builder
    .addCase(logIn, (state) => ({ ...state, loading: true }))
    .addCase(logInSuccess, (state, { payload: { token, user } }) => ({
      ...state,
      loading: false,
      token,
      user,
      error: null,
    }))
    .addCase(logInFailure, (state, { payload: { error } }) => ({ ...state, loading: false, error }))
    .addCase(logOut, () => initialState);
});
