import { createAction } from '@reduxjs/toolkit';
import { Column, Sort } from '../../../components/table';
import { LeadsFilters } from './reducer';

export const setIncomingLeadsSearch = createAction<{ search: string }>('SET_INCOMING_LEADS_SEARCH');
export const setSelectedIncomingLeads = createAction<{ selected_leads: number[] }>('SET_SELECTED_INCOMING_LEADS');
export const setIncomingLeadsFilters = createAction<{ filters: LeadsFilters }>('SET_INCOMING_LEADS_FILTERS');
export const setIncomingLeadsColumns = createAction<{ columns: Column[] }>('SET_INCOMING_LEADS_COLUMNS');
export const resetIncomingLeadsFilters = createAction('RESET_INCOMING_LEADS_FILTERS');
export const toggleIncomingLeadsColumn = createAction<{ column: Column }>('TOGGLE_INCOMING_LEADS_COLUMN');
export const sortIncomingLeads = createAction<{ sort: Sort }>('SORT_INCOMING_LEADS');
