import React, { Children, cloneElement, ReactNode, useState } from 'react';

import { Box, Button } from '@material-ui/core';

import { Dialog, DialogProps } from './Dialog';
import { useForm } from '../../hooks';

export type FormDialogProps = {
  children: ReactNode;
  actionLabel?: string;
  actions?: boolean;
  additionalActions?: ReactNode;
} & DialogProps;

FormDialog.defaultProps = {
  actionLabel: 'Save',
} as Partial<FormDialogProps>;

export function FormDialog({ actions, actionLabel, children, additionalActions, ...props }: FormDialogProps) {
  const { formId, setFormId } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Dialog
      {...props}
      actions={
        actions ? (
          <Box display="flex" justifyContent="flex-end">
            {additionalActions}
            <Button
              disabled={isSubmitting}
              color="primary"
              size="large"
              variant="contained"
              type="submit"
              form={formId}
            >
              {actionLabel}
            </Button>
          </Box>
        ) : (
          ''
        )
      }
    >
      <div ref={(ref) => ref && setFormId(ref)}>
        {Children.map(children, (child: any) => {
          return cloneElement(child, { formRef: (ref: any) => ref && setIsSubmitting(ref.isSubmitting) });
        })}
      </div>
    </Dialog>
  );
}
