import { createReducer } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import { loadAccountManagersFailure, loadAccountManagersRequest, loadAccountManagersSuccess } from './actions';

export interface AccountManager {
  id: number;
  status: 'active' | 'disabled' | 'deleted';
  name: string;
  email: string;
}

export interface State extends EntityState<AccountManager> {}

export const adapter: EntityAdapter<AccountManager> = createEntityAdapter<AccountManager>({
  sort: (entities) => orderBy(entities, 'name'),
});

export const reducer = createReducer<State>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadAccountManagersRequest, (state) => adapter.setLoading(state))
    .addCase(loadAccountManagersSuccess, (state, { payload }) => adapter.addMany(payload.response.items, state))
    .addCase(loadAccountManagersFailure, (state, { payload }) => adapter.setError(payload.error, state));
});
