import { createRequestActions, makeHttpPayload } from '../../effects';
import { Id } from '../adapter';
import { RuleMapping } from './reducer';

export type RuleMappingData = Partial<Exclude<RuleMapping, 'id'>>;

export const {
  request: loadRuleMappingsRequest,
  success: loadRuleMappingsSuccess,
  failure: loadRuleMappingsFailure,
} = createRequestActions('LOAD_RULE_MAPPINGS', () => ({
  http: makeHttpPayload('get', 'rule-mappings'),
}));

export const {
  request: addRuleMappingRequest,
  success: addRuleMappingSuccess,
  failure: addRuleMappingFailure,
} = createRequestActions('ADD_RULE_MAPPINGS', ({ body }: { body: RuleMappingData }) => ({
  http: makeHttpPayload('post', 'rule-mappings', body),
}));

export const {
  request: updateRuleMappingRequest,
  success: updateRuleMappingSuccess,
  failure: updateRuleMappingFailure,
} = createRequestActions('UPDATE_RULE_MAPPINGS', ({ id, body }: { id: Id; body: RuleMappingData }) => ({
  http: makeHttpPayload('post', `rule-mappings/${id}`, body),
  data: { id, body },
}));

export const {
  request: removeRuleMappingRequest,
  success: removeRuleMappingSuccess,
  failure: removeRuleMappingFailure,
} = createRequestActions('REMOVE_RULE_MAPPINGS', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `rule-mappings/${id}`, { status: 'deleted' }),
  data: { id },
}));

export const {
  request: restoreRuleMappingRequest,
  success: restoreRuleMappingSuccess,
  failure: restoreRuleMappingFailure,
} = createRequestActions('RESTORE_RULE_MAPPINGS', ({ id, body }: { id: Id; body: RuleMappingData }) => ({
  http: makeHttpPayload('post', `rule-mappings/${id}`, body),
  data: { id, body },
}));

export const {
  request: recoverRuleMappingRequest,
  success: recoverRuleMappingSuccess,
  failure: recoverRuleMappingFailure,
} = createRequestActions('RECOVER_RULE_MAPPINGS', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `rule-mappings/${id}`, { status: 'active' }),
  data: { id },
}));
