import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  ListSubheader,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  ArrowDropDown as ArrowDropDownIcon,
  ExitToApp as LogOutIcon,
  Menu as MenuIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons';
import { logOut, selectAuth } from '../../store/auth';
import { showSidebar } from '../../store/ui/sidebar';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  offset: theme.mixins.toolbar,
  profileButton: {
    color: 'white',
    display: 'flex',
    padding: 0,
    minHeight: 0,
    minWidth: 0,
  },
  menuLink: {
    color: 'inherit',
    border: 'none',
    textDecoration: 'none',
    '&:hover': {
      color: 'inherit',
    },
  },
}));

export function Header() {
  const theme = useTheme();
  const classes = useStyles();
  const { user } = useSelector(selectAuth);
  const [receiverId, setReceiverId] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      setReceiverId(user.receiverId);
    }
  }, [user]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((open) => !open);
  };

  function handleListKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab' || event.key === 'Escape') {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Box width="100%" display="flex">
            <Box
              display="flex"
              justifyContent={isMobile ? 'center' : 'flex-start'}
              alignItems="center"
              flexBasis="100%"
              order={!isMobile ? 1 : 2}
            >
              <img
                src="https://statisk.net/nettbureau/nettbureau-logo-square--white.svg"
                alt="Nettbureau"
                height={40}
                width={40}
              />
              {isMobile && (
                <Typography style={{ marginLeft: '5px' }} variant="subtitle2">{`Time zone: ${
                  Intl.DateTimeFormat().resolvedOptions().timeZone
                }`}</Typography>
              )}
              {!isMobile && (
                <Box ml={2}>
                  <Typography variant="h5">Leadbox</Typography>
                  <Typography variant="subtitle2">{`Time is shown in time zone: ${
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                  }`}</Typography>
                </Box>
              )}
            </Box>
            {isMobile && (
              <Box display="flex" justifyContent="flex-start" alignItems="center" flexBasis="100%" order={1}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={() => dispatch(showSidebar())}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            )}
            {user ? (
              <Box display="flex" justifyContent="flex-end" flexBasis="100%" order={3}>
                <Button ref={anchorRef} className={classes.profileButton} onClick={handleToggle}>
                  <Box mr={0.5}>
                    <Avatar>
                      <img src={user.picture} alt={user.name} height={40} width={40} />
                    </Avatar>
                  </Box>
                  <ArrowDropDownIcon fontSize="small" />
                </Button>
                <Popper open={open} anchorEl={anchorRef.current} placement="bottom-end" transition disablePortal>
                  {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <div>
                            <ListSubheader component="div">{user.name}</ListSubheader>
                            <Divider />
                            <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                              <MenuItem
                                onClick={handleClose}
                                component={Link}
                                to={`/receivers/${receiverId}`}
                                className={classes.menuLink}
                              >
                                <Box mr={2} display="flex" alignItems="center">
                                  <SettingsIcon color="action" fontSize="small" />
                                </Box>
                                <Typography variant="inherit">Settings</Typography>
                              </MenuItem>
                              <MenuItem onClick={() => dispatch(logOut())}>
                                <Box mr={2} display="flex" alignItems="center">
                                  <LogOutIcon color="action" fontSize="small" />
                                </Box>
                                <Typography variant="inherit">Log Out</Typography>
                              </MenuItem>
                            </MenuList>
                          </div>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Box>
            ) : null}
          </Box>
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
    </>
  );
}
