import { createReducer } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import { CustomerLead } from '../customerLeads';
import {
  loadCustomerLeadFailure,
  loadCustomerLeadRequest,
  loadCustomerLeadSuccess,
  updateCustomerLeadFailure,
  updateCustomerLeadRequest,
  updateCustomerLeadSuccess,
} from './actions';

export interface State extends EntityState<CustomerLead> {}

export const adapter: EntityAdapter<CustomerLead> = createEntityAdapter<CustomerLead>({
  sort: (entities) => orderBy(entities, 'id'),
});

export const reducer = createReducer<State>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadCustomerLeadRequest, (state) => adapter.setLoading(state))
    .addCase(loadCustomerLeadSuccess, (state, { payload }) =>
      adapter.replace(payload.response.items, state, payload.response.total_count)
    )
    .addCase(loadCustomerLeadFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(updateCustomerLeadRequest, (state) => adapter.setLoading(state))
    .addCase(updateCustomerLeadSuccess, (state, { payload }) =>
      adapter.update(payload.response.data.id, payload.response.data, state)
    )
    .addCase(updateCustomerLeadFailure, (state, { payload }) => adapter.setError(payload.error, state));
});
