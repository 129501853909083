import { createReducer } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import { leadData } from '../incomingLeads';
import {
  creditLeadsFailure,
  creditLeadsForCustomerFailure,
  creditLeadsForCustomerRequest,
  creditLeadsForCustomerSuccess,
  creditLeadsRequest,
  creditLeadsSuccess,
  loadCustomerLeadsFailure,
  loadCustomerLeadsRequest,
  loadCustomerLeadsSuccess,
  searchCustomerLeadsFailure,
  searchCustomerLeadsRequest,
  searchCustomerLeadsSuccess,
  updateCustomerLeadFailure,
  updateCustomerLeadRequest,
  updateCustomerLeadSuccess,
} from './actions';

export interface CustomerLead {
  id: number;
  incoming_id: number;
  customer_id: number;
  notes: string | null;
  date: string;
  rule_id: number | null;
  lead_price: number | null;
  discount: number | null;
  exclusive: boolean;
  flag?: string | null;
  free: Boolean;
  received_by: string[] | null;
  customer_name: String;
  customer_priority: number;
  customer_rule_priority: number | null;
  rule_name: string;
  status: undefined;
  nature_id: number;
  lead_data: leadData;
  [key: string]: any;
}

export interface State extends EntityState<CustomerLead> {}

export const adapter: EntityAdapter<CustomerLead> = createEntityAdapter<CustomerLead>({
  sort: (entities) => orderBy(entities, 'id'),
});

export const reducer = createReducer<State>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadCustomerLeadsRequest, (state) => adapter.setLoading(state))
    .addCase(loadCustomerLeadsSuccess, (state, { payload }) =>
      adapter.replace(
        payload.response.items,
        state,
        payload.response.total_count,
        payload.response.exclusive_count,
        payload.response.normal_count
      )
    )
    .addCase(loadCustomerLeadsFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(searchCustomerLeadsRequest, (state) => adapter.setLoading(state))
    .addCase(searchCustomerLeadsSuccess, (state, { payload }) =>
      adapter.replace(
        payload.response.items,
        state,
        payload.response.total_count,
        payload.response.exclusive_count,
        payload.response.normal_count
      )
    )
    .addCase(searchCustomerLeadsFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(updateCustomerLeadRequest, (state) => adapter.setLoading(state))
    .addCase(updateCustomerLeadSuccess, (state, { payload }) =>
      adapter.update(payload.response.data.id, payload.response.data, state)
    )
    .addCase(updateCustomerLeadFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(creditLeadsRequest, (state) => adapter.setLoading(state))
    .addCase(creditLeadsSuccess, (state) => adapter.setSuccess(state))
    .addCase(creditLeadsFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(creditLeadsForCustomerRequest, (state) => adapter.setLoading(state))
    .addCase(creditLeadsForCustomerSuccess, (state) => adapter.setSuccess(state))
    .addCase(creditLeadsForCustomerFailure, (state, { payload }) => adapter.setError(payload.error, state));
});
