import { put, select, takeEvery } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';

import { FailurePayload, SuccessPayload } from '../../effects';
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from '../../ui/notifications/actions';
import {
  createNatureFailure,
  createNatureSuccess,
  deleteNatureFailure,
  deleteNatureRequest,
  deleteNatureSuccess,
  loadNaturesFailure,
  recoverNatureFailure,
  recoverNatureRequest,
  recoverNatureSuccess,
  restoreNatureFailure,
  restoreNatureRequest,
  restoreNatureSuccess,
  updateNatureFailure,
  updateNatureSuccess,
} from './actions';
import { selectHistory } from './selectors';

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load', label: 'Retry', action: request }));
}

export function* createSuccess({ payload: { response } }: PayloadAction<SuccessPayload>) {
  const undoAction = deleteNatureRequest({ id: response.data.id });
  yield put(showSuccessNotification({ message: 'Nature created', label: 'Undo', action: undoAction }));
}

export function* createFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to create', label: 'Retry', action: request }));
}

export function* updateSuccess({ payload: { data } }: PayloadAction<SuccessPayload>): Generator {
  const nature = yield select(selectHistory(data.id));
  const undoAction = restoreNatureRequest({ id: data.id, body: nature });
  yield put(showSuccessNotification({ message: 'Nature updated', label: 'Undo', action: undoAction }));
}

export function* updateFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to update', label: 'Retry', action: request }));
}

export function* removeSuccess({ payload: { data } }: PayloadAction<SuccessPayload>) {
  const undoAction = recoverNatureRequest({ id: data.id });
  yield put(showWarningNotification({ message: 'Nature removed', label: 'Undo', action: undoAction }));
}

export function* removeFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to remove', label: 'Retry', action: request }));
}

export function* restoreSuccess() {
  yield put(showSuccessNotification({ message: 'Nature restored' }));
}

export function* restoreFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to restore', label: 'Retry', action: request }));
}

export function* recoverSuccess() {
  yield put(showSuccessNotification({ message: 'Nature recovered' }));
}

export function* recoverFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to recover', label: 'Retry', action: request }));
}

export function* effects() {
  yield takeEvery(loadNaturesFailure, loadFailure);
  yield takeEvery(createNatureSuccess, createSuccess);
  yield takeEvery(createNatureFailure, createFailure);
  yield takeEvery(updateNatureSuccess, updateSuccess);
  yield takeEvery(updateNatureFailure, updateFailure);
  yield takeEvery(deleteNatureSuccess, removeSuccess);
  yield takeEvery(deleteNatureFailure, removeFailure);
  yield takeEvery(restoreNatureSuccess, restoreSuccess);
  yield takeEvery(restoreNatureFailure, restoreFailure);
  yield takeEvery(recoverNatureSuccess, recoverSuccess);
  yield takeEvery(recoverNatureFailure, recoverFailure);
}
