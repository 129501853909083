import { createReducer } from '@reduxjs/toolkit';
import moment from 'moment';
import { Column, Sort } from '../../../components/table';
import { LeadsRule } from '../../../views/Leads/LeadUtils/components/LeadsHelpers';
import { Nature } from '../../entities/natures';
import {
  resetIncomingLeadsFilters,
  setIncomingLeadsColumns,
  setIncomingLeadsFilters,
  setIncomingLeadsSearch,
  setSelectedIncomingLeads,
  sortIncomingLeads,
  toggleIncomingLeadsColumn,
} from './actions';

export interface LeadsFilters {
  natures?: Nature[];
  countries?: string[];
  statuses?: string[];
  field_ids: LeadsRule[];
  before_date: string;
  after_date: string;
  type?: 'not-spam' | 'spam' | 'limbo' | 'failed' | 'all';
  limit: number;
  offset: number;
  page: number;
  customers?: number[];
}

export interface State {
  search: string;
  selected_leads: number[];
  filters: LeadsFilters;
  filteredIds: number[] | null;
  columns: Column[];
  sort: Sort;
}

export const baseFieldIds: LeadsRule[] = [
  { field_id_operator: 'equal', field: 'email', value_operator: 'equal', value: '', values: [] },
  { field_id_operator: 'equal', field: 'phone', value_operator: 'equal', value: '', values: [] },
  { field_id_operator: 'equal', field: 'address', value_operator: 'equal', value: '', values: [] },
  { field_id_operator: 'equal', field: 'name', value_operator: 'equal', value: '', values: [] },
  { field_id_operator: 'equal', field: 'general_needs', value_operator: 'equal', value: '', values: [] },
  { field_id_operator: 'equal', field: 'contact_type', value_operator: 'equal', value: '', values: [] },
  { field_id_operator: 'equal', field: 'sys_ip', value_operator: 'equal', value: '', values: [] },
  { field_id_operator: 'equal', field: 'district', value_operator: 'equal', value: '', values: [] },
  { field_id_operator: 'equal', field: 'state', value_operator: 'equal', value: '', values: [] },
  { field_id_operator: 'equal', field: 'region', value_operator: 'equal', value: '', values: [] },
];

export const initialState: State = {
  search: '',
  selected_leads: [],
  filters: {
    natures: [],
    countries: [],
    statuses: [],
    field_ids: baseFieldIds,
    after_date: moment
      .utc()
      .startOf('day')
      .subtract(2, 'months')
      .locale('YYYY-MM-DD HH:mm:ss')
      .format('YYYY-MM-DD HH:mm:ss'),
    before_date: moment.utc().endOf('day').locale('YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
    type: 'not-spam',
    limit: 25,
    offset: 0,
    page: 0,
  },
  filteredIds: null,
  columns: [
    { id: 'date', label: 'Date', visible: true },
    { id: 'name', label: 'Name', visible: true },
    { id: 'received_by', label: 'Received by', visible: true },
    { id: 'email', label: 'Email', visible: true },
    { id: 'state', label: 'State', visible: true },
    { id: 'address', label: 'Address', visible: true },
    { id: 'outgoing_leads_status', label: 'Outgoing leads status', visible: true },
    { id: 'sys_soruce', label: 'Sys source', visible: true },
  ],
  sort: {
    column: 'id',
    direction: 'desc',
  },
};

export const incomingLeadsTableReducer = createReducer<State>(initialState, (builder) => {
  builder
    .addCase(setIncomingLeadsSearch, (state, { payload }) => ({ ...state, search: payload.search }))
    .addCase(setSelectedIncomingLeads, (state, { payload }) => ({ ...state, selected_leads: payload.selected_leads }))
    .addCase(setIncomingLeadsFilters, (state, { payload }) => ({
      ...state,
      filters: {
        ...payload.filters,
        after_date: moment(payload.filters.after_date).format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment(payload.filters.before_date).format('YYYY-MM-DD HH:mm:ss'),
      },
      filteredIds: payload.filters.field_ids.length ? state.filteredIds : null,
    }))
    .addCase(setIncomingLeadsColumns, (state, { payload }) => ({ ...state, columns: payload.columns }))
    .addCase(resetIncomingLeadsFilters, (state) => ({ ...state, filters: initialState.filters, filteredIds: null }))
    .addCase(toggleIncomingLeadsColumn, (state, { payload }) => ({
      ...state,
      columns: state.columns.map((column) => (column.id === payload.column.id ? payload.column : column)),
    }))
    .addCase(sortIncomingLeads, (state, { payload }) => ({ ...state, sort: payload.sort }));
});
