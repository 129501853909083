import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { loadOutgoingLeadsFromIncomingIdRequest } from './actions';
import { adapter } from './reducer';

export const selectOutgoingLeadsFromIncomingId = (state: RootState) => state.entities.outgoingLeadsIncomingId;
export const { selectAll, selectById, selectHistory } = adapter.selectors(selectOutgoingLeadsFromIncomingId);

export function useOutgoingLeadFromIncomingId(incomingLeadId: string) {
  const { loaded, loading, error } = useSelector(selectOutgoingLeadsFromIncomingId);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!loaded && !loading && !error) {
      dispatch(loadOutgoingLeadsFromIncomingIdRequest({ incomingLeadId }));
    }
  }, [dispatch, loaded, loading, error]);

  const entities = useSelector(selectAll);
  return { loaded, loading, error, entities };
}
