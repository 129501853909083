import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../reducer';
import { loadNaturesRequest } from './actions';
import { adapter, Nature } from './reducer';

export const selectNatures = (state: RootState) => state.entities.natures;
export const { selectAll, selectById, selectHistory } = adapter.selectors(selectNatures);
export const selectExisting = createSelector(selectAll, (natures: Nature[]) =>
  natures.filter(nature => nature.status !== 'deleted')
);

export function useNatures() {
  const { loaded, loading, error } = useSelector(selectNatures);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded && !loading && !error) {
      dispatch(loadNaturesRequest());
    }
  }, [dispatch, loaded, loading, error]);

  return useSelector<RootState, Nature[]>(selectExisting);
}
