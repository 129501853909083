export enum Protocol {
  LEADBOX = 'Leadbox',
  LEADMAIL_V2 = 'LeadmailV2',
  LEADSMS = 'Leadsms',
  LEADBOX_CONNECT = 'LeadboxConnect',
  LEADBOX_CONNECT_RESTRICT = 'LeadboxConnectRestrict',
  PROXY = 'Proxy',
  PROXY_RESTRICT = 'ProxyRestrict',
}

export enum Status {
  DELETED = 'deleted',
  DISABLED = 'disabled',
  ACTIVE = 'active',
}

export type IncomingLeadFlag = 'duplicate' | 'free' | 'spam';

export type CustomerLeadFlag = 'free' | 'similar';

export interface Connection {
  id: number;
  config: object | null;
  name: string;
  accepted_flags: Array<IncomingLeadFlag | CustomerLeadFlag>;
  protocol: Protocol;
  status: Status;
  receiver_ids: number[];
  customer_count: number;
}

export type ConnectionData = Pick<Connection, 'name' | 'protocol' | 'accepted_flags' | 'config'>;
