import { AuthClient } from './auth-client';

export const createApiClient = (authClient: AuthClient): ApiClient => new ApiClient(authClient);

export interface Query {
  format?: 'xlsx' | 'csv' | 'json';
  export_fields?: string[];
  medium?: 'inline' | 'file';
  [key: string]: any;
}

export interface RequestBody {
  [key: string]: any;
}

export interface Response<D = null, M = null> {
  data?: D;
  items?: D;
  meta?: M;
  total_count: number;
  item_count: number;
  normal_count: number;
  exclusive_count: number;
  result?: D;
}

export class ApiClient {
  constructor(private authClient: AuthClient) {}

  async get(path: string, query?: Query): Promise<Response> {
    return this.makeRequest('get', path, undefined, query);
  }

  async post(path: string, body?: RequestBody, query?: Query): Promise<Response> {
    return this.makeRequest('post', path, body, query);
  }

  async makeRequest(method: 'get' | 'post', path: string, body?: RequestBody, query?: Query): Promise<Response> {
    const token = await this.authClient.getToken();
    const url = resolveUrl(path, query);
    const response = await fetch(url, {
      method,
      body: resolveBody(method, body),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const contentType = response.headers.get('Content-Type') ?? '';
      const responseData = contentType.includes('application/json') ? await response.json() : await response.text();
      await handleContentDisposition(response, responseData, query);
      return responseData;
    } else {
      throw new Error(await response.text());
    }
  }
}

function resolveBody(method: 'get' | 'post', body?: RequestBody) {
  return method === 'post' && body ? JSON.stringify(body) : undefined;
}

function resolveUrl(path: string, query?: Query) {
  const apiUrl = window.location.hostname.startsWith('test.')
    ? process.env.REACT_APP_API_URL_TEST
    : process.env.REACT_APP_API_URL;
  const queryString = query && Object.entries(query).filter(nonEmptyValue).map(encodeParameter).join('&');
  return queryString ? `${apiUrl}/${path}?${queryString}` : `${apiUrl}/${path}`;
}

function nonEmptyValue([, value]: [string, unknown]) {
  return value !== null && value !== undefined && value !== '' && !(Array.isArray(value) && value.length === 0);
}

function encodeParameter([key, value]: [string, unknown]) {
  return `${key}=${encodeValue(value)}`;
}

function encodeValue(value: unknown): string {
  if (Array.isArray(value)) {
    return value.map(encodeValue).join();
  } else if (typeof value === 'object') {
    return JSON.stringify(value);
  } else {
    return String(value);
  }
}

async function handleContentDisposition(response: globalThis.Response, responseData: unknown, query?: Query) {
  const contentDisposition = response.headers.get('Content-Disposition') ?? '';
  if (contentDisposition.includes('attachment')) {
    const format = query?.format ?? 'json';
    const filename = contentDisposition.split('filename=')?.[1] ?? `download.${format}`;
    const content = await parseDocumentData(format, responseData);
    await downloadAsFile(filename, content);
  }
}

function parseDocumentData(format: 'json' | 'csv' | 'xlsx', data: unknown) {
  switch (format) {
    case 'json':
      return new Blob([JSON.stringify(data)], { type: 'application/json;charset=utf-8;' });
    case 'csv':
      return new Blob(['\ufeff' + data], { type: 'text/csv;charset=utf-8;' });
    case 'xlsx':
      return fetch(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`).then(
        (result) => result.blob()
      );
    default:
      throw new Error(`Could not download file, unknown format ${format}`);
  }
}

async function downloadAsFile(filename: string, content: Blob) {
  const url = window.URL.createObjectURL(content);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
