import { createRequestActions, get, post } from '../../effects';
import { Id } from '../adapter';

export const {
  request: loadCustomerLeadsFromIncomingIdRequest,
  success: loadCustomerLeadsFromIncomingIdSuccess,
  failure: loadCustomerLeadsFromIncomingIdFailure,
} = createRequestActions('LOAD_CUSTOMER_LEADS_FROM_INCOMING_ID', ({ incomingLeadId }: { incomingLeadId: string }) => {
  return {
    http: get('leads/customer/incoming-id', {
      incoming_id: incomingLeadId,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }),
  };
});

export const {
  request: updateCustomerLeadsFromIncomingIdRequest,
  success: updateCustomerLeadsFromIncomingIdSuccess,
  failure: updateCustomerLeadsFromIncomingIdFailure,
} = createRequestActions('UPDATE_CUSTOMER_LEADS_FROM_INCOMING_ID', ({ id, flag }: { id: Id; flag: string | null }) => {
  return {
    http: post('leads/customer/', {
      id,
      flag,
    }),
  };
});
