import {
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  Build as ToolIcon,
  Business as BusinessIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Inbox as InboxIcon,
  Input as InputIcon,
  People as PeopleIcon,
  Receipt as ReceiptIcon,
  Send as SendIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useToggle } from '../../hooks';
import { selectAuth } from '../../store/auth/selectors';
import { selectSidebar } from '../../store/ui/sidebar/selectors';
import { hideSidebar } from '../../store/ui/sidebar';

export const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  nested: {
    paddingLeft: theme.spacing(11),
  },
  toolbar: theme.mixins.toolbar,
  link: {
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  activeLink: {
    color: '#0059b3',
    '& span': {
      fontWeight: 600,
    },
    '& svg': {
      color: '#0059b3',
    },
  },
}));

export interface SimpleNavItemProps {
  to: string;
  text: string;
  onClick: () => void;
}

const SimpleNavItem = ({ to, text, onClick }: SimpleNavItemProps) => {
  const classes = useStyles();
  return (
    <NavLink to={to} className={classes.link} activeClassName={classes.activeLink} onClick={onClick}>
      <ListItem button className={classes.nested}>
        <ListItemText primary={text} />
      </ListItem>
    </NavLink>
  );
};

const IconNavItem = ({
  to,
  text,
  onClick,
  children,
}: {
  to: string;
  text: string;
  onClick: () => void;
  children: React.ReactElement;
}) => {
  const classes = useStyles();
  return (
    <NavLink to={to} className={classes.link} activeClassName={classes.activeLink} onClick={onClick}>
      <ListItem button>
        <ListItemIcon>{children}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    </NavLink>
  );
};

export function Sidebar() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector(selectAuth);
  const [groups, setGroups] = useState<string[]>([]);
  const [dispatchesOpen, toggleDispatchesOpen] = useToggle(false);
  const [toolsOpen, toggleToolsOpen] = useToggle(false);
  const [settingsOpen, toggleSettingsOpen] = useToggle(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { open: drawerOpen } = useSelector(selectSidebar);
  const hideNavigationDrawer = () => dispatch(hideSidebar());

  useEffect(() => {
    if (user) {
      setGroups(user.securityGroups);
    }
  }, [user]);

  const navContent = (
    <>
      {!isMobile && <div className={classes.toolbar} />}
      {groups.includes('admin') ? (
        <>
          <List>
            <IconNavItem onClick={hideNavigationDrawer} to="/leads/incoming" text="Leads">
              <InboxIcon />
            </IconNavItem>
          </List>

          <ListItem button onClick={() => toggleDispatchesOpen()}>
            <ListItemIcon>
              <SendIcon />
            </ListItemIcon>
            <ListItemText primary="Dispatches" />
            {dispatchesOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={dispatchesOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <SimpleNavItem onClick={hideNavigationDrawer} to="/specific-leads/customer" text="Customers" />
              <SimpleNavItem onClick={hideNavigationDrawer} to="/specific-leads/outgoing" text="Receivers" />
            </List>
          </Collapse>
          <Divider />
          <List>
            <IconNavItem
              onClick={hideNavigationDrawer}
              to="/admin-tools/distribution-settings"
              text="Distribution settings"
            >
              <InputIcon />
            </IconNavItem>
            <IconNavItem onClick={hideNavigationDrawer} to="/customers" text="Customers">
              <BusinessIcon />
            </IconNavItem>
            <IconNavItem onClick={hideNavigationDrawer} to="/receivers" text="Receivers">
              <PeopleIcon />
            </IconNavItem>
          </List>
          <Divider />
          <List>
            <ListItem button onClick={() => toggleToolsOpen()}>
              <ListItemIcon>
                <ToolIcon />
              </ListItemIcon>
              <ListItemText primary="Tools" />
              {toolsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={toolsOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <SimpleNavItem onClick={hideNavigationDrawer} to="/tools/insights" text="Insights" />
                <SimpleNavItem onClick={hideNavigationDrawer} to="/tools/invoice" text="Invoice Generator" />
              </List>
            </Collapse>
            <ListItem button onClick={() => toggleSettingsOpen()}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
              {settingsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <SimpleNavItem onClick={hideNavigationDrawer} to="/admin-tools/spam-filters" text="Spam Filters" />
                <SimpleNavItem
                  onClick={hideNavigationDrawer}
                  to="/admin-tools/field-translations"
                  text="Translations"
                />
                <SimpleNavItem onClick={hideNavigationDrawer} to="/admin-tools/rule-mappings" text="Rule mappings" />
                <SimpleNavItem onClick={hideNavigationDrawer} to="/admin-tools/natures" text="Natures" />
                <SimpleNavItem onClick={hideNavigationDrawer} to="/admin-tools/countries" text="Countries" />
                {groups.includes('dev') ? (
                  <>
                    <SimpleNavItem onClick={hideNavigationDrawer} to="/dev-tools/field-access" text="Field Access" />
                    <SimpleNavItem onClick={hideNavigationDrawer} to="/dev-tools/connections" text="Connections" />
                  </>
                ) : null}
              </List>
            </Collapse>
          </List>
        </>
      ) : groups.includes('customer') ? (
        <List>
          <IconNavItem onClick={hideNavigationDrawer} to="/leads" text="Leads">
            <InputIcon />
          </IconNavItem>
          <IconNavItem onClick={hideNavigationDrawer} to="/invoice-insights" text="Invoice Insights">
            <ReceiptIcon />
          </IconNavItem>
        </List>
      ) : groups.includes('sales') ? (
        <List>
          <IconNavItem onClick={hideNavigationDrawer} to="/leads" text="Leads">
            <InputIcon />
          </IconNavItem>
          <IconNavItem onClick={hideNavigationDrawer} to="/customers" text="Customers">
            <InputIcon />
          </IconNavItem>
        </List>
      ) : null}
    </>
  );

  return (
    <nav>
      {!isMobile ? (
        <Drawer
          elevation={6}
          variant="persistent"
          anchor="left"
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          open={true}
        >
          {navContent}
        </Drawer>
      ) : (
        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={hideNavigationDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {navContent}
        </Drawer>
      )}
    </nav>
  );
}
