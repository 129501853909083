import moment from 'moment';
import { Column } from '../../../components/table';
import {
  buildFieldQueryStringForLeads,
  nonEmptyFieldIds,
} from '../../../views/Leads/LeadUtils/components/LeadsHelpers';
import { createRequestActions, get, post } from '../../effects';
import { CustomerLeadsFilters } from '../../ui/customer-leads-table/reducer';
import { Id } from '../adapter';

export const {
  request: loadCustomerLeadsRequest,
  success: loadCustomerLeadsSuccess,
  failure: loadCustomerLeadsFailure,
} = createRequestActions(
  'LOAD_CUSTOMER_LEADS',
  ({ filters, count, customerId }: { filters: CustomerLeadsFilters; count?: boolean; customerId?: number }) => {
    const fieldIdSearch = nonEmptyFieldIds(filters.field_ids);
    return {
      http: get('leads/customer', {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        customer_id: customerId,
        limit: filters.limit,
        after_date: moment(filters.after_date).format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment(filters.before_date).format('YYYY-MM-DD HH:mm:ss'),
        countries: filters.countries,
        natures: filters.natures,
        offset: filters.offset,
        field_ids: fieldIdSearch.length > 0 ? buildFieldQueryStringForLeads(filters.field_ids) : '',
      }),
    };
  }
);

export const downloadCustomerLeads = createRequestActions(
  'DOWNLOAD_CUSTOMER_LEADS',
  ({
    format,
    filters,
    columns,
    id,
  }: {
    format: 'xlsx' | 'csv' | 'json';
    filters: CustomerLeadsFilters;
    columns: Column[];
    id: number;
  }) => {
    return {
      http: get(`leads/customer`, {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        after_date: moment(filters.after_date).format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment(filters.before_date).format('YYYY-MM-DD HH:mm:ss'),
        export_fields: columns
          .filter((column) => column.visible === true)
          .map((column) => {
            return column.label;
          }),
        countries: filters.countries,
        natures: filters.natures,
        format,
        limit: 5000,
        offset: 0,
        medium: 'file',
        customer_id: id,
      }),
    };
  }
);

export const {
  request: searchCustomerLeadsRequest,
  success: searchCustomerLeadsSuccess,
  failure: searchCustomerLeadsFailure,
} = createRequestActions(
  'SEARCH_CUSTOMER_LEADS',
  ({ search, filters }: { search: string; filters: CustomerLeadsFilters }) => {
    return {
      http: get(`leads/customer`, {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        limit: filters.limit,
        search: search,
        countries: filters.countries,
        natures: filters.natures,
        after_date: filters.after_date,
        before_date: filters.before_date,
        offset: filters.offset,
      }),
    };
  }
);

export const {
  request: updateCustomerLeadRequest,
  success: updateCustomerLeadSuccess,
  failure: updateCustomerLeadFailure,
} = createRequestActions('UPDATE_CUSTOMER_LEAD', ({ id, flag }: { id: Id; flag: string | null }) => {
  return {
    http: post('leads/customer/', {
      id,
      flag,
    }),
  };
});

export const {
  request: creditLeadsRequest,
  success: creditLeadsSuccess,
  failure: creditLeadsFailure,
} = createRequestActions(
  'CREDIT_LEADS',
  ({
    ids,
    filters,
    flag,
  }: {
    ids: number | number[] | undefined;
    filters: CustomerLeadsFilters;
    flag: string | null;
  }) => {
    const formattedCustomerLeadIds = Array.isArray(ids) ? ids : ids ? [ids] : [];
    return {
      http: post('credit-multiple-leads/', {
        customer_lead_ids: formattedCustomerLeadIds,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        after_date: moment(filters.after_date).format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment(filters.before_date).format('YYYY-MM-DD HH:mm:ss'),
        countries: filters.countries.length > 0 ? filters.countries : undefined,
        natures: filters.natures.length > 0 ? filters.natures : undefined,
        flag,
      }),
    };
  }
);

export const {
  request: creditLeadsForCustomerRequest,
  success: creditLeadsForCustomerSuccess,
  failure: creditLeadsForCustomerFailure,
} = createRequestActions(
  'CREDIT_LEADS_FOR_CUSTOMER',
  ({
    ids,
    filters,
    flag,
    customerId,
  }: {
    ids: number | number[] | undefined;
    filters: CustomerLeadsFilters;
    flag: string | null;
    customerId: Id;
  }) => {
    const formattedCustomerLeadIds = Array.isArray(ids) ? ids : ids ? [ids] : [];
    return {
      http: post('credit-multiple-leads-for-customer/', {
        customer_lead_ids: formattedCustomerLeadIds,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        after_date: moment(filters.after_date).format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment(filters.before_date).format('YYYY-MM-DD HH:mm:ss'),
        flag,
        customer_id: customerId,
      }),
    };
  }
);
