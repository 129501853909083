import { Box, Popover, PopoverOrigin, Typography } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import React, { MouseEvent, ReactNode, useState } from 'react';
import { IconButton } from './IconButton';

export interface InfoPopoverProps {
  disabled?: boolean;
  title: string;
  children: ReactNode;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export function InfoPopover(props: InfoPopoverProps) {
  const { disabled, title, children, anchorOrigin, transformOrigin } = props;

  const [anchorElement, setAnchorElement] = useState<Element | null>(null);

  const handleClick = (event: MouseEvent) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <IconButton title={title} disabled={disabled} onClick={handleClick} Icon={InfoIcon} />
      <Popover
        open={!!anchorElement}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={
          anchorOrigin ?? {
            vertical: 'center',
            horizontal: 'right',
          }
        }
        transformOrigin={
          transformOrigin ?? {
            vertical: 'center',
            horizontal: 'left',
          }
        }
      >
        <Box p={2} minWidth={260} maxWidth={700}>
          <Typography variant="h6" component="h3" gutterBottom>
            {title}
          </Typography>
          {typeof children === 'string' ? <Typography variant="body1">{children}</Typography> : children}
        </Box>
      </Popover>
    </>
  );
}
