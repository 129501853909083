import { createRequestActions, get, makeHttpPayload } from '../../effects';
import { Id } from '../adapter';
import { CustomerEntity } from './types';

export type CustomerData = Partial<Exclude<CustomerEntity, 'id'>>;

export const {
  request: loadCustomersRequest,
  success: loadCustomersSuccess,
  failure: loadCustomersFailure,
} = createRequestActions('LOAD_CUSTOMERS', () => ({
  http: makeHttpPayload('get', 'customers'),
}));

export const {
  request: loadCustomerRequest,
  success: loadCustomerSuccess,
  failure: loadCustomerFailure,
} = createRequestActions('LOAD_CUSTOMER', ({ id }: { id: number }) => ({
  http: makeHttpPayload('get', `customers?customer_id=${id}`),
}));


export const downloadCustomers = createRequestActions(
  'DOWNLOAD_CUSTOMERS',
  ({ format }: { format: 'xlsx' | 'csv' | 'json' }) => ({
    http: get('customers', { format, medium: 'file' }),
  })
);

export const {
  request: createCustomerRequest,
  success: createCustomerSuccess,
  failure: createCustomerFailure,
} = createRequestActions('CREATE_CUSTOMER', ({ body }: { body: CustomerData }) => ({
  http: makeHttpPayload('post', 'customers', body),
}));

export const {
  request: updateCustomerRequest,
  success: updateCustomerSuccess,
  failure: updateCustomerFailure,
} = createRequestActions('UPDATE_CUSTOMER', ({ id, body }: { id: Id; body: CustomerData }) => ({
  http: makeHttpPayload('post', `customers/${id}`, body),
  data: { id, body },
}));

export const {
  request: removeCustomerRequest,
  success: removeCustomerSuccess,
  failure: removeCustomerFailure,
} = createRequestActions('REMOVE_CUSTOMER', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `customers/${id}`, { status: 'deleted' }),
  data: { id },
}));

export const {
  request: restoreCustomerRequest,
  success: restoreCustomerSuccess,
  failure: restoreCustomerFailure,
} = createRequestActions('RESTORE_CUSTOMER', ({ id, body }: { id: Id; body: CustomerData }) => ({
  http: makeHttpPayload('post', `customers/${id}`, body),
  data: { id, body },
}));

export const {
  request: recoverCustomerRequest,
  success: recoverCustomerSuccess,
  failure: recoverCustomerFailure,
} = createRequestActions('RECOVER_CUSTOMER', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `customers/${id}`, { status: 'active' }),
  data: { id },
}));
