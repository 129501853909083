import { createSelector } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { EntityState } from '../adapter';
import { loadRuleMappingsRequest } from './actions';
import { adapter, RuleMapping } from './reducer';

export const selectRuleMappings = (state: RootState): EntityState<RuleMapping> => state.entities.ruleMappings;
export const { selectAll, selectById, selectHistory } = adapter.selectors(selectRuleMappings);
export const selectExisting = createSelector(selectAll, (entities: RuleMapping[]) =>
  entities.filter((entity) => entity.status !== 'deleted')
);

export function useRuleMappings() {
  const { loaded, loading, error } = useSelector(selectRuleMappings);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded && !loading && !error) {
      dispatch(loadRuleMappingsRequest());
    }
  }, [dispatch, loaded, loading, error]);

  const entities = useSelector<RootState, RuleMapping[]>(selectExisting);
  return { loaded, loading, error, entities };
}
