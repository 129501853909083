import { createSelector } from '@reduxjs/toolkit';
import { intersection, orderBy } from 'lodash';
import { OutgoingLead, selectAll } from '../../entities/outgoingLeads';
import { RootState } from '../../reducer';
import { OutgoingLeadsFilters, State } from './reducer';
import { sortBy } from '../incoming-leads-table';

export const selectOutgoingLeadsTable = (state: RootState) => state.ui.outgoingLeadsTable;
export const selectSelectedOutgoingLeads = createSelector(
  selectOutgoingLeadsTable,
  ({ selected_leads }: State) => selected_leads
);
export const selectHasFilters = createSelector(
  selectOutgoingLeadsTable,
  ({ filters }: State) => !Object.values(filters).every((filter: any) => !filter.length)
);

export const selectOutgoingLeadsFromTable = createSelector(
  selectOutgoingLeadsTable,
  selectAll,
  ({ sort, filters, filteredIds }: State, outgoingLeads) => {
    const filteredOutgoingLeads = filteredIds
      ? outgoingLeads.filter(({ id }) => filteredIds.includes(id))
      : outgoingLeads;

    const sortColumn =
      outgoingLeads.filter((lead) => {
        return sortBy(sort.column) in lead.lead_data;
      }).length > 0
        ? `lead_data[${sortBy(sort.column)}]`
        : outgoingLeads.filter((lead) => {
            return sortBy(sort.column) in lead;
          }).length > 0
        ? sortBy(sort.column)
        : '';
    //check to see if sorting column exists in outgoingLead or if if is a key in lead_data

    return orderBy(
      filter(filteredOutgoingLeads, filters),
      sortColumn,
      new Array(sortBy(sort.column).length).fill(sort.direction)
    );
  }
);

const filter = (outgoingLeads: OutgoingLead[], filters: OutgoingLeadsFilters): OutgoingLead[] => {
  return outgoingLeads.filter((outgoingLead) => {
    if (
      filters &&
      filters.natures &&
      filters.natures.length &&
      !filters.natures.filter((nature) => nature.nature_id === outgoingLead.nature_id)
    ) {
      return false;
    } else if (filters.countries.length && intersection(outgoingLead.country_codes, filters.countries).length) {
      return false;
    }
    return true;
  });
};
