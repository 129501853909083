import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';

import { dismissNotification, notificationDismissed, selectNotifications } from '../../store/ui/notifications';
import { Notification as NotificationModel } from '../../store/ui/notifications/reducer';
import { Notification } from './Notification';

export function Notifier() {
  const [opened, setOpened] = useState<number[]>([]);
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const notifications = useSelector(selectNotifications);
  const dispatch = useDispatch();

  useEffect(() => {
    notifications.forEach((notification: NotificationModel) => {
      if (!notification.open) {
        return dismiss(notification.key);
      } else if (opened.includes(notification.key)) {
        return;
      }

      show(notification);
    });
  });

  const show = (notification: NotificationModel) => {
    const { key, message } = notification;

    enqueueSnackbar(message, {
      key,
      autoHideDuration: 6000,
      content: <Notification notification={notification} onAction={handleAction} onDismiss={dismiss} />,
      onExited: () => handleDismissed(key),
    });

    setOpened((opened) => [...opened, key]);
  };

  const dismiss = (key: number) => closeSnackbar(key);

  const handleDismissed = (key: number) => {
    dispatch(notificationDismissed({ key }));
    setOpened((opened) => opened.filter((item) => item !== key));
  };

  const handleAction = (key: number, action: Action) => {
    dispatch(dismissNotification({ key }));
    dispatch(action);
  };

  return null;
}
