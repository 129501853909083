import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import { FailurePayload, SuccessPayload } from '../../effects';
import { showErrorNotification, showSuccessNotification } from '../../ui/notifications/actions';
import { actions } from './actions';

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load insights', label: 'Retry', action: request }));
}

export function* downloadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to download insights', label: 'Retry', action: request }));
}

export function* downloadSuccess(action: PayloadAction<SuccessPayload>) {
  yield put(showSuccessNotification({ message: 'Insights downloaded' }));
}

export function* effects() {
  yield takeEvery(actions.loadInsights.failure, loadFailure);
  yield takeEvery(actions.downloadInsights.failure, downloadFailure);
  yield takeEvery(actions.downloadInsights.success, downloadSuccess);
}
