import { createAction } from '@reduxjs/toolkit';

import { AuthenticatedUser } from './reducer';

export interface LogInSuccessPayload {
  token: string;
  user: AuthenticatedUser;
}

export interface LogInFailurePayload {
  error: string;
}

export const logIn = createAction('LOG_IN');
export const logInSuccess = createAction<LogInSuccessPayload>('LOG_IN_SUCCESS');
export const logInFailure = createAction<LogInFailurePayload>('LOG_IN_FAILURE');
export const logOut = createAction('LOG_OUT');
