import { createReducer } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import { IncomingLead } from '../incomingLeads';
import {
  deletePersonalDataOnIncomingLeadFailure,
  deletePersonalDataOnIncomingLeadRequest,
  deletePersonalDataOnIncomingLeadSuccess,
  loadIncomingLeadFailure,
  loadIncomingLeadRequest,
  loadIncomingLeadSuccess,
  updateIncomingLeadFailure,
  updateIncomingLeadRequest,
  updateIncomingLeadSuccess,
} from './actions';

export interface State extends EntityState<IncomingLead> {}

export const adapter: EntityAdapter<IncomingLead> = createEntityAdapter<IncomingLead>({
  sort: (entities) => orderBy(entities, 'id'),
});

export const reducer = createReducer<State>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadIncomingLeadRequest, (state) => adapter.setLoading(state))
    .addCase(loadIncomingLeadSuccess, (state, { payload }) =>
      adapter.replace(payload.response.items, state, payload.response.total_count)
    )
    .addCase(loadIncomingLeadFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(updateIncomingLeadRequest, (state) => adapter.setLoading(state))
    .addCase(updateIncomingLeadSuccess, (state, { payload }) =>
      adapter.update(payload.response.data.id, payload.response.data, state)
    )
    .addCase(updateIncomingLeadFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(deletePersonalDataOnIncomingLeadRequest, (state) => adapter.setLoading(state))
    .addCase(deletePersonalDataOnIncomingLeadSuccess, (state) => adapter.setSuccess(state))
    .addCase(deletePersonalDataOnIncomingLeadFailure, (state, { payload }) => adapter.setError(payload.error, state));
});
