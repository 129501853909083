import { createAction } from '@reduxjs/toolkit';
import { Column, Sort } from '../../../components/table';
import { createRequestActions, get } from '../../effects';
import { InsightsFilter } from './reducer';

// TODO: this utility can be centralized and reused across tables
export function createTableActions<T>(type: string) {
  return {
    search: createAction<{ search: string }>(`TABLE/search-${type}`),
    setFilters: createAction<{ filters: T }>(`TABLE/set-filters-${type}`),
    resetFilters: createAction(`TABLE/reset-filters-${type}`),
    toggleColumns: createAction<{ column: Column }>(`TABLE/toggle-columns-${type}`),
    sort: createAction<{ sort: Sort }>(`TABLE/sort-${type}`),
  };
}

type InsightSecurityGroups = 'admin' | 'receiver';

export const tableActions = createTableActions<InsightsFilter>('insights');
export const loadInsights = createRequestActions(
  'LOAD_INSIGHTS',
  ({ payload, userType }: { payload: InsightsFilter; userType: InsightSecurityGroups }) => ({
    http: get(`insights/${userType}`, payload),
  })
);

export const downloadInsights = createRequestActions(
  'DOWNLOAD_INSIGHTS',
  ({
    payload,
    userType,
  }: {
    payload: InsightsFilter & { format: 'xlsx' | 'csv' | 'json' };
    userType: InsightSecurityGroups;
  }) => ({
    http: get(`insights/${userType}`, { ...payload, medium: 'file' }),
  })
);

export const actions = {
  ...tableActions,
  loadInsights,
  downloadInsights,
};
