import { merge } from 'lodash';

export function authorizedFetch(url, config) {
  const configWithBearer = merge(
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('nettbureau-login-token')}`,
      },
    },
    config
  );

  return fetch(url, configWithBearer).then((response) => {
    if (response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }

    return response;
  });
}
