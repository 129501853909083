import { combineReducers } from 'redux';

import { customersTableReducer } from './customers-table';
import { notificationsReducer } from './notifications';
import { sidebarReducer } from './sidebar';
import { connectionsTableReducer } from './connections-table/reducer';
import { receiversTableReducer } from './receivers-table/reducer';
import { incomingLeadsTableReducer } from './incoming-leads-table';
import { outgoingLeadsTableReducer } from './outgoing-leads-table';
import { customerLeadsTableReducer } from './customer-leads-table';
import { receiverLeadsTableReducer } from './receiver-leads-table';

export const uiReducer = combineReducers({
  customerTable: customersTableReducer,
  connectionsTable: connectionsTableReducer,
  customerLeadsTable: customerLeadsTableReducer,
  incomingLeadsTable: incomingLeadsTableReducer,
  outgoingLeadsTable: outgoingLeadsTableReducer,
  receiverLeadsTable: receiverLeadsTableReducer,
  receiversTable: receiversTableReducer,
  notifications: notificationsReducer,
  sidebar: sidebarReducer,
});

export type UiState = ReturnType<typeof uiReducer>;
