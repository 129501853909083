import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../reducer';
import { adapter } from '../crm';

export const crmSelector = (state: RootState) => {
  return state.entities.crm;
};

export const { selectAll } = adapter.selectors(crmSelector);

export function selectUsersInOrganization() {
  return createSelector(selectAll, (users) => users);
}
