import { createReducer } from '@reduxjs/toolkit';
import { Column, Sort } from '../../../components/table';
import {
  resetReceiversFilters,
  searchReceivers,
  setReceiversFilter,
  sortReceivers,
  toggleReceiversColumn,
} from './actions';

export interface ReceiversTableState {
  search: string;
  filters: ReceiversFilter;
  columns: Column[];
  sort: Sort;
}

export interface ReceiversFilter {
  protocols: string[];
  statuses: string[];
}

export const receiverTableColumns: Column[] = [
  { id: 'name', label: 'Name', visible: true },
  { id: 'email', label: 'Email', visible: true },
  { id: 'language', label: 'Language', visible: true },
  { id: 'status', label: 'Status', visible: true },
  { id: 'actions', label: '', visible: true },
];

export const initialState: ReceiversTableState = {
  search: '',
  filters: {
    protocols: [],
    statuses: [],
  },
  columns: receiverTableColumns,
  sort: {
    column: 'name',
    direction: 'asc',
  },
};

export const receiversTableReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(searchReceivers, (state, { payload }) => ({ ...state, search: payload.search }))
    .addCase(setReceiversFilter, (state, { payload }) => ({ ...state, filters: payload.filters }))
    .addCase(toggleReceiversColumn, (state, { payload }) => ({
      ...state,
      columns: state.columns.map((column) => (column.id === payload.column.id ? payload.column : column)),
    }))
    .addCase(resetReceiversFilters, (state) => ({ ...state, filters: initialState.filters }))
    .addCase(sortReceivers, (state, { payload }) => ({ ...state, sort: payload.sort }));
});
