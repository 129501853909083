import { createRequestActions, makeHttpPayload } from '../../effects';
import { Id } from '../adapter';
import { FieldTranslation } from './reducer';

export type FieldTranslationData = Partial<Exclude<FieldTranslation, 'id'>>;

export const {
  request: loadFieldTranslationsRequest,
  success: loadFieldTranslationsSuccess,
  failure: loadFieldTranslationsFailure,
} = createRequestActions('LOAD_FIELD_TRANSLATIONS', () => ({
  http: makeHttpPayload('get', 'field-translations'),
}));

export const {
  request: addFieldTranslationRequest,
  success: addFieldTranslationSuccess,
  failure: addFieldTranslationFailure,
} = createRequestActions('ADD_FIELD_TRANSLATION', ({ body }: { body: FieldTranslationData }) => ({
  http: makeHttpPayload('post', 'field-translations', body),
}));

export const {
  request: updateFieldTranslationRequest,
  success: updateFieldTranslationSuccess,
  failure: updateFieldTranslationFailure,
} = createRequestActions('UPDATE_FIELD_TRANSLATION', ({ id, body }: { id: Id; body: FieldTranslationData }) => ({
  http: makeHttpPayload('post', `field-translations/${id}`, body),
  data: { id, body },
}));

export const {
  request: removeFieldTranslationRequest,
  success: removeFieldTranslationSuccess,
  failure: removeFieldTranslationFailure,
} = createRequestActions('REMOVE_FIELD_TRANSLATION', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `field-translations/${id}`, { status: 'deleted' }),
  data: { id },
}));

export const {
  request: restoreFieldTranslationRequest,
  success: restoreFieldTranslationSuccess,
  failure: restoreFieldTranslationFailure,
} = createRequestActions('RESTORE_FIELD_TRANSLATION', ({ id, body }: { id: Id; body: FieldTranslationData }) => ({
  http: makeHttpPayload('post', `field-translations/${id}`, body),
  data: { id, body },
}));

export const {
  request: recoverFieldTranslationRequest,
  success: recoverFieldTranslationSuccess,
  failure: recoverFieldTranslationFailure,
} = createRequestActions('RECOVER_FIELD_TRANSLATION', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `field-translations/${id}`, { status: 'active' }),
  data: { id },
}));
