/**
 * Polyfills
 */
import 'react-app-polyfill/stable';

/**
 * CSS
 */
import 'flag-icon-css/css/flag-icon.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import './scss/style.scss';
import './scss/core/_dropdown-menu-right.scss';
import './scss/vendors/react-select/react-select.scss';
import './stylesheet.css';

import moment from 'moment';
import React from 'react';
import { render } from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { Route, Router } from 'react-router-dom';

import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { init as initSentry } from '@sentry/browser';

import { App } from './App';
import { ErrorBoundary } from './components/layout';
import { NotificationProvider } from './components/notifications/NotificationProvider';
import { history } from './history';
import { store } from './store';
import { theme } from './theme';

initSentry({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_LEADBOX_ENV,
});

moment.updateLocale('en', { week: { dow: 1, doy: 4 } });
moment.defaultFormat = 'YYYY-MM-DD HH:mm:ss';

if (process.env.REACT_APP_LEADBOX_ENV !== 'production') {
  document.title = '[TEST] Leadbox';
}

render(
  <ErrorBoundary>
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <NotificationProvider>
            <Router history={history}>
              <Route component={App} />
            </Router>
          </NotificationProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ReduxProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);
