import { RootState } from '../reducer';
import { AssociationsPayload } from './actions';
import { createSelector, OutputSelector } from '@reduxjs/toolkit';
import { Entity } from '../../views/settings/entity-editors/dialog-entity-editor/EntityEditor';
import { isEmpty } from 'lodash';
import { SetOptional } from 'type-fest';
import { EntityName } from '../entities';

export const selectAssociations = (state: RootState) => state.associations;

/**
 * Gets all associated entities of a specific type for one given entity.
 * @param entityId ID for the entity want the associated entities for.
 * @param entityName Name of the entity type that you want the associated entities for.
 * @param associatedEntityName Name of the associated entity type.
 * @example selectAssociatedEntity(1, 'customers', 'receivers') will get you all associated receivers for the customer with id 1.
 */
export const selectAssociatedEntity = <T extends Entity>({
  entityId,
  entityName,
  associatedEntityName,
}: SetOptional<AssociationsPayload, 'entityId'>) => {
  return createSelector(
    selectAssociations,
    (state: RootState) => state.entities[associatedEntityName]?.entities,
    (associations, entities) => {
      const {
        ids = null,
        error = null,
        loaded = false,
        loading = true,
      } = entityId ? associations?.[entityName]?.[associatedEntityName]?.[entityId] ?? {} : {};

      if (entities && ids) {
        const filteredEntities = Object.values(entities).filter((entity) => ids.includes(entity.id));
        return { loading, loaded, error, entities: filteredEntities as T[] };
      } else {
        return { loading, loaded, error, entities: [] };
      }
    }
  );
};

export const selectAssociationsBulkLoaded = ({ entityName }: { entityName?: EntityName }) => {
  return createSelector(selectAssociations, (associations) => {
    if (!entityName) {
      return false;
    }

    return associations?.[entityName]?.loaded ?? false;
  });
};

/**
 * Gets all associated entities of one or more types for one given entity.
 * @param entityId ID for the entity want the associated entities for.
 * @param entityName Name of the entity type that you want the associated entities for.
 * @param associatedEntityName Name of the associated entity types.
 * @example selectAssociatedEntities(1, 'customers', ['receivers', 'natures']) will get you all receivers and natures that are associated with the customer with id 1.
 */
export const selectAssociatedEntities = ({
  entityName,
  entityId,
  associatedEntityNames,
}: {
  entityId?: number;
  entityName?: EntityName;
  associatedEntityNames?: EntityName[];
}): OutputSelector<any, any, any> => {
  if (!entityId || !entityName || !associatedEntityNames) {
    return createSelector(selectAssociations, () => {});
  }

  const selectors = associatedEntityNames.map((associatedEntityName) =>
    createSelector(
      selectAssociatedEntity({
        entityName,
        entityId,
        associatedEntityName,
      }),
      (result) => ({ [associatedEntityName]: result.entities })
    )
  );

  return createSelector(selectors, (...res) => res.reduce((acc, entity) => Object.assign(acc, entity)));
};

/**
 * Merges each item in list of entities with specified associated entities.
 * @param entities - List of entities.
 * @param entityName - Type of entity in list of entities.
 * @param associatedEntityNames - Types of associated entities you want to merge with the entities with.
 * @example Let's say that a customer with id 1 is associated with a receiver with id 2 and nature with id 3.
 * If you call this function like this: mergeEntitiesWithAssociatedEntity([{ id: 1, customerName: "A customer" }], 'customers', ['receivers', 'natures'] )
 * it will result in data structured like this: [{ id: 1, customerName: "A customer", receivers: [{ id: 2, receiverName: "A receiver" }], natures: [{ id: 3: natureName: "A nature" }]} }],
 * and a type looking like this: Array<Customer & { receivers: Receiver[], natures: Nature[] } >.
 */
export const mergeEntitiesWithAssociatedEntity = <T extends Entity>(
  entities: T[],
  entityName: EntityName,
  associatedEntityNames: EntityName[] | EntityName
) => {
  const associatedEntityNamesFormatted = Array.isArray(associatedEntityNames)
    ? associatedEntityNames
    : [associatedEntityNames];

  return createSelector(
    selectAssociations,
    (state: RootState) => state.entities,
    (associations, state) => {
      const mergedEntities = entities
        .map((entity) =>
          associatedEntityNamesFormatted
            .map((associatedEntityName) => associations?.[entityName]?.[associatedEntityName]?.[entity.id]?.ids ?? [])
            .map((entityAssociation, index) => {
              const associatedEntityName = associatedEntityNamesFormatted[index];
              const associatedEntities = Object.values(state[associatedEntityName]?.entities ?? {}).filter(
                (associatedEntity: Entity) => entityAssociation.includes(associatedEntity.id)
              );
              return { ...entity, [associatedEntityName]: associatedEntities };
            })
        )
        .flat();

      // Ensures that all required associations have been loaded in.
      const associationsLoaded = !isEmpty(entities)
        ? associatedEntityNamesFormatted.every((associatedEntityName) => {
            const associationsForEntity = associations?.[entityName]?.[associatedEntityName];
            return !isEmpty(associationsForEntity);
          })
        : true;

      return { entities: mergedEntities, associationsLoaded };
    }
  );
};
