import { createSelector } from '@reduxjs/toolkit';
import { intersection, orderBy } from 'lodash';
import { IncomingLead, selectAll } from '../../entities/incomingLeads';
import { selectFields } from '../../enums/fields';
import { RootState } from '../../reducer';
import { LeadsFilters, State } from './reducer';

export const selectIncomingLeadsTable = (state: RootState) => state.ui.incomingLeadsTable;
export const selectSelectedIncomingLeads = createSelector(selectIncomingLeadsTable, ({ selected_leads }: State) => {
  return selected_leads;
});
export const selectHasFilters = createSelector(
  selectIncomingLeadsTable,
  ({ filters }: State) => !Object.values(filters).every((filter: any) => !filter.length)
);

export const selectIncomingLeadsFromTable = createSelector(
  selectIncomingLeadsTable,
  selectAll,
  selectFields,
  ({ sort, filters, filteredIds }: State, incomingLeads) => {
    const filteredIncomingLeads = filteredIds
      ? incomingLeads.filter(({ id }) => filteredIds.includes(id))
      : incomingLeads;

    const sortColumn =
      incomingLeads.filter((lead) => {
        return sortBy(sort.column) in lead.lead_data;
      }).length > 0
        ? `lead_data[${sortBy(sort.column)}]`
        : incomingLeads.filter((lead) => {
            return sortBy(sort.column) in lead;
          }).length > 0
        ? sortBy(sort.column)
        : '';
    //check to see if sorting column exists in incomingLead or if if is a key in lead_data

    return orderBy(filter(filteredIncomingLeads, filters), sortColumn, [sort.direction]);
  }
);

export const sortBy = (column: string): any => {
  switch (column) {
    case 'natures':
      return 'nature_id';
    case 'countries':
      return 'country_code';
    default:
      return column;
  }
};

const filter = (incomingLeads: IncomingLead[], filters: LeadsFilters): IncomingLead[] => {
  return incomingLeads.filter((incomingLead) => {
    if (
      filters &&
      filters.natures &&
      filters.natures.length &&
      !filters.natures.filter((nature) => nature.nature_id === incomingLead.nature_id)
    ) {
      return false;
    } else if (
      filters &&
      filters.countries &&
      filters.countries.length &&
      !intersection([incomingLead.country_code], filters.countries).length
    ) {
      return false;
    }
    return true;
  });
};
