import { Grid, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';

export interface InformationProps {
  title: string;
  description: ReactNode | string;
}

export function Information(props: InformationProps) {
  const { title, description } = props;
  return (
    <Grid container alignItems="center" direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={8}>
          {typeof description === 'string' ? <Typography>{description}</Typography> : description}
        </Grid>
      </Grid>
    </Grid>
  );
}
