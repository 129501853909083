import { PayloadAction } from '@reduxjs/toolkit';

import { put, select, takeEvery } from 'redux-saga/effects';

import { FailurePayload, SuccessPayload } from '../../effects';
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from '../../ui/notifications/actions';

import {
  addFieldAccessFailure,
  addFieldAccessSuccess,
  loadFieldAccessFailure,
  recoverFieldAccessFailure,
  recoverFieldAccessRequest,
  recoverFieldAccessSuccess,
  removeFieldAccessFailure,
  removeFieldAccessRequest,
  removeFieldAccessSuccess,
  restoreFieldAccessFailure,
  restoreFieldAccessRequest,
  restoreFieldAccessSuccess,
  updateFieldAccessFailure,
  updateFieldAccessSuccess,
} from './actions';
import { selectHistory } from './selectors';

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load', label: 'Retry', action: request }));
}

export function* addSuccess({ payload: { response } }: PayloadAction<SuccessPayload>) {
  const undoAction = removeFieldAccessRequest({ id: response.data.id });
  yield put(showSuccessNotification({ message: 'Field access added', label: 'Undo', action: undoAction }));
}

export function* addFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to add', label: 'Retry', action: request }));
}

export function* updateSuccess({ payload: { data } }: PayloadAction<SuccessPayload>): Generator {
  const entity = yield select(selectHistory(data.id));
  const undoAction = restoreFieldAccessRequest({ id: data.id, body: entity });
  yield put(showSuccessNotification({ message: 'Field access updated', label: 'Undo', action: undoAction }));
}

export function* updateFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to update', label: 'Retry', action: request }));
}

export function* removeSuccess({ payload: { data } }: PayloadAction<SuccessPayload>) {
  const undoAction = recoverFieldAccessRequest({ id: data.id });
  yield put(showWarningNotification({ message: 'Field access removed', label: 'Undo', action: undoAction }));
}

export function* removeFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to remove', label: 'Retry', action: request }));
}

export function* restoreSuccess() {
  yield put(showSuccessNotification({ message: 'Field access restored' }));
}

export function* restoreFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to restore', label: 'Retry', action: request }));
}

export function* recoverSuccess() {
  yield put(showSuccessNotification({ message: 'Field access recovered' }));
}

export function* recoverFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to recover', label: 'Retry', action: request }));
}

export function* effects() {
  yield takeEvery(loadFieldAccessFailure, loadFailure);
  yield takeEvery(addFieldAccessSuccess, addSuccess);
  yield takeEvery(addFieldAccessFailure, addFailure);
  yield takeEvery(updateFieldAccessSuccess, updateSuccess);
  yield takeEvery(updateFieldAccessFailure, updateFailure);
  yield takeEvery(removeFieldAccessSuccess, removeSuccess);
  yield takeEvery(removeFieldAccessFailure, removeFailure);
  yield takeEvery(restoreFieldAccessSuccess, restoreSuccess);
  yield takeEvery(restoreFieldAccessFailure, restoreFailure);
  yield takeEvery(recoverFieldAccessSuccess, recoverSuccess);
  yield takeEvery(recoverFieldAccessFailure, recoverFailure);
}
