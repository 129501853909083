import { createReducer } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import { RuleCondition } from '../rules';
import {
  addSpamFilterSuccess,
  loadSpamFiltersFailure,
  loadSpamFiltersRequest,
  loadSpamFiltersSuccess,
  recoverSpamFilterSuccess,
  removeSpamFilterSuccess,
  restoreSpamFilterSuccess,
  updateSpamFilterSuccess,
} from './actions';

export type Status = 'active' | 'disabled' | 'deleted';

export interface SpamFilter {
  id: number;
  status: Status;
  name: string;
  field: string;
  condition: RuleCondition;
  language: string | null;
}

export const adapter: EntityAdapter<SpamFilter> = createEntityAdapter<SpamFilter>({
  selectId: (entity) => entity.id,
  sort: (entities) => orderBy(entities, (entity) => entity.field),
});

export const reducer = createReducer<EntityState<SpamFilter>>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadSpamFiltersRequest, (state) => adapter.setLoading(state))
    .addCase(loadSpamFiltersSuccess, (state, { payload }) => adapter.addMany(payload.response.items, state))
    .addCase(loadSpamFiltersFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(addSpamFilterSuccess, (state, { payload }) => adapter.add(payload.response.data, state))
    .addCase(updateSpamFilterSuccess, (state, { payload }) => adapter.update(payload.data.id, payload.data.body, state))
    .addCase(restoreSpamFilterSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, payload.data.body, state)
    )
    .addCase(recoverSpamFilterSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: 'active' }, state)
    )
    .addCase(removeSpamFilterSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: 'deleted' }, state)
    );
});
