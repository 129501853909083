import { createReducer } from '@reduxjs/toolkit';
import moment from 'moment';
import { Column, Sort } from '../../../components/table';
import { LeadsRule } from '../../../views/Leads/LeadUtils/components/LeadsHelpers';
import { Nature } from '../../entities/natures';
import { baseFieldIds } from '../incoming-leads-table';
import {
  resetOutgoingLeadsFilters,
  setOutgoingLeadsColumns,
  setOutgoingLeadsFilters,
  setOutgoingLeadsSearch,
  setSelectedOutgoingLeads,
  sortOutgoingLeads,
  toggleOutgoingLeadsColumn,
} from './actions';

export interface OutgoingLeadsFilters {
  natures: Nature[];
  countries: string[];
  statuses: string[];
  field_ids: LeadsRule[];
  before_date: string;
  after_date: string;
  type?: 'all' | 'failed';
  limit: number;
  offset: number;
  page: number;
  receiver_id?: number;
}

export interface State {
  search: string;
  selected_leads: number[];
  filters: OutgoingLeadsFilters;
  filteredIds: number[] | null;
  columns: Column[];
  sort: Sort;
}

export const initialState: State = {
  search: '',
  selected_leads: [],
  filters: {
    type: 'all',
    natures: [],
    countries: [],
    statuses: [],
    field_ids: baseFieldIds,
    after_date: moment
      .utc()
      .startOf('day')
      .subtract(1, 'months')
      .locale('YYYY-MM-DD HH:mm:ss')
      .format('YYYY-MM-DD HH:mm:ss'),
    before_date: moment.utc().endOf('day').locale('YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
    limit: 25,
    offset: 0,
    page: 0,
  },
  filteredIds: null,
  columns: [
    { id: 'id', label: 'Id', visible: true },
    { id: 'connection_name', label: 'Connection name', visible: true },
    { id: 'date', label: 'Date', visible: true },
    { id: 'customer_name', label: 'Customer name', visible: true },
    { id: 'status', label: 'Status', visible: true },
  ],
  sort: {
    column: 'id',
    direction: 'desc',
  },
};

export const outgoingLeadsTableReducer = createReducer<State>(initialState, (builder) => {
  builder
    .addCase(setOutgoingLeadsSearch, (state, { payload }) => ({ ...state, search: payload.search }))
    .addCase(setSelectedOutgoingLeads, (state, { payload }) => ({ ...state, selected_leads: payload.selected_leads }))
    .addCase(setOutgoingLeadsFilters, (state, { payload }) => ({
      ...state,
      filters: {
        ...payload.filters,
        after_date: moment(payload.filters.after_date).format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment(payload.filters.before_date).format('YYYY-MM-DD HH:mm:ss'),
      },
      filteredIds: payload.filters.field_ids.length ? state.filteredIds : null,
    }))
    .addCase(setOutgoingLeadsColumns, (state, { payload }) => ({ ...state, columns: payload.columns }))
    .addCase(resetOutgoingLeadsFilters, (state) => ({ ...state, filters: initialState.filters, filteredIds: null }))
    .addCase(toggleOutgoingLeadsColumn, (state, { payload }) => ({
      ...state,
      columns: state.columns.map((column) => (column.id === payload.column.id ? payload.column : column)),
    }))
    .addCase(sortOutgoingLeads, (state, { payload }) => ({ ...state, sort: payload.sort }));
});
