import { createRequestActions, get, post } from '../../effects';
import { Id } from '../adapter';

export const {
  request: loadIncomingLeadRequest,
  success: loadIncomingLeadSuccess,
  failure: loadIncomingLeadFailure,
} = createRequestActions('LOAD_INCOMING_LEAD', ({ incomingLeadId }: { incomingLeadId: number }) => {
  return {
    http: get('leads/incoming/all', {
      incoming_id: incomingLeadId,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }),
  };
});

export const {
  request: updateIncomingLeadRequest,
  success: updateIncomingLeadSuccess,
  failure: updateIncomingLeadFailure,
} = createRequestActions('UPDATE_INCOMING_LEAD', ({ id, flag }: { id: Id; flag: string | null }) => {
  return {
    http: post('leads/incoming/', {
      id,
      flag,
    }),
  };
});

export const {
  request: deletePersonalDataOnIncomingLeadRequest,
  success: deletePersonalDataOnIncomingLeadSuccess,
  failure: deletePersonalDataOnIncomingLeadFailure,
} = createRequestActions('DELETE_PERSONAL_DATA_ON_INCOMING_LEAD', ({ incoming_id }: { incoming_id: Id }) => {
  return {
    http: post('personalData/delete', {
      incoming_id,
    }),
  };
});
