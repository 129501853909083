import { put, takeEvery } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';

import { FailurePayload } from '../../effects';
import { showErrorNotification } from '../../ui/notifications/actions';
import { loadFieldsFailure } from './actions';

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load', label: 'Retry', action: request }));
}

export function* effects() {
  yield takeEvery(loadFieldsFailure, loadFailure);
}
