import { createReducer } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import {
  addDistributionGroupSuccess,
  loadDistributionGroupsFailure,
  loadDistributionGroupsRequest,
  loadDistributionGroupsSuccess,
  recoverDistributionGroupSuccess,
  removeDistributionGroupSuccess,
  restoreDistributionGroupSuccess,
  updateDistributionGroupSuccess,
} from './actions';
import { loadAssociatedEntitiesSuccess } from '../../associations';

export type Status = 'active' | 'disabled' | 'deleted';

export type DistributionGroup = ReceiverGroup | CustomerGroup;

export interface ReceiverGroup {
  id: number;
  customer_id: number;
  status: Status;
  name: string;
  receivers: number[];
}

export interface CustomerGroup {
  id: number;
  settings_id: number;
  status: Status;
  name: string;
  customers: number[];
}

export const adapter: EntityAdapter<DistributionGroup> = createEntityAdapter<DistributionGroup>({
  selectId: (entity) => entity.id,
  sort: (entities) => orderBy(entities, (entity) => entity.name),
});

export const reducer = createReducer<EntityState<DistributionGroup>>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadDistributionGroupsRequest, (state) => adapter.setLoading(state))
    .addCase(loadDistributionGroupsSuccess, (state, { payload }) => adapter.addMany(payload.response.items, state))
    .addCase(loadDistributionGroupsFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(addDistributionGroupSuccess, (state, { payload }) => adapter.add(payload.response.data, state))
    .addCase(updateDistributionGroupSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, payload.data.body, state)
    )
    .addCase(restoreDistributionGroupSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, payload.data.body, state)
    )
    .addCase(recoverDistributionGroupSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: 'active' }, state)
    )
    .addCase(removeDistributionGroupSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: 'deleted' }, state)
    )
    .addCase(loadAssociatedEntitiesSuccess, (state, { payload }) =>
      payload.meta.associatedEntityName === 'distributionGroups' ? adapter.addMany(payload.response.items, state, false) : state
    );
});
