import { createRequestActions, get, makeHttpPayload } from '../../effects';
import { Id } from '../adapter';
import { Receiver } from './types';

export type ReceiverData = Partial<Exclude<Receiver, 'id'>>;

export const {
  request: loadReceiversRequest,
  success: loadReceiversSuccess,
  failure: loadReceiversFailure,
} = createRequestActions('LOAD_RECEIVERS', () => ({
  http: makeHttpPayload('get', 'receivers'),
}));

export const downloadReceivers = createRequestActions(
  'DOWNLOAD_RECEIVERS',
  ({ format }: { format: 'xlsx' | 'csv' | 'json' }) => ({
    http: get('receivers', { format, medium: 'file' }),
  })
);

export const {
  request: loadReceiverRequest,
  success: loadReceiverSuccess,
  failure: loadReceiverFailure,
} = createRequestActions('LOAD_RECEIVER', (id: number) => ({
  http: makeHttpPayload('get', 'receivers', undefined, { receiver_id: id }),
}));

export const {
  request: loadAssociatedReceiversRequest,
  success: loadAssociatedReceiversSuccess,
  failure: loadAssociatedReceiversFailure,
} = createRequestActions('LOAD_ASSOCIATED_RECEIVERS', ({ id, entity }: { id: number; entity: string }) => ({
  http: makeHttpPayload('get', `${entity}/${id}/receivers`),
}));

export const {
  request: createReceiverRequest,
  success: createReceiverSuccess,
  failure: createReceiverFailure,
} = createRequestActions(
  'CREATE_RECEIVER',
  ({
    body,
    meta,
  }: {
    body: ReceiverData;
    meta: {
      defaultConnectionId: number | null;
      redirectAfterCreation: boolean;
      copyRules?: boolean;
      copyRulesFrom?: boolean;
    };
  }) => ({
    http: makeHttpPayload('post', 'receivers', body),
    meta,
  })
);

export const {
  request: updateReceiverRequest,
  success: updateReceiverSuccess,
  failure: updateReceiverFailure,
} = createRequestActions('UPDATE_RECEIVER', ({ id, body }: { id: Id; body: ReceiverData }) => ({
  http: makeHttpPayload('post', `receivers/${id}`, body),
  data: { id, body },
}));

export const {
  request: restoreReceiverRequest,
  success: restoreReceiverSuccess,
  failure: restoreReceiverFailure,
} = createRequestActions('RESTORE_RECEIVER', ({ id, body }: { id: Id; body: ReceiverData }) => ({
  http: makeHttpPayload('post', `receivers/${id}`, body),
  data: { id, body },
}));

export const {
  request: recoverReceiverRequest,
  success: recoverReceiverSuccess,
  failure: recoverReceiverFailure,
} = createRequestActions('RECOVER_RECEIVER', ({ id, body }: { id: Id; body: ReceiverData }) => ({
  http: makeHttpPayload('post', `receivers/${id}`, body),
  data: { id },
}));
