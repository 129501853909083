import { PayloadAction } from '@reduxjs/toolkit';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { history } from '../../../history';
import { FailurePayload, SuccessPayload } from '../../effects';
import { loadFieldsRequest } from '../../enums/fields';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from '../../ui/notifications';
import { loadAccountManagersRequest } from '../account-managers';
import { loadCountriesRequest } from '../countries';
import { loadNaturesRequest } from '../natures';
import {
  createCustomerFailure,
  createCustomerSuccess,
  loadCustomerRequest,
  loadCustomersFailure,
  loadCustomersRequest,
  recoverCustomerFailure,
  recoverCustomerRequest,
  recoverCustomerSuccess,
  removeCustomerFailure,
  removeCustomerRequest,
  removeCustomerSuccess,
  restoreCustomerFailure,
  restoreCustomerRequest,
  restoreCustomerSuccess,
  updateCustomerFailure,
  updateCustomerSuccess,
} from './actions';
import { selectHistory } from './selectors';
import { loadAssociationsBulkRequest } from '../../associations';
import { SagaIterator } from 'redux-saga';
import { isAdmin, isSales, selectAuth } from '../../auth';
import { copyCustomerRulesRequest } from '../rules';

export function* loadRequest() {
  const auth = selectAuth(yield select());
  if (isAdmin(auth.user) || isSales(auth.user)) {
    yield all([
      put(loadNaturesRequest()),
      put(loadCountriesRequest()),
      put(loadAccountManagersRequest()),
      put(loadFieldsRequest()),
      put(loadAssociationsBulkRequest({ entityName: 'customers' })),
    ]);
  }
}

export function* loadSingleRequest() {
  yield all([
    put(loadNaturesRequest()),
    put(loadCountriesRequest()),
    put(loadAccountManagersRequest()),
    put(loadFieldsRequest()),
  ]);
}

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load', label: 'Retry', action: request }));
}

export function* addSuccess({ payload: { response, meta } }: PayloadAction<SuccessPayload>) {
  const undoAction = removeCustomerRequest({ id: response.data.id });
  yield put(showSuccessNotification({ message: 'Customer added', label: 'Undo', action: undoAction }));

  if (meta?.copyRules) {
    yield put(
      copyCustomerRulesRequest({
        body: { copyFrom: meta.copyFrom, copyTo: response.data.id },
        meta: { copyTo: response.data.id },
      })
    );
  }

  if (!meta?.copyRules) {
    history.push(`/customers/${response.data.id}`);
  }
}

export function* addFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to add', label: 'Retry', action: request }));
}

export function* updateSuccess({ payload: { data } }: PayloadAction<SuccessPayload>): SagaIterator<void> {
  const customer = yield select(selectHistory(data.id));
  const undoAction = restoreCustomerRequest({
    id: data.id,
    body: customer,
  });
  yield put(showSuccessNotification({ message: 'Customer updated', label: 'Undo', action: undoAction }));
}

export function* updateFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to update', label: 'Retry', action: request }));
}

export function* removeSuccess({ payload: { data } }: PayloadAction<SuccessPayload>) {
  const undoAction = recoverCustomerRequest({ id: data.id });
  yield put(showWarningNotification({ message: 'Customer removed', label: 'Undo', action: undoAction }));
}

export function* removeFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to remove', label: 'Retry', action: request }));
}

export function* restoreSuccess() {
  yield put(showSuccessNotification({ message: 'Customer restored' }));
}

export function* restoreFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to restore', label: 'Retry', action: request }));
}

export function* recoverSuccess() {
  yield put(showSuccessNotification({ message: 'Customer recovered' }));
}

export function* recoverFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to recover', label: 'Retry', action: request }));
}

export function* effects() {
  yield takeEvery(loadCustomersRequest, loadRequest);
  yield takeEvery(loadCustomerRequest, loadSingleRequest);
  yield takeEvery(loadCustomersFailure, loadFailure);
  yield takeEvery(createCustomerSuccess, addSuccess);
  yield takeEvery(createCustomerFailure, addFailure);
  yield takeEvery(updateCustomerSuccess, updateSuccess);
  yield takeEvery(updateCustomerFailure, updateFailure);
  yield takeEvery(removeCustomerSuccess, removeSuccess);
  yield takeEvery(removeCustomerFailure, removeFailure);
  yield takeEvery(restoreCustomerSuccess, restoreSuccess);
  yield takeEvery(restoreCustomerFailure, restoreFailure);
  yield takeEvery(recoverCustomerSuccess, recoverSuccess);
  yield takeEvery(recoverCustomerFailure, recoverFailure);
}
