import {
  Box,
  Dialog as MaterialDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import React, { ReactNode } from 'react';
import { IconButton } from '../ui';

export interface DialogProps {
  children: ReactNode;
  title: string;
  open: boolean;
  onClose: () => any;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  actions?: ReactNode;
}

export function Dialog({ children, title, open, onClose, size, actions }: DialogProps) {
  return (
    <MaterialDialog open={open} onClose={onClose} fullWidth maxWidth={size}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">{title}</Typography>
          <Box mr={-1}>
            <IconButton title="Close" onClick={onClose} Icon={CloseIcon} />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box my={1}>{children}</Box>
      </DialogContent>
      {actions && (
        <DialogActions>
          <Box mx={2} my={1}>
            {actions}
          </Box>
        </DialogActions>
      )}
    </MaterialDialog>
  );
}
