import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps, Tooltip } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import React, { Ref } from 'react';

export interface IconButtonProps extends MuiIconButtonProps {
  title: string;
  Icon?: typeof SvgIcon;
}

export const IconButton = React.forwardRef(
  ({ title, Icon, children, ...iconButtonProps }: IconButtonProps, ref: Ref<HTMLButtonElement>) => {
    const icon = Icon ? <Icon fontSize="inherit" /> : children;
    return (
      <Tooltip title={title}>
        <span>
          <MuiIconButton ref={ref} {...iconButtonProps}>
            {icon}
          </MuiIconButton>
        </span>
      </Tooltip>
    );
  }
);
