import {
  INVOICE_CHANGE_ROW,
  INVOICE_CLEAR_RESULT,
  INVOICE_DELETE_ROWS,
  INVOICE_FINISHED,
  INVOICE_LOADING,
} from './invoiceAction';

export function invoice(
  state = {
    isLoading: false,
    hasFailed: false,
    result: [],
  },
  action
) {
  switch (action.type) {
    case INVOICE_LOADING:
      return Object.assign({}, state, {
        isLoading: true,
        hasFailed: false,
      });
    case INVOICE_FINISHED:
      return Object.assign({}, state, {
        isLoading: false,
        hasFailed: !action.success,
        result: action.result,
      });
    case INVOICE_DELETE_ROWS:
      return {
        ...state,
        result: state.result.filter(({ key }) => !action.rowsToDelete.includes(key)),
      };
    case INVOICE_CHANGE_ROW:
      return {
        ...state,
        result: state.result.reduce((result, row) => {
          const { rowToChange } = action;
          if (row.key === rowToChange.key) return [...result, rowToChange];
          else return [...result, row];
        }, []),
      };
    case INVOICE_CLEAR_RESULT:
      return {
        ...state,
        result: [],
      };
    default:
      return state;
  }
}
