import { createAction } from '@reduxjs/toolkit';

import { Notification } from './reducer';

export type NotificationPayload = Omit<Notification, 'open' | 'key' | 'variant'>;

export const showInfoNotification = createAction<NotificationPayload>('SHOW_INFO_NOTIFICATION');
export const showSuccessNotification = createAction<NotificationPayload>('SHOW_SUCCESS_NOTIFICATION');
export const showWarningNotification = createAction<NotificationPayload>('SHOW_WARNING_NOTIFICATION');
export const showErrorNotification = createAction<NotificationPayload>('SHOW_ERROR_NOTIFICATION');

export const dismissNotification = createAction<{ key: number }>('DISMISS_NOTIFICATION');
export const dismissAllNotifications = createAction('DISMISS_ALL_NOTIFICATION');
export const notificationDismissed = createAction<{ key: number }>('NOTIFICATION_DISMISSED');
