import { $CombinedState, combineReducers } from 'redux';
import { reducer as accountManagers } from './account-managers';
import { reducer as countries } from './countries';
import { reducer as customers } from './customers';
import { reducer as crm } from './crm';
import { reducer as distributionGroups } from './distribution-groups';
import { reducer as distributionSettings } from './distribution-settings';
import { reducer as fieldAccesses } from './field-access';
import { reducer as fieldTranslations } from './field-translations';
import { reducer as natures } from './natures';
import { reducer as receivers } from './receivers';
import { reducer as ruleMappings } from './rule-mappings';
import { reducer as rules } from './rules';
import { reducer as spamFilters } from './spam-filters';
import { reducer as connections } from './connections';
import { reducer as outgoingLeads } from './outgoingLeads';
import { reducer as outgoingLeadsIncomingId } from './outgoingLeadsIncomingId';
import { reducer as customerLeads } from './customerLeads';
import { reducer as customerLead } from './customerLead';
import { reducer as customerLeadsIncomingId } from './customerLeadsIncomingId';
import { reducer as incomingLeads } from './incomingLeads';
import { reducer as incomingLead } from './incomingLead';
import { reducer as ianaCountries } from './iana-countries';
import { reducer as receiverLeads } from './receiverLeads';
import { reducer as receiverLead } from './receiverLead';

export const entitiesReducer = combineReducers({
  accountManagers,
  crm,
  countries,
  ianaCountries,
  customers,
  natures,
  receivers,
  spamFilters,
  fieldAccesses,
  fieldTranslations,
  ruleMappings,
  distributionSettings,
  distributionGroups,
  rules,
  connections,
  outgoingLeads,
  outgoingLeadsIncomingId,
  customerLead,
  customerLeads,
  customerLeadsIncomingId,
  incomingLeads,
  incomingLead,
  receiverLeads,
  receiverLead,
});

export type EntitiesState = ReturnType<typeof entitiesReducer>;
export type EntityName = Exclude<keyof EntitiesState, typeof $CombinedState>;
