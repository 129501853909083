import { call, fork, getContext, put, take, takeEvery } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';

import { ApiClient } from '../../api-client';
import { AuthClient, User } from '../../auth-client';
import { logIn, logInFailure, logInSuccess, LogInSuccessPayload, logOut } from './actions';

export function* listenForAuth() {
  const auth: AuthClient = yield getContext('auth');

  while (true) {
    yield take(logIn);
    yield fork(attemptLogIn, auth);
    yield take(logOut);
    auth.logOut();

    // Temporary to keep old authentication logic working
    localStorage.clear();
  }
}

export function* attemptLogIn(auth: AuthClient): Generator<any, any, any> {
  if (auth.isRedirected()) {
    try {
      // Include ev. redirect paths
      const fetchedRedirectPath = localStorage.getItem('redirectPath');
      const decodedRedirectPath =
        fetchedRedirectPath !== null ? new Buffer(fetchedRedirectPath, 'base64').toString() : null;
      yield auth.handleRedirect(decodedRedirectPath);
    } catch (error: any) {
      return yield put(logInFailure({ error: error.message }));
    }
  }

  if (yield auth.isAuthenticated()) {
    const token = yield auth.getToken();

    // Temporary to keep old authentication logic working
    localStorage.setItem('nettbureau-login-token', token);

    try {
      const user = yield call(getUser, auth);
      yield put(logInSuccess({ token, user }));
    } catch (error: any) {
      yield put(logInFailure({ error: error.message }));
    }
  } else {
    // If there was an attempt to access authentication protected link (while not being on root), store path for pending future auth success redirect
    const redirectPath = window.location.pathname !== '/' ? window.location.pathname : '';

    const encodedRedirectPath = new Buffer(redirectPath).toString('base64');
    localStorage.setItem('redirectPath', encodedRedirectPath);
    auth.redirectToLogin();
  }
}

export function* getUser(auth: AuthClient) {
  const api: ApiClient = yield getContext('api');
  const user: Promise<User> = yield auth.getUser();
  const { receiver_id, security_groups } = yield api.get('login');

  return { ...user, receiverId: receiver_id, securityGroups: security_groups };
}

export function* configureUserInSentry({ payload: { user } }: PayloadAction<LogInSuccessPayload>) {
  yield call(Sentry.configureScope, (scope: Sentry.Scope) => {
    scope.setUser({ email: user.email });
  });
}

export function* effects() {
  yield fork(listenForAuth);
  yield takeEvery(logInSuccess, configureUserInSentry);
  yield put(logIn());
}
