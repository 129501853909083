import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import { FailurePayload, SuccessPayload } from '../../effects';
import { showErrorNotification, showSuccessNotification } from '../../ui/notifications';
import {
  countOutgoingLeadsFailure,
  loadOutgoingLeadsFailure,
  retryOutgoingLeadsFailure,
  retryOutgoingLeadsSuccess,
  retryOutgoingLeadsForReceiverFailure,
  retryOutgoingLeadsForReceiverSuccess,
  searchOutgoingLeadsFailure,
} from './actions';

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load', label: 'Retry', action: request }));
}

export function* countFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to count outgoing leads', label: 'Retry', action: request }));
}

export function* retryFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to retry leads', label: 'Retry', action: request }));
}

export function* retrySuccess({ payload: { response } }: PayloadAction<SuccessPayload>) {
  const failed: number[] = response.data.failed;
  const success: number[] = response.data.success;

  if (failed.length === 0 && success.length === 0) {
    yield put(
      showSuccessNotification({
        message: `Failed to retry any leads`,
      })
    );
  } else if (failed.length === 0) {
    yield put(
      showSuccessNotification({
        message: `Successfully sent leads: ${success.length}`,
      })
    );
  } else {
    yield put(
      showErrorNotification({
        message: `Failed to send (id): ${failed.length}, successfully sent leads: ${success.length}`,
      })
    );
  }
}

export function* effects() {
  yield takeEvery(loadOutgoingLeadsFailure, loadFailure);
  yield takeEvery(countOutgoingLeadsFailure, countFailure);
  yield takeEvery(searchOutgoingLeadsFailure, loadFailure);
  yield takeEvery(retryOutgoingLeadsFailure, retryFailure);
  yield takeEvery(retryOutgoingLeadsSuccess, retrySuccess);
  yield takeEvery(retryOutgoingLeadsForReceiverFailure, retryFailure);
  yield takeEvery(retryOutgoingLeadsForReceiverSuccess, retrySuccess);
}
