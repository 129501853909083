import { createRequestActions, get, makeHttpPayload } from '../../effects';
import { ConnectionData, Status } from './types';
import { Id } from '../adapter';

export const {
  request: loadConnectionsRequest,
  success: loadConnectionsSuccess,
  failure: loadConnectionsFailure,
} = createRequestActions('LOAD_CONNECTIONS', () => ({
  http: makeHttpPayload('get', 'connections'),
}));

export const downloadConnections = createRequestActions(
  'DOWNLOAD_CONNECTIONS',
  ({ format }: { format: 'xlsx' | 'csv' | 'json' }) => ({
    http: get('connections', { format, medium: 'file' }),
  })
);

export const {
  request: createConnectionRequest,
  success: createConnectionSuccess,
  failure: createConnectionFailure,
} = createRequestActions('CREATE_CONNECTION', ({ body }: { body: ConnectionData }) => ({
  http: makeHttpPayload('post', 'connections', body),
}));

export const {
  request: updateConnectionRequest,
  success: updateConnectionSuccess,
  failure: updateConnectionsFailure
} = createRequestActions('UPDATE_CONNECTION', ({ id, body }: { id: Id, body: ConnectionData}) => ({
  http: makeHttpPayload('post', `connections/${id}`, body),
  data: { id, body }
}));

export const {
  request: removeConnectionRequest,
  success: removeConnectionSuccess,
  failure: removeConnectionFailure
} = createRequestActions('REMOVE_CONNECTION', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post',`connections/${id}`, { id, status: Status.DELETED }),
  data: { id }
}));

export const {
  request: recoverConnectionRequest,
  success: recoverConnectionSuccess,
  failure: recoverConnectionFailure,
} = createRequestActions('RECOVER_CONNECTION', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `connections/${id}`, {id, status: Status.ACTIVE }),
  data: { id },
}));
