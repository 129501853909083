import { Action } from 'redux';

import { Color } from '@material-ui/lab';
import { createReducer } from '@reduxjs/toolkit';

import {
  dismissAllNotifications,
  dismissNotification,
  notificationDismissed,
  NotificationPayload,
  showErrorNotification,
  showInfoNotification,
  showSuccessNotification,
  showWarningNotification,
} from './actions';

export interface Notification {
  open: boolean;
  key: number;
  variant: Color;
  message: string;
  label?: string;
  action?: Action;
}

export type State = Notification[];

export const notificationsReducer = createReducer<State>([], builder => {
  builder
    .addCase(showInfoNotification, (state, { payload }) => add('info', payload, state))
    .addCase(showSuccessNotification, (state, { payload }) => add('success', payload, state))
    .addCase(showWarningNotification, (state, { payload }) => add('warning', payload, state))
    .addCase(showErrorNotification, (state, { payload }) => add('error', payload, state))
    .addCase(dismissNotification, (state, { payload }) => dismiss(payload.key, state))
    .addCase(dismissAllNotifications, state => dismissAll(state))
    .addCase(notificationDismissed, (state, { payload }) => remove(payload.key, state));
});

const add = (variant: Color, notification: NotificationPayload, state: State) => {
  return [...state, { key: new Date().getTime(), open: true, variant, ...notification }];
};

const dismiss = (key: number, state: State) => {
  return state.map(notification => (notification.key === key ? { ...notification, open: false } : notification));
};

const dismissAll = (state: State) => state.map(notification => ({ ...notification, open: false }));
const remove = (key: number, state: State) => state.filter(notification => notification.key !== key);
