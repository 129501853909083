import { Chip as MuiChip, makeStyles } from '@material-ui/core';
import { green, orange, red } from '@material-ui/core/colors';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

export interface ChipProps {
  label: string | ReactNode;
  size?: 'small' | 'medium';
  color?: 'success' | 'warning' | 'error';
}

const useStyles = makeStyles((theme) => ({
  chip: {
    fontWeight: 500,
  },
  success: {
    color: green[600],
    backgroundColor: green[50],
  },
  warning: {
    color: orange[600],
    backgroundColor: orange[50],
  },
  error: {
    color: red[600],
    backgroundColor: red[50],
  },
}));

export function Chip({ label, color, size = 'small' }: ChipProps) {
  const classes = useStyles();
  const className = clsx([classes.chip, color && classes[color]]);
  return <MuiChip size={size} label={label} className={className} />;
}
