import { fetchData, postData } from '../../api';

export const WELCOME_EMAIL_EDIT_FINISHED = 'WELCOME_EMAIL_EDIT_FINISHED';
function welcomeEmailEditFinished(success) {
    return {
        type: WELCOME_EMAIL_EDIT_FINISHED,
        success
    }
}

export const WELCOME_EMAIL_EDIT_REQUEST = 'WELCOME_EMAIL_EDIT_REQUEST';
function welcomeEmailEditRequest(id) {
    return {
        type: WELCOME_EMAIL_EDIT_REQUEST,
        id
    }
}

export function editWelcomeEmail(receiver_id, html) {
  return (dispatch) => {
    dispatch(welcomeEmailEditRequest(receiver_id));
    return postData('email/welcome', { receiver_id, html })
      .then(({ response }) => {
        return response.then((result) => {
          return dispatch(welcomeEmailEditFinished(true));
        });
      })
      .catch((error) => {
        return dispatch(welcomeEmailEditFinished(false));
      });
  };
}


export const WELCOME_EMAIL_GET_FINISHED = 'WELCOME_EMAIL_GET_FINISHED';
function welcomeEmailGetFinished(success, result) {
    return {
        type: WELCOME_EMAIL_GET_FINISHED,
        success,
        result
    }
}

export const WELCOME_EMAIL_GET_REQUEST = 'WELCOME_EMAIL_GET_REQUEST';
function welcomeEmailGetRequest(id) {
    return {
        type: WELCOME_EMAIL_GET_REQUEST,
        id
    }
}

export function getWelcomeEmail(receiver_id) {
    return dispatch => {
        dispatch(welcomeEmailGetRequest(receiver_id));
        return fetchData('email/welcome', { query: { receiver_id } })
            .then(({ ok, response }) => {
                response.then((code) => {
                    dispatch(welcomeEmailGetFinished(ok, code))
                })
            })
    }
}
