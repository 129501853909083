import { isDateRangeAction } from './dateRangeAction';

export type State = {
  after_date: string;
  before_date: string;
};

// TODO: replce hacky data persistance with a more general solution like e.g. redux-persist
export function dateRange(
  state = {
    after_date: sessionStorage.getItem("after_date") || "",
    before_date: sessionStorage.getItem("before_date") || ""
  },
  action: any
) {
  if (isDateRangeAction(action)) {
    sessionStorage.setItem("after_date", action.after_date);
    sessionStorage.setItem("before_date", action.before_date);
    return {
      ...state,
      after_date: action.after_date,
      before_date: action.before_date
    };
  }

  return state;
}
