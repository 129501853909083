import { createReducer } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import {
  addFieldTranslationSuccess,
  loadFieldTranslationsFailure,
  loadFieldTranslationsRequest,
  loadFieldTranslationsSuccess,
  recoverFieldTranslationSuccess,
  removeFieldTranslationSuccess,
  restoreFieldTranslationSuccess,
  updateFieldTranslationSuccess,
} from './actions';

export type Status = 'active' | 'disabled' | 'deleted';

export interface FieldTranslation {
  id: number;
  status: Status;
  field: string;
  translations: Record<string, string>;
}

export const adapter: EntityAdapter<FieldTranslation> = createEntityAdapter<FieldTranslation>({
  selectId: (entity) => entity.id,
  sort: (entities) => orderBy(entities, (entity) => entity.field),
});

export const reducer = createReducer<EntityState<FieldTranslation>>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadFieldTranslationsRequest, (state) => adapter.setLoading(state))
    .addCase(loadFieldTranslationsSuccess, (state, { payload }) => adapter.addMany(payload.response.items, state))
    .addCase(loadFieldTranslationsFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(addFieldTranslationSuccess, (state, { payload }) => adapter.add(payload.response.data, state))
    .addCase(updateFieldTranslationSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, payload.data.body, state)
    )
    .addCase(restoreFieldTranslationSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, payload.data.body, state)
    )
    .addCase(recoverFieldTranslationSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: 'active' }, state)
    )
    .addCase(removeFieldTranslationSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: 'deleted' }, state)
    );
});
