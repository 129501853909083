import moment from 'moment';
import { Column } from '../../../components/table';
import {
  buildFieldQueryStringForLeads,
  nonEmptyFieldIds,
} from '../../../views/Leads/LeadUtils/components/LeadsHelpers';
import { createRequestActions, get } from '../../effects';
import { LeadsFilters } from '../../ui/incoming-leads-table';

export const {
  request: loadReceiverLeadsRequest,
  success: loadReceiverLeadsSuccess,
  failure: loadReceiverLeadsFailure,
} = createRequestActions('LOAD_RECEIVER_LEADS', ({ filters }: { filters: LeadsFilters }) => {
  const fieldIdSearch = nonEmptyFieldIds(filters.field_ids);
  return {
    http: get(`leads/receiver`, {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      limit: filters.limit,
      after_date: moment(filters.after_date).format('YYYY-MM-DD HH:mm:ss'),
      before_date: moment(filters.before_date).format('YYYY-MM-DD HH:mm:ss'),
      offset: fieldIdSearch.length > 0 ? 0 : filters.offset,
      field_ids: fieldIdSearch.length > 0 ? buildFieldQueryStringForLeads(filters.field_ids) : '',
      customer_ids: filters.customers,
    }),
  };
});

export const downloadReceiverLeads = createRequestActions(
  'DOWNLOAD_RECEIVER_LEADS',
  ({ format, filters, columns }: { format: 'xlsx' | 'csv' | 'json'; filters: LeadsFilters; columns: Column[] }) => {
    return {
      http: get(`leads/receiver`, {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        after_date: moment(filters.after_date).format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment(filters.before_date).format('YYYY-MM-DD HH:mm:ss'),
        export_fields: columns
          .filter((column) => column.visible === true)
          .map((column) => {
            return column.label;
          }),
        format,
        limit: 5000,
        offset: 0,
        medium: 'file',
        customer_ids: filters.customers,
      }),
    };
  }
);

export const {
  request: searchReceiverLeadsRequest,
  success: searchReceiverLeadsSuccess,
  failure: searchReceiverLeadsFailure,
} = createRequestActions('SEARCH_RECEIVER_LEADS', ({ search, filters }: { search: string; filters: LeadsFilters }) => {
  return {
    http: get(`leads/receiver`, {
      limit: filters.limit,
      search: search,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      after_date: moment(filters.after_date).format('YYYY-MM-DD HH:mm:ss'),
      before_date: moment(filters.before_date).format('YYYY-MM-DD HH:mm:ss'),
      offset: filters.offset,
      customer: filters.customers,
    }),
  };
});
