import { createReducer } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import {
  createCustomerSuccess,
  loadCustomerFailure,
  loadCustomerRequest,
  loadCustomersFailure,
  loadCustomersRequest,
  loadCustomersSuccess,
  loadCustomerSuccess,
  recoverCustomerSuccess,
  removeCustomerSuccess,
  restoreCustomerSuccess,
  updateCustomerSuccess,
} from './actions';
import { BaseCustomer, CustomerEntity, CustomerState } from './types';
import { loadAssociatedEntitiesSuccess } from '../../associations';

export type Customer = BaseCustomer | CustomerState;

export type State = EntityState<Customer>;

export const adapter: EntityAdapter<Customer, BaseCustomer | CustomerEntity> = createEntityAdapter<
  Customer,
  BaseCustomer | CustomerEntity
>({
  sort: (entities) => orderBy(entities, 'name'),
});

export const reducer = createReducer<State>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadCustomersRequest, (state) => adapter.setLoading(state))
    .addCase(loadCustomersSuccess, (state, { payload }) => adapter.addMany(payload.response.items, state))
    .addCase(loadCustomersFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(loadCustomerRequest, (state) => adapter.setLoading(state))
    .addCase(loadCustomerSuccess, (state, { payload }) => adapter.addMany(payload.response.items, state, false))
    .addCase(loadCustomerFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(createCustomerSuccess, (state, { payload }) => adapter.add(payload.response.data, state))
    .addCase(updateCustomerSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, payload.response.data, state)
    )
    .addCase(restoreCustomerSuccess, (state, { payload }) => adapter.update(payload.data.id, payload.data.body, state))
    .addCase(recoverCustomerSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: 'active' }, state)
    )
    .addCase(removeCustomerSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: 'deleted' }, state)
    )
    .addCase(loadAssociatedEntitiesSuccess, (state, { payload }) =>
      payload.meta.associatedEntityName === 'customers' ? adapter.addMany(payload.response.items, state, false) : state
    );
});
