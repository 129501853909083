import {
  countOutgoingLeadsSuccess,
  loadOutgoingLeadsFailure,
  loadOutgoingLeadsRequest,
  loadOutgoingLeadsSuccess,
  retryOutgoingLeadsFailure,
  retryOutgoingLeadsRequest,
  retryOutgoingLeadsSuccess,
  retryOutgoingLeadsForReceiverRequest,
  retryOutgoingLeadsForReceiverFailure,
  retryOutgoingLeadsForReceiverSuccess,
  searchOutgoingLeadsFailure,
  searchOutgoingLeadsRequest,
  searchOutgoingLeadsSuccess,
} from './actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import { orderBy } from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import { leadData } from '../incomingLeads';

export interface ProxyObject {
  hash?: any;
}

type OutgoingLeadStatus = 'error' | 'pending' | 'success';

export interface OutgoingLeadAttempt {
  id: number;
  timestamp: Date;
  external_id: string;
  outgoing_id: number;
  status: OutgoingLeadStatus;
}

export interface OutgoingLead {
  id: number;
  date: string;
  incoming_id: number;
  customer_id: number | null;
  receiver_id: number | null;
  connection_id: number | null;
  nature_id: number;
  country_code: string;
  status: OutgoingLeadStatus;
  lead_data: leadData;
  flag?: string | null;
  proxy?: ProxyObject | null;
  received_by: string[];
  receiver_name?: string;
  connection_name?: string;
  connection_protocol: string | null;
  customer_name?: string;
  exclusive?: boolean;
  outgoingLeadAttempts: OutgoingLeadAttempt[];
  [key: string]: any;
}

export interface State extends EntityState<OutgoingLead> {}

export const adapter: EntityAdapter<OutgoingLead> = createEntityAdapter<OutgoingLead>({
  sort: (entities) => orderBy(entities, 'id'),
});

export const reducer = createReducer<State>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadOutgoingLeadsRequest, (state) => adapter.setLoading(state))
    .addCase(loadOutgoingLeadsSuccess, (state, { payload }) =>
      adapter.replace(payload.response.items, state, payload.response.total_count)
    )
    .addCase(loadOutgoingLeadsFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(searchOutgoingLeadsRequest, (state) => adapter.setLoading(state))
    .addCase(searchOutgoingLeadsSuccess, (state, { payload }) =>
      adapter.replace(payload.response.items, state, payload.response.total_count)
    )
    .addCase(searchOutgoingLeadsFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(countOutgoingLeadsSuccess, (state, { payload }) =>
      adapter.updateTotal(state, payload.response.total_count)
    )
    .addCase(retryOutgoingLeadsRequest, (state) => adapter.setLoading(state))
    .addCase(retryOutgoingLeadsSuccess, (state) => adapter.setSuccess(state))
    .addCase(retryOutgoingLeadsFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(retryOutgoingLeadsForReceiverRequest, (state) => adapter.setLoading(state))
    .addCase(retryOutgoingLeadsForReceiverSuccess, (state) => adapter.setSuccess(state))
    .addCase(retryOutgoingLeadsForReceiverFailure, (state, { payload }) => adapter.setError(payload.error, state));
});
