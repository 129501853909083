import { createSelector } from '@reduxjs/toolkit';
import { chain, isEqual } from 'lodash';
import { RootState } from '../../reducer';
import { initialState, State } from './reducer';

export const selectInsights = (state: RootState) => state.tools.insights;

export const selectHasFilters = createSelector(
  selectInsights,
  ({ filters }: State) => !isEqual(filters, initialState.filters)
);

export const selectInsightsData = createSelector(selectInsights, ({ insights, sort }: State) => {
  return chain(insights.data).orderBy([sort.column], [sort.direction]).value();
});

export const selectHasInsightsData = createSelector(
  selectInsights,
  ({ insights }) => insights.loaded && insights.data?.length > 0
);
