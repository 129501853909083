import { put, select, takeEvery } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';

import { FailurePayload, SuccessPayload } from '../../effects';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from '../../ui/notifications';
import {
  addCountryFailure,
  addCountrySuccess,
  loadCountriesFailure,
  recoverCountryFailure,
  recoverCountryRequest,
  recoverCountrySuccess,
  removeCountryFailure,
  removeCountryRequest,
  removeCountrySuccess,
  restoreCountryFailure,
  restoreCountryRequest,
  restoreCountrySuccess,
  updateCountryFailure,
  updateCountrySuccess,
} from './actions';
import { selectHistory } from './selectors';

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load', label: 'Retry', action: request }));
}

export function* addSuccess({ payload: { response } }: PayloadAction<SuccessPayload>) {
  const undoAction = removeCountryRequest({ id: response.data.id });
  yield put(showSuccessNotification({ message: 'Country added', label: 'Undo', action: undoAction }));
}

export function* addFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to add', label: 'Retry', action: request }));
}

export function* updateSuccess({ payload: { data } }: PayloadAction<SuccessPayload>): Generator {
  const country = yield select(selectHistory(data.id));
  const undoAction = restoreCountryRequest({ id: data.id, body: country });
  yield put(showSuccessNotification({ message: 'Country updated', label: 'Undo', action: undoAction }));
}

export function* updateFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to update', label: 'Retry', action: request }));
}

export function* removeSuccess({ payload: { data } }: PayloadAction<SuccessPayload>) {
  const undoAction = recoverCountryRequest({ id: data.id });
  yield put(showWarningNotification({ message: 'Country removed', label: 'Undo', action: undoAction }));
}

export function* removeFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to remove', label: 'Retry', action: request }));
}

export function* restoreSuccess() {
  yield put(showSuccessNotification({ message: 'Country restored' }));
}

export function* restoreFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to restore', label: 'Retry', action: request }));
}

export function* recoverSuccess() {
  yield put(showSuccessNotification({ message: 'Country recovered' }));
}

export function* recoverFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to recover', label: 'Retry', action: request }));
}

export function* effects() {
  yield takeEvery(loadCountriesFailure, loadFailure);
  yield takeEvery(addCountrySuccess, addSuccess);
  yield takeEvery(addCountryFailure, addFailure);
  yield takeEvery(updateCountrySuccess, updateSuccess);
  yield takeEvery(updateCountryFailure, updateFailure);
  yield takeEvery(removeCountrySuccess, removeSuccess);
  yield takeEvery(removeCountryFailure, removeFailure);
  yield takeEvery(restoreCountrySuccess, restoreSuccess);
  yield takeEvery(restoreCountryFailure, restoreFailure);
  yield takeEvery(recoverCountrySuccess, recoverSuccess);
  yield takeEvery(recoverCountryFailure, recoverFailure);
}
