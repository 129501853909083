import { put, select, take, takeEvery } from 'redux-saga/effects';
import {
  addAssociatedEntity,
  addAssociationsRequest,
  addEntityAndCreateAssociations,
  AssociationsPayload,
} from './actions';
import { makeNameSingular } from './reducer';
import { makeHttpPayload } from '../effects';
import { kebabCase, map, snakeCase } from 'lodash';
import { selectAssociatedEntity } from './selectors';
import { SagaIterator } from 'redux-saga';

export function* createAssociationsRequest<T extends object>({
  payload: { incompleteEntityData, associationPayload },
  meta,
}: {
  payload: { incompleteEntityData: T; associationPayload: AssociationsPayload };
  meta: any;
}): SagaIterator<void> {
  const formattedEntityName = makeNameSingular(snakeCase(associationPayload.associatedEntityName)).toUpperCase();

  // Create request for the specified entity.
  yield put({
    type: `CREATE_${formattedEntityName}_REQUEST`,
    payload: {
      http: makeHttpPayload('post', `${kebabCase(associationPayload.associatedEntityName)}`, incompleteEntityData),
      meta,
    },
  });

  // Get the result from the create request.
  const { payload } = yield take(`CREATE_${formattedEntityName}_SUCCESS`);

  // Get the already associated entities.
  const { entities } = yield select(selectAssociatedEntity(associationPayload));

  // Create a new association between the newly created entity and the connected entity type.
  yield put(addAssociationsRequest({ ids: [...map(entities, 'id'), payload.response.data.id], ...associationPayload }));
}

export function* createEntityAndMultipleAssociations<T extends object>({
  payload: { incompleteEntityData, entityName, associatedEntities },
  meta,
}: {
  payload: { incompleteEntityData: T; entityName: string; associatedEntities: Record<string, number[]> };
  meta: any;
}): SagaIterator<void> {
  const formattedEntityName = makeNameSingular(snakeCase(entityName)).toUpperCase();

  // Create request for the specified entity.
  yield put({
    type: `CREATE_${formattedEntityName}_REQUEST`,
    payload: {
      http: makeHttpPayload('post', kebabCase(entityName), incompleteEntityData),
      meta,
    },
  });

  // Get the result from the create request.
  const { payload } = yield take(`CREATE_${formattedEntityName}_SUCCESS`);

  // Create a new association between the newly created entity and the connected entity types.
  for (const [name, ids] of Object.entries(associatedEntities)) {
    yield put(
      addAssociationsRequest({ ids, entityName, entityId: payload.response.data.id, associatedEntityName: name })
    );
  }
}

export function* effects() {
  yield takeEvery(addAssociatedEntity, createAssociationsRequest);
  yield takeEvery(addEntityAndCreateAssociations, createEntityAndMultipleAssociations);
}
