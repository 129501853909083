import { RootState } from '../../reducer';
import { EntityState } from '../adapter';
import { adapter } from './reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { loadConnectionsRequest } from './actions';
import { Connection } from './types';
import { createSelector } from '@reduxjs/toolkit';

export const connectionsSelector = (state: RootState): EntityState<Connection> => state.entities.connections;
export const { selectAll, selectHistory, selectById } = adapter.selectors<Connection>(connectionsSelector);

export function useAndFetchConnections() {
  const { loaded, loading, error } = useSelector(connectionsSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!loaded && !loading && !error) {
      dispatch(loadConnectionsRequest());
    }
  }, [dispatch, loaded, loading, error]);

  const entities = useSelector(selectAll)
  return { loaded, loading, error, entities };
}

export function selectConnections() {
  return createSelector(selectAll, connectionsSelector, (connections, { loading, loaded, error }) => ({
    entities: connections,
    loading,
    loaded,
    error,
  }));
}
