import { createSelector } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { EntityState } from '../adapter';
import { loadDistributionSettingsRequest } from './actions';
import { adapter, DistributionSetting, DistributionSettingWithRelations } from './reducer';

export const selectDistributionSettings = (state: RootState): EntityState<DistributionSetting> =>
  state.entities.distributionSettings;
export const { selectAll, selectById, selectHistory } = adapter.selectors<DistributionSettingWithRelations>(
  selectDistributionSettings
);
export const selectExisting = createSelector(selectAll, (entities) =>
  entities.filter((entity) => entity.status !== 'deleted')
);

export function useDistributionSettings() {
  const { loaded, loading, error } = useSelector(selectDistributionSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded && !loading && !error) {
      dispatch(loadDistributionSettingsRequest());
    }
  }, [dispatch, loaded, loading, error]);

  const entities = useSelector(selectExisting);
  return { loaded, loading, error, entities };
}
