import { isPlainObject } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Column } from '../../../../components/table';
import { CustomerState } from '../../../../store/entities/customers/types';
import { IncomingLead } from '../../../../store/entities/incomingLeads';
import { ReceiverLead } from '../../../../store/entities/receiverLeads';
import { Rule } from '../../../../store/ui/customers-table';
import { isEmailHeaderColumn } from '../../../../utils';

export interface LeadsRule {
  field: string;
  field_id_operator: string;
  value_operator: string;
  value: string;
  values: string[];
}

export const incomingLeadsStartingFields = [
  'date',
  'name',
  'received_by',
  'email',
  'state',
  'address',
  'outgoing_leads_status',
  'sys_source',
];
export const spamIncomingLeadsStartingFields = ['date', 'name', 'email', 'phone', 'address', 'sys_source'];
export const customerLeadsStartingFields = [
  'date',
  'incoming_id',
  'customer_name',
  'rule_name',
  'lead_price',
  'exclusive',
  'received_by',
  'name',
  'address',
  'remarks',
  'flag',
];
export const outgoingLeadsStartingFields = [
  'id',
  'incoming_id',
  'connection_name',
  'date',
  'customer_name',
  'receiver_name',
  'status',
];
export const receiverLeadsStartingFields = [
  'date',
  'fullAddress',
  'name',
  'email',
  'phone',
  'remarks',
  'lead_price',
  'district',
];
export const formatFieldsToColumns = (fields: string[], startingFields: string[]): Column[] => {
  const mixedFields = [...startingFields, ...fields, 'id'];
  const uniqueColumns = [...new Set(mixedFields)];
  return uniqueColumns.map((field) => {
    const id = String(field);
    const label = String(field);
    const visible = startingFields.indexOf(field) >= 0;

    return { id, label, visible };
  });
};

export const formatFieldsToColumnsForReceiverLeads = (receiverLeads: ReceiverLead[]): string[] => {
  return receiverLeads
    .reduce((columns: string[], lead: object) => resolveColumns(columns, lead), [])
    .filter((field: string) => !field.startsWith('gen'))
    .filter((field: string) => !field.startsWith('sys'))
    .filter((field: string) => !field.startsWith('h1'))
    .filter((field: string) => !field.startsWith('active_choice'))
    .sort();
};

function resolveColumns(columns: string[], obj: { [key: string]: any }) {
  for (const key in obj) {
    if (isPlainObject(obj[key])) {
      resolveColumns(columns, obj[key]);
    } else if (!columns.includes(key) && !isEmailHeaderColumn(key)) {
      columns.push(key);
    }
  }
  return columns;
}

export function asValidOption(customer: CustomerState) {
  return {
    ...customer,
    value: customer.id,
    label: customer.name,
  };
}

export function useDebounce(value: string, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
}

export const setTargetOperator = (
  operator: { value: string; label: string },
  options: { value: string; label: string }[]
) => {
  const currentOperatorIndex = options.map((option) => option.value).indexOf(operator.value);
  return options[currentOperatorIndex + 1 <= options.length - 1 ? currentOperatorIndex + 1 : 0];
};

export const nonEmptyFieldIds = (fieldIds: LeadsRule[]) => {
  return fieldIds.filter((field) => field.value.length > 0);
};

export const buildFieldQueryString = (fields: Rule[], type: string): string => {
  return fields
    .filter((field) => field.value !== '') // filter away empty values for fields
    .map((field) => {
      const operator = field.operator.replace('not_', '');
      const operatorPrefix = operator !== field.operator ? '!' : '';
      const value = operator === 'equal' ? field.value : String(field.values);

      return `${type}[${field.field}]=${operatorPrefix}${value}`;
    })
    .join(',');
};

export const buildFieldQueryStringForLeads = (fields: LeadsRule[]): string => {
  return fields
    .filter((field) => field.value !== '') // filter away empty values for fields
    .map((field) => {
      return `field_id_operator=${field.field_id_operator}/field_id=${field.field}/value_operator=${field.value_operator}/value=${field.value}`;
    })
    .join('commaseparate');
};

export const outgoingLeadsColumns = [
  { id: 'id', label: 'Id', visible: true },
  { id: 'customer_id', label: 'Customer id', visible: true },
  { id: 'customer_name', label: 'Customer name', visible: true },
  { id: 'receiver_name', label: 'Receiver name', visible: true },
  { id: 'connection_name', label: 'Connection name', visible: true },
  { id: 'status', label: 'Status', visible: true },
  { id: 'proxy', label: 'Proxy', visible: true },
  { id: 'leadmail', label: 'Leadmail', visible: true },
  { id: 'details', label: 'Details', visible: true },
];

export const outgoingLeadsAttemptsColumns = [
  { id: 'id', label: 'Id', visible: true },
  { id: 'timestamp', label: 'Date', visible: true },
  { id: 'outgoing_id', label: 'Outgoing id', visible: true },
  { id: 'status', label: 'Status', visible: true },
  { id: 'proxy', label: 'Proxy', visible: true },
  { id: 'leadmail', label: 'Leadmail', visible: true },
];

export const customerLeadsColumns = [
  { id: 'remarks', label: 'Remarks', visible: true },
  { id: 'id', label: 'Id', visible: true },
  { id: 'customer_name', label: 'Customer name', visible: true },
  { id: 'exclusive', label: 'Exclusive', visible: true },
  { id: 'lead_price', label: 'Lead price', visible: true },
  { id: 'customer_priority', label: 'Customer priority', visible: true },
  { id: 'notes', label: 'Notes', visible: true },
  { id: 'flag', label: 'Flag', visible: true },
  { id: 'details', label: 'Details', visible: true },
];

const targetedFields = [
  'sys_source',
  'name',
  'email',
  'fullAddress',
  'phone_international_format',
  'postarea',
  'district',
  'state',
  'zip',
  'general_needs',
  'contact_type',
  'tabs',
];

export const colorCodedLeadDataFields = new Set(targetedFields);

export const checkIfSpamColumn = (incomingLead: IncomingLead, column: string): boolean => {
  return incomingLead.meta?.spam?.[column] !== undefined;
};

export const formatDates = (value: string) => {
  switch (value) {
    case 'today':
      return {
        after_date: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      };
    case 'yesterday':
      return {
        after_date: moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      };
    case 'last 7 days':
      return {
        after_date: moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      };
    case 'this month':
      return {
        after_date: moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      };
    case 'last month':
      return {
        after_date: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss'),
      };
    case 'last 365 days':
      return {
        after_date: moment().subtract(1, 'year').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      };
    case 'all time':
      return {
        after_date: moment('2017-06-08').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      };
    default:
      break;
  }
};

export const dateOptions = [
  'today',
  'yesterday',
  'last 7 days',
  'this month',
  'last month',
  'last 365 days',
  'all time',
];
