import { createAction, PayloadAction, PrepareAction } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { SagaIterator } from 'redux-saga';
import { getContext, put, takeEvery } from 'redux-saga/effects';
import { Query, RequestBody, Response } from '../api-client';

export interface HttpPayload<B extends RequestBody, Q extends Query> {
  method: 'get' | 'post';
  path: string;
  body?: B;
  query?: Q;
}

export interface RequestPayload<D, B extends RequestBody, Q extends Query, M> {
  http: HttpPayload<B, Q>;
  data?: D;
  meta?: M;
}

export interface SuccessPayload<D = any, M = any, B = any> {
  response: Response<D, M>;
  meta?: M;
  data: B;
}

export interface FailurePayload<P = any, B extends RequestBody = any, Q extends Query = any, M = any> {
  error: string;
  request: PayloadAction<RequestPayload<P, B, Q, M>>;
}

export function createRequestActions<P, B extends RequestBody, Q extends Query, M>(name: string, prepareAction: (payload: P, meta?: M) => { http: HttpPayload<B, Q>, meta?: M; }) {
  const { request, success, failure } = getActionTypes(name);

  return {
    request: createAction<PrepareAction<RequestPayload<any, any, any, any>>>(request, (payload) => ({
      payload: prepareAction(payload)
    })),
    success: createAction<PrepareAction<SuccessPayload>>(success, (payload, meta) => ({ payload, meta })),
    failure: createAction<PrepareAction<FailurePayload>>(failure, (payload, meta) => ({ payload, meta })),
  };
}

export function get<Q extends Query>(path: string, query?: Q): HttpPayload<{}, Q> {
  return makeHttpPayload('get', path, undefined, query);
}

export function post<B extends RequestBody, Q extends Query>(path: string, body?: B, query?: Q): HttpPayload<B, Q> {
  return makeHttpPayload('post', path, body, query);
}

export function makeHttpPayload<B extends RequestBody, Q extends Query>(
  method: 'get' | 'post',
  path: string,
  body?: B,
  query?: Q,
): HttpPayload<B, Q> {
  return { method, path, body, query };
}

function* makeRequest<D, B extends RequestBody, Q extends Query, M>(action: PayloadAction<RequestPayload<D, B, Q, M>>): SagaIterator<void> {
  const api = yield getContext('api');
  const { success, failure } = getActionTypes(extractName(action));
  const { method, path, body, query } = action.payload.http;

  try {
    const response = yield api.makeRequest(method, path, body, query);
    yield put({ type: success, payload: { data: action.payload.data, response, meta: action.payload.meta }});
  } catch (error: any) {
    yield put({ type: failure, payload: { error: error.message, request: action, meta: action.payload.meta } });
  }
}

function extractName(action: Action) {
  return action.type.split('_').slice(0, -1).join('_');
}

function getActionTypes(name: string) {
  return {
    request: `${name}_REQUEST`,
    success: `${name}_SUCCESS`,
    failure: `${name}_FAILURE`,
  };
}

export function* effects() {
  yield takeEvery((action: Action) => {
    if (action.type === 'WELCOME_EMAIL_GET_REQUEST' || action.type === 'WELCOME_EMAIL_EDIT_REQUEST') {
      return false;
    }

    return action.type.endsWith('_REQUEST');
  }, makeRequest);
}
