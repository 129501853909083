import {
  loadIncomingLeadsFailure,
  loadIncomingLeadsRequest,
  loadIncomingLeadsSuccess,
  redistributeIncomingLeadsFailure,
  redistributeIncomingLeadsRequest,
  redistributeIncomingLeadsSuccess,
  searchIncomingLeadsFailure,
  searchIncomingLeadsRequest,
  searchIncomingLeadsSuccess,
} from './actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import { orderBy } from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import { Status } from '../../../components/data';

export interface leadData {
  [key: string]: string;
}

export interface OutgoingLeadsStatus {
  incoming_id: string;
  outgoing_lead_id: string;
  status: Status;
}

export interface IncomingLead {
  id: number;
  flag: string | null;
  meta?: {
    response_mail?: string;
    spam?: {
      [key: string]: string;
    };
  };
  country_code: string;
  date: string;
  exclusive: string;
  guid: string;
  nature_id: number;
  lead_data: leadData;
  status: string;
  outgoing_leads: OutgoingLeadsStatus[];
  [key: string]: any;
}

export interface LeadsFilter {
  limit: number;
  search: string;
  after_date: string;
  before_date: string;
  natures: number[];
  customers: number[];
  countries: string[];
}

export interface State extends EntityState<IncomingLead> {}

export const adapter: EntityAdapter<IncomingLead> = createEntityAdapter<IncomingLead>({
  sort: (entities) => orderBy(entities, 'id'),
});

export const reducer = createReducer<State>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadIncomingLeadsRequest, (state) => adapter.setLoading(state))
    .addCase(loadIncomingLeadsSuccess, (state, { payload }) =>
      adapter.replace(payload.response.items, state, payload.response.total_count)
    )
    .addCase(loadIncomingLeadsFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(redistributeIncomingLeadsRequest, (state) => adapter.setLoading(state))
    .addCase(redistributeIncomingLeadsSuccess, (state) => adapter.setSuccess(state))
    .addCase(redistributeIncomingLeadsFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(searchIncomingLeadsRequest, (state) => adapter.setLoading(state))
    .addCase(searchIncomingLeadsSuccess, (state, { payload }) =>
      adapter.replace(payload.response.items, state, payload.response.total_count)
    )
    .addCase(searchIncomingLeadsFailure, (state, { payload }) => adapter.setError(payload.error, state));
});
