import { Divider, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { Redirect, Route, RouteProps, Switch, useHistory, useLocation } from 'react-router-dom';

export interface TabbableRoute extends RouteProps {
  label: string;
  path: string;
}

export interface TabbableRoutesProps {
  routes: TabbableRoute[];
}

export function TabbableRoutes(props: TabbableRoutesProps) {
  const { pathname } = useLocation();
  const history = useHistory();

  if (props.routes.length === 0) {
    return null;
  }

  const tab = props.routes.findIndex((route) =>
    route.exact ? pathname === route.path : pathname.startsWith(route.path)
  );
  return (
    <>
      <Tabs
        value={Math.max(tab, 0)}
        onChange={(e, selected: number) => {
          history.push(props.routes[selected].path);
        }}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {props.routes.map((route, index) => (
          <Tab key={route.path} value={index} label={route.label} />
        ))}
      </Tabs>
      <Divider />
      <Switch>
        {props.routes.map((route) => (
          <Route key={route.path} {...route} />
        ))}
        <Redirect to={props.routes[0].path} />
      </Switch>
    </>
  );
}
