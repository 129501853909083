import { createRequestActions, makeHttpPayload } from '../../effects';
import { Id } from '../adapter';
import { DistributionSetting } from './reducer';

export type DistributionSettingData = Partial<Exclude<DistributionSetting, 'id'>>;

export const {
  request: loadDistributionSettingsRequest,
  success: loadDistributionSettingsSuccess,
  failure: loadDistributionSettingsFailure,
} = createRequestActions('LOAD_DISTRIBUTION_SETTINGS', () => ({
  http: makeHttpPayload('get', 'distribution-settings'),
}));

export const {
  request: createDistributionSettingRequest,
  success: createDistributionSettingSuccess,
  failure: createDistributionSettingFailure,
} = createRequestActions('CREATE_DISTRIBUTION_SETTING', ({ body }: { body: DistributionSettingData }) => ({
  http: makeHttpPayload('post', 'distribution-settings', body),
}));

export const {
  request: updateDistributionSettingRequest,
  success: updateDistributionSettingSuccess,
  failure: updateDistributionSettingFailure,
} = createRequestActions('UPDATE_DISTRIBUTION_SETTING', ({ id, body }: { id: Id; body: DistributionSettingData }) => ({
  http: makeHttpPayload('post', `distribution-settings/${id}`, body),
  data: { id, body },
}));

export const {
  request: removeDistributionSettingRequest,
  success: removeDistributionSettingSuccess,
  failure: removeDistributionSettingFailure,
} = createRequestActions('REMOVE_DISTRIBUTION_SETTING', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `distribution-settings/${id}`, { status: 'deleted' }),
  data: { id },
}));

export const {
  request: restoreDistributionSettingRequest,
  success: restoreDistributionSettingSuccess,
  failure: restoreDistributionSettingFailure,
} = createRequestActions(
  'RESTORE_DISTRIBUTION_SETTING',
  ({ id, body }: { id: Id; body: DistributionSettingData }) => ({
    http: makeHttpPayload('post', `distribution-settings/${id}`, body),
    data: { id, body },
  })
);

export const {
  request: recoverDistributionSettingRequest,
  success: recoverDistributionSettingSuccess,
  failure: recoverDistributionSettingFailure,
} = createRequestActions('RECOVER_DISTRIBUTION_SETTING', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `distribution-settings/${id}`, { status: 'active' }),
  data: { id },
}));
