import { SnackbarProvider } from 'notistack';
import React, { ReactNode } from 'react';

import { Notifier } from './Notifier';

export interface NotificationProviderProps {
  children: ReactNode;
}

export function NotificationProvider({ children }: NotificationProviderProps) {
  return (
    <SnackbarProvider>
      <>
        <Notifier />
        {children}
      </>
    </SnackbarProvider>
  );
}
