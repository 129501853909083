import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../reducer';
import { adapter } from './reducer';
import { Receiver } from './types';
import { useEffect } from 'react';
import { loadReceiversRequest } from './actions';
import moment from 'moment';
import { DateRange } from '../customers/types';

export const receiversSelector = (state: RootState) => state.entities.receivers;
export const { selectAll, selectById, selectHistory, selectTotal } = adapter.selectors(receiversSelector);
export const selectExisting = createSelector(selectAll, (receivers: Receiver[]) =>
  receivers.filter((receiver) => receiver.status !== 'deleted')
);

export function useAndFetchReceivers() {
  const { loaded, loading, error } = useSelector(receiversSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded && !loading && !error) {
      dispatch(loadReceiversRequest());
    }
  }, [dispatch, loaded, loading, error]);

  const entities = useSelector(selectAll);
  return { loaded, loading, error, entities };
}

export function selectReceivers() {
  return createSelector(selectAll, receiversSelector, (receivers, { loading, loaded, error }) => ({
    entities: receivers,
    loading,
    loaded,
    error,
  }));
}

export function selectReceiverStatus({ date_ranges, status }: Receiver) {
  if (date_ranges.some((date_range) => getStatusForDateRange(date_range) === 'excluded')) {
    return 'excluded';
  } else if (date_ranges.some((date_range) => getStatusForDateRange(date_range) === 'scheduledExclusion')) {
    return 'scheduledExclusion';
  } else {
    return status;
  }
}

const getStatusForDateRange = (date_range: DateRange) => {
  if (date_range.start && !date_range.end) {
    if (moment().isSameOrAfter(date_range.start)) {
      return 'excluded';
    } else if (moment().isBefore(date_range.start)) {
      return 'scheduledExclusion';
    }
  } else if (!date_range.start && date_range.end) {
    if (moment().isSameOrBefore(date_range.end)) {
      return 'excluded';
    }
  } else if (date_range.start && date_range.end) {
    return moment().isSameOrAfter(date_range.start) && moment().isSameOrBefore(date_range.end)
      ? 'excluded'
      : moment().isSameOrBefore(date_range.start) && 'scheduledExclusion';
  }
};
