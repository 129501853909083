import React, { Ref } from 'react';
import { Action } from 'redux';

import { Button, Grid, IconButton, makeStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import { Notification as NotificationModel } from '../../store/ui/notifications/reducer';

export interface NotificationProps {
  notification: NotificationModel;
  onAction: (key: number, action: Action) => any;
  onDismiss: (key: number) => any;
}

const useStyles = makeStyles(theme => ({
  alert: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: 288
    }
  }
}));

export const Notification = React.forwardRef(({ notification, onAction, onDismiss }: NotificationProps, ref: Ref<any>) => {
  const classes = useStyles();
  const { key, variant, message, label, action } = notification;

  return (
    <Alert
      ref={ref}
      variant="filled"
      severity={variant}
      elevation={6}
      className={classes.alert}
      action={
        <Grid container spacing={1} alignItems="center">
          {label && action ? (
            <Grid item>
              <Button size="small" color="inherit" onClick={() => onAction(key, action)}>
                {label}
              </Button>
            </Grid>
          ) : null}
          <Grid item>
            <IconButton color="inherit" size="small" onClick={() => onDismiss(key)}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      }
    >
      {message}
    </Alert>
  );
});
