import { createBrowserHistory } from 'history';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';

export const createAuthClient = async (): Promise<AuthClient> => {
  const client_id =
    (window.location.hostname.startsWith('test.')
      ? process.env.REACT_APP_AUTH0_CLIENT_ID_TEST
      : process.env.REACT_APP_AUTH0_CLIENT_ID) || '';

  const client = await createAuth0Client({
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
    client_id,
    redirect_uri: window.location.origin,
  });

  return new AuthClient(client);
};

export interface User {
  email: string;
  name: string;
  picture?: string;
}

export class AuthClient {
  constructor(private client: Auth0Client) {}

  redirectToLogin(): Promise<void> {
    return this.client.loginWithRedirect();
  }

  logOut(): void {
    this.client.logout();
  }

  isAuthenticated(): Promise<boolean> {
    return this.client.isAuthenticated();
  }

  isRedirected(): boolean {
    const params = new URLSearchParams(window.location.search);
    return (params.has('code') && params.has('state')) || (params.has('error') && params.has('error_description'));
  }

  async handleRedirect(redirectPath: string | null): Promise<void> {
    try {
      // After redirect to callback page wait for handleRedirectCallback() to handle success and error responses
      await this.client.handleRedirectCallback();
    } catch (error) {
      throw error;
    } finally {
      let history = createBrowserHistory({ forceRefresh: true });
      // Only redirect if redirectPath is defined. Otherwise just stay on root.
      if (redirectPath && typeof redirectPath === 'string') {
        history.replace(redirectPath);
      }
    }
  }

  getToken(): Promise<string> {
    return this.client.getTokenSilently();
  }

  async getUser(): Promise<User> {
    const user = await this.client.getUser();

    if (!user?.email || !user?.name) {
      throw new Error('User not found');
    }

    return {
      email: user.email,
      name: user.name,
      picture: user.picture,
    };
  }
}
