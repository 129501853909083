import moment from 'moment';
import { Column } from '../../../components/table';
import {
  buildFieldQueryStringForLeads,
  nonEmptyFieldIds,
} from '../../../views/Leads/LeadUtils/components/LeadsHelpers';
import { createRequestActions, get, post } from '../../effects';
import { LeadsFilters } from '../../ui/incoming-leads-table';

export const {
  request: loadIncomingLeadsRequest,
  success: loadIncomingLeadsSuccess,
  failure: loadIncomingLeadsFailure,
} = createRequestActions('LOAD_INCOMING_LEADS', ({ filters }: { filters: LeadsFilters }) => {
  const fieldIdSearch = nonEmptyFieldIds(filters.field_ids);
  return {
    http: get(`leads/incoming/${filters.type}`, {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      limit: filters.limit,
      after_date: moment(filters.after_date).format('YYYY-MM-DD HH:mm:ss'),
      before_date: moment(filters.before_date).format('YYYY-MM-DD HH:mm:ss'),
      countries: filters.countries && filters.countries.length > 0 ? filters.countries : undefined,
      natures: filters.natures && filters.natures.length > 0 ? filters.natures : undefined,
      offset: filters.offset,
      field_ids: fieldIdSearch.length > 0 ? buildFieldQueryStringForLeads(filters.field_ids) : '',
    }),
  };
});

export const downloadIncomingLeads = createRequestActions(
  'DOWNLOAD_INCOMING_LEADS',
  ({ format, filters, columns }: { format: 'xlsx' | 'csv' | 'json'; filters: LeadsFilters; columns: Column[] }) => {
    return {
      http: get(`leads/incoming/${filters.type}/export`, {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        after_date: moment(filters.after_date).format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment(filters.before_date).format('YYYY-MM-DD HH:mm:ss'),
        export_fields: columns
          .filter((column) => column.visible)
          .filter((column) => column.id !== 'outgoing_leads_status')
          .map((column) => {
            return column.label;
          }),
        countries: filters.countries,
        natures: filters.natures,
        format,
        limit: 5000,
        offset: 0,
        medium: 'file',
      }),
    };
  }
);

export const {
  request: searchIncomingLeadsRequest,
  success: searchIncomingLeadsSuccess,
  failure: searchIncomingLeadsFailure,
} = createRequestActions('SEARCH_INCOMING_LEADS', ({ search, filters }: { search: string; filters: LeadsFilters }) => {
  return {
    http: get(`leads/incoming/${filters.type}`, {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      limit: filters.limit,
      search: search,
      countries: filters.countries,
      natures: filters.natures,
      after_date: moment(filters.after_date).format('YYYY-MM-DD HH:mm:ss'),
      before_date: moment(filters.before_date).format('YYYY-MM-DD HH:mm:ss'),
      offset: 0,
    }),
  };
});

export const {
  request: redistributeIncomingLeadsRequest,
  success: redistributeIncomingLeadsSuccess,
  failure: redistributeIncomingLeadsFailure,
} = createRequestActions(
  'REDISTRIBUTE_INCOMING_LEADS',
  ({ ids, filters }: { ids: number | number[] | undefined; filters: LeadsFilters | undefined }) => {
    const formattedIncomingIds = Array.isArray(ids) ? ids : ids ? [ids] : [];
    return {
      http: post('dispatch/redistribute', {
        incoming_ids: formattedIncomingIds,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        after_date: filters ? moment(filters.after_date).format('YYYY-MM-DD HH:mm:ss') : '-',
        before_date: filters ? moment(filters.before_date).format('YYYY-MM-DD HH:mm:ss') : '-',
        countries: filters?.countries && filters.countries.length > 0 ? filters.countries : undefined,
        natures: filters?.natures && filters.natures.length > 0 ? filters.natures : undefined,
      }),
    };
  }
);
