import { createSelector } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';
import { RootState } from '../reducer';

export type Id = number;

export type IdSelector<E> = (entity: E) => Id;
export type Sorter<E> = (entities: E[]) => E[];
export type Transformer<E, I> = (data: I) => E;
export type Relation = { field: string; selector: any; property?: string };

export interface EntityState<E> {
  ids: Id[];
  entities: { [id in Id]: E };
  history: { [id in Id]: E };
  loading: boolean;
  loaded: boolean;
  error: string | null;
  total: number;
  totalFailed?: number;
  exclusive?: number;
  normal?: number;
}

export interface EntitySelectors<E, R> {
  selectIds: (state: any) => Id[];
  selectTotal: (state: any) => number;
  selectAll: (state: any) => (E & R)[];
  selectById: (id: Id) => (state: any) => (E & R) | null;
  selectHistory: (id: Id) => (state: any) => E;
}

export interface EntityAdapter<E, I = E> {
  initialState<S extends EntityState<E>>(additionalState?: any): S;
  selectors<R>(selector: (state: RootState) => EntityState<E>): EntitySelectors<E, R>;
  setLoading<S extends EntityState<E>>(state: S): S;
  setSuccess<S extends EntityState<E>>(state: S): S;
  setError<S extends EntityState<E>>(message: string, state: S): S;
  select<S extends EntityState<E>>(id: Id, state: S): S;
  deselect<S extends EntityState<E>>(state: S): S;
  add<S extends EntityState<E>>(entity: I, state: S): S;
  addMany<S extends EntityState<E>>(entities: I[], state: S, loaded?: boolean): S;
  update<S extends EntityState<E>>(id: Id, data: any, state: S): S;
  replace<S extends EntityState<E>>(entities: I[], state: S, total: number, normal?: number, exclusive?: number): S;
  updateTotal<S extends EntityState<E>>(state: S, total: number): S;
}

export function createEntityAdapter<E, I = E>(options?: {
  selectId?: IdSelector<E>;
  sort?: Sorter<E>;
  transform?: Transformer<E, I>;
  relations?: { [field: string]: Relation };
}): EntityAdapter<E, I> {
  const { selectId, sort, transform, relations  } = {
    selectId: (entity: E): Id => (entity as any).id,
    sort: (entities: E[]) => entities,
    transform: (data: I): E => (data as unknown) as E,
    relations: {},
    ...options,
  };

  const sortState = <S extends EntityState<E>>(state: S) => {
    return { ...state, ids: sort(Object.values(state.entities)).map((entity) => selectId(entity)) };
  };

  const relationSelector = createStructuredSelector(
    Object.keys(relations).reduce(
      (selectors, relation) => ({
        ...selectors,
        [relation]: relations[relation].selector,
      }),
      {}
    ) as { [field: string]: (state: any) => any }
  );

  const resolveRelations = (ids: Id | Id[], relation: Relation, state: EntityState<any>) => {
    if (typeof relation.property === 'string') {
      const property = relation.property;
      const entities = Object.values(state.entities);
      return Array.isArray(ids)
        ? ids
            .map((id) => {
              return entities.find((entity) => entity[property] === id);
            })
            .filter((item) => !!item)
        : entities.find((entity) => entity[property] === ids) || null;
    } else {
      return Array.isArray(ids)
        ? ids.map((id) => state.entities[id]).filter((item) => !!item)
        : state.entities[ids] || null;
    }
  };

  const attachRelations = (entity: any, states: { [field: string]: EntityState<any> }) => {
    const relatedEntities = Object.keys(states).reduce((related: any, field: string) => {
      const ids: Id | Id[] = entity[relations[field].field];

      return {
        ...related,
        [field]: resolveRelations(ids, relations[field], states[field]),
      };
    }, {});

    return {
      ...entity,
      ...relatedEntities,
    };
  };

  return {
    initialState<S extends EntityState<E>>(additionalState?: any): S {
      return {
        ids: [],
        entities: {},
        loading: false,
        loaded: false,
        selected: null,
        updating: null,
        error: null,
        ...additionalState,
      };
    },
    setLoading<S extends EntityState<E>>(state: S): S {
      return { ...state, loading: true };
    },
    setSuccess<S extends EntityState<E>>(state: S): S {
      return { ...state, loading: false, loaded: true };
    },
    setError<S extends EntityState<E>>(message: string, state: S): S {
      return { ...state, loading: false, error: message };
    },
    select<S extends EntityState<E>>(id: Id, state: S): S {
      return { ...state, selected: id };
    },
    deselect<S extends EntityState<E>>(state: S): S {
      return { ...state, selected: null };
    },
    add<S extends EntityState<E>>(instance: I, state: S): S {
      const entity = transform(instance);
      const id = selectId(entity);
      return sortState<S>({
        ...state,
        error: null,
        ids: [...state.ids, id],
        entities: { ...state.entities, [id]: entity },
      });
    },
    addMany<S extends EntityState<E>>(instances: I[], state: S, loaded: boolean = true): S {
      const entities = instances.map(transform);
      const hasLoaded = state.loaded;
      return sortState({
        ...state,
        error: null,
        ids: entities.map(selectId),
        entities: entities.reduce((previous, entity) => ({ ...previous, [selectId(entity)]: entity }), state.entities),
        loading: false,
        loaded: !hasLoaded && loaded ? true : !(!hasLoaded && !loaded)
      });
    },
    replace<S extends EntityState<E>>(instances: I[], state: S, total: number, exclusive?: number, normal?: number): S {
      const entities = instances.map(transform);
      return sortState({
        ...state,
        error: null,
        ids: entities.length > 0 ? entities.map(selectId) : [],
        entities:
          entities.length > 0
            ? entities.reduce((previous, entity) => ({ ...previous, [selectId(entity)]: entity }), {})
            : {},
        loading: false,
        loaded: true,
        total,
        exclusive,
        normal,
      });
    },
    update<S extends EntityState<E>>(id: Id, data: any, state: S): S {
      const entity = state.entities[id];
      return sortState({
        ...state,
        error: null,
        entities: { ...state.entities, [id]: transform({ ...entity, ...data }) },
        history: { ...state.history, [id]: entity },
        loading: false,
        loaded: true,
      });
    },
    updateTotal<S extends EntityState<E>>(state: S, total: number): S {
      return sortState({
        ...state,
        loading: false,
        loaded: true,
        totalFailed: total,
      });
    },
    selectors<R>(selector: (state: RootState) => EntityState<E>): EntitySelectors<E, R> {
      return {
        selectIds: createSelector(selector, ({ ids }) => ids),
        selectTotal: createSelector(selector, ({ ids }) => ids.length),
        selectAll: createSelector(
          [selector, relationSelector],
          ({ entities, ids }: EntityState<E>, states: { [field: string]: EntityState<any> }) =>
            ids.map((id: Id) => attachRelations(entities[id], states))
        ),
        selectById: (id: Id) =>
          createSelector(
            [selector, relationSelector],
            ({ entities }: EntityState<E>, states: { [field: string]: EntityState<any> }) =>
              entities[id] ? attachRelations(entities[id], states) : null
          ),
        selectHistory: (id: Id) => createSelector(selector, ({ history }) => history[id]),
      };
    },
  };
}
