import {
  WELCOME_EMAIL_EDIT_FINISHED,
  WELCOME_EMAIL_EDIT_REQUEST,
  WELCOME_EMAIL_GET_FINISHED,
  WELCOME_EMAIL_GET_REQUEST,
} from './welcomeEmailAction';

export function editWelcomeEmail(
  state = {
    loading: false,
    success: false,
  },
  action
) {
  switch (action.type) {
    case WELCOME_EMAIL_EDIT_REQUEST:
      return { ...state, loading: true };
    case WELCOME_EMAIL_EDIT_FINISHED:
      return { ...state, loading: false, success: action.success };
    default:
      return state;
  }
}

export function welcomeEmail(state = {
    loading: true,
    success: true,
    result: [],
    id: 0
}, action) {
    switch (action.type) {
        case WELCOME_EMAIL_GET_REQUEST:
            return { ...state, loading: true, id: action.id }
        case WELCOME_EMAIL_GET_FINISHED:
            return { ...state, loading: false, success: action.success, result: action.result }
        default:
            return state
    }
}
