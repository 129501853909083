import { EntityName, Id } from '../entities';
import { createRequestActions, makeHttpPayload } from '../effects';
import { createAction } from '@reduxjs/toolkit';
import { kebabCase, snakeCase } from 'lodash';

export type AssociationsPayload = {
  entityId: Id;
  entityName: EntityName;
  associatedEntityName: EntityName;
};

export type AddAssociationsPayload = AssociationsPayload & { ids: number[] };

/**
 * Fetches associated entities of provided type.
 * @example { entityName: 'customer', entityId: 1, associatedEntityName: 'receivers' }
 * will fetch all receivers associated to customer with id 1.
 */
export const {
  request: loadAssociatedEntitiesRequest,
  success: loadAssociatedEntitiesSuccess,
  failure: loadAssociatedEntitiesFailure,
} = createRequestActions(
  'LOAD_ASSOCIATED_ENTITIES',
  ({ entityName, entityId, associatedEntityName }: AssociationsPayload) => ({
    http: makeHttpPayload('get', `${kebabCase(entityName)}/${entityId}/${kebabCase(associatedEntityName)}`),
    meta: { entityName, entityId, associatedEntityName },
  })
);

/**
 * Fetches all associations for a given entity-type.
 * @example { entityName: 'customers' } will fetch all associations for customers.
 * @todo Make it possible to specify what kind of associations to fetch and not have that pre-decided by the server.
 */
export const {
  request: loadAssociationsBulkRequest,
  success: loadAssociationsBulkSuccess,
  failure: loadAssociationsBulkFailure,
} = createRequestActions('LOAD_ASSOCIATIONS_BULK', ({ entityName }: AssociationsPayload) => ({
  http: makeHttpPayload('get', `${kebabCase(entityName)}/associations`),
  meta: { entityName },
}));

/**
 * Creates an association between a given entity and one or more associated entities.
 * @example { entityName: 'customers', entityId: 1, associatedEntityName: 'receivers', ids: [1, 2] }
 * will create an association between customer with id 1 and receivers with id 1 and 2.
 */
export const {
  request: addAssociationsRequest,
  success: addAssociationsSuccess,
  failure: addAssociationsFailure,
} = createRequestActions(
  'ADD_ASSOCIATIONS',
  ({ entityName, entityId, associatedEntityName, ids }: AddAssociationsPayload) => ({
    http: makeHttpPayload('post', `${kebabCase(entityName)}/${entityId}/${kebabCase(associatedEntityName)}`, {
      [`${snakeCase(associatedEntityName).slice(0, -1)}_ids`]: ids,
    }),
    meta: { entityName, entityId, associatedEntityName },
  })
);

/**
 * Removes the association between a given entity and one or more associated entities.
 * @example { entityName: 'customers', entityId: 1, associatedEntityName: 'receivers', ids: [1, 2] }
 * will remove the association between customer with id 1 and receivers with id 1 and 2.
 */
export const {
  request: deleteAssociationsRequest,
  success: deleteAssociationsSuccess,
  failure: deleteAssociationsFailure,
} = createRequestActions(
  'DELETE_ASSOCIATIONS',
  ({ entityName, entityId, associatedEntityName, ids }: AddAssociationsPayload) => ({
    http: makeHttpPayload('post', `${kebabCase(entityName)}/${entityId}/${kebabCase(associatedEntityName)}`, {
      [`${snakeCase(associatedEntityName).slice(0, -1)}_ids`]: ids,
    }),
    meta: { entityName, entityId, associatedEntityName },
  })
);

/**
 * Creates a new entity and adds relations to possible multiple entity-types after creation.
 */
export const addEntityAndCreateAssociations = createAction(
  'ADD_ENTITY_AND_ADD_MULTIPLE_ASSOCIATIONS',
  (incompleteEntityData: object, entityName: string, associatedEntities: Record<string, number[]>, meta?: any) => ({
    payload: { incompleteEntityData, entityName, associatedEntities},
    meta
  })
);

/**
 * Creates a new entity and adds relation to another entity-type after creation.
 */
export const addAssociatedEntity = createAction(
  'ADD_ENTITY_AND_CREATE_ASSOCIATION',
  (associationPayload: AssociationsPayload, incompleteEntityData: object, meta?: any) => ({
    payload: { incompleteEntityData, associationPayload },
    meta,
  })
);
