import { orderBy } from 'lodash';

import { createReducer } from '@reduxjs/toolkit';

import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import {
  addCountrySuccess,
  loadCountriesFailure,
  loadCountriesRequest,
  loadCountriesSuccess,
  recoverCountrySuccess,
  removeCountrySuccess,
  restoreCountrySuccess,
  updateCountrySuccess,
} from './actions';

export type CountryStatus = 'active' | 'disabled' | 'deleted';

export interface Country {
  id: number;
  code: string;
  name: string;
  timezone: string;
  status: CountryStatus;
}

export interface State extends EntityState<Country> {}

export const adapter: EntityAdapter<Country> = createEntityAdapter<Country>({
  selectId: (country) => country.id,
  sort: (entities) => orderBy(entities, 'name'),
});

export const reducer = createReducer<State>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadCountriesRequest, (state) => adapter.setLoading(state))
    .addCase(loadCountriesSuccess, (state, { payload }) => adapter.addMany(payload.response.items, state))
    .addCase(loadCountriesFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(addCountrySuccess, (state, { payload }) => adapter.add(payload.response.data, state))
    .addCase(updateCountrySuccess, (state, { payload }) => adapter.update(payload.data.id, payload.data.body, state))
    .addCase(restoreCountrySuccess, (state, { payload }) => adapter.update(payload.data.id, payload.data.body, state))
    .addCase(recoverCountrySuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: 'active' }, state)
    )
    .addCase(removeCountrySuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: 'deleted' }, state)
    );
});
