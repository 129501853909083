import { createReducer } from '@reduxjs/toolkit';
import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import {
  loadUsersInOrganizationFailure,
  loadUsersInOrganizationRequest,
  loadUsersInOrganizationSuccess,
} from './actions';
import { orderBy } from 'lodash';
import { ReceiverPermission } from '../receivers/types';

export interface CrmPermission {
  organization_id: number;
  public_metadata: {
    access: { [key: number]: ReceiverPermission };
    receiver: { [key: number]: number };
  };
}

export interface State extends EntityState<CrmPermission> {}

export const adapter: EntityAdapter<CrmPermission> = createEntityAdapter<CrmPermission>({
  sort: (entities) => orderBy(entities, 'id'),
});

export const reducer = createReducer<State>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadUsersInOrganizationRequest, (state) => adapter.setLoading(state))
    .addCase(loadUsersInOrganizationSuccess, (state, { payload }) => adapter.add(payload.response.items, state))
    .addCase(loadUsersInOrganizationFailure, (state, { payload }) => adapter.setError(payload.error, state));
});
