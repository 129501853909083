import { createReducer } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import { Country } from '../countries';
import { Nature } from '../natures';
import {
  createDistributionSettingSuccess,
  loadDistributionSettingsFailure,
  loadDistributionSettingsRequest,
  loadDistributionSettingsSuccess,
  recoverDistributionSettingSuccess,
  removeDistributionSettingSuccess,
  restoreDistributionSettingSuccess,
  updateDistributionSettingSuccess,
} from './actions';
import { ExtraProduct } from "../customers/types";

export type Status = 'active' | 'disabled' | 'deleted';

export interface DistributionSetting {
  id: number;
  status: Status;
  name: string;
  languages: string[];
  response_mail: boolean;
  response_sms: boolean;
  reply_to_source: boolean;
  lead_price: number | null;
  discount: number | null;
  product_description: string | null;
  customer_limit: number | null;
  extra_products: ExtraProduct[];
}

export interface DistributionSettingWithRelations extends Omit<DistributionSetting, 'natures' | 'languages'> {
  natures: Nature[];
  languages: Country[];
}

export const adapter: EntityAdapter<DistributionSetting> = createEntityAdapter<DistributionSetting>({
  selectId: (entity) => entity.id,
  sort: (entities) => orderBy(entities, (entity) => entity.name),
});

export const reducer = createReducer<EntityState<DistributionSetting>>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadDistributionSettingsRequest, (state) => adapter.setLoading(state))
    .addCase(loadDistributionSettingsSuccess, (state, { payload }) => adapter.addMany(payload.response.items, state))
    .addCase(loadDistributionSettingsFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(createDistributionSettingSuccess, (state, { payload }) => adapter.add(payload.response.data, state))
    .addCase(updateDistributionSettingSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, payload.data.body, state)
    )
    .addCase(restoreDistributionSettingSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, payload.data.body, state)
    )
    .addCase(recoverDistributionSettingSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: 'active' }, state)
    )
    .addCase(removeDistributionSettingSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: 'deleted' }, state)
    );
});
