import { createRequestActions, get, post } from '../../effects';
import { Id } from '../adapter';

export const {
  request: loadCustomerLeadRequest,
  success: loadCustomerLeadSuccess,
  failure: loadCustomerLeadFailure,
} = createRequestActions('LOAD_CUSTOMER_LEAD', ({ customerLeadId }: { customerLeadId: number }) => {
  return {
    http: get('leads/customer/id', {
      customer_lead_id: customerLeadId,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }),
  };
});

export const {
  request: updateCustomerLeadRequest,
  success: updateCustomerLeadSuccess,
  failure: updateCustomerLeadFailure,
} = createRequestActions('UPDATE_CUSTOMER_LEAD', ({ id, flag }: { id: Id; flag: string | null }) => {
  return {
    http: post('leads/customer/', {
      id,
      flag,
    }),
  };
});
