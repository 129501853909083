import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { EntityState } from '../adapter';
import { adapter, Rule } from './reducer';

export const selectRules = (state: RootState): EntityState<Rule> => state.entities.rules;

export const { selectAll, selectById, selectHistory } = adapter.selectors(selectRules);

export const selectExisting = createSelector(selectAll, (entities) =>
  entities.filter((entity) => entity.status !== 'deleted')
);

export const selectReceiverRules = (id: number) =>
  createSelector(selectExisting, (rules) => rules.filter((rule) => rule.receiver_id === id)
);

export const selectCustomerRules = (id: number) =>
  createSelector(selectExisting, (rules) => rules.filter((rule) => rule.customer_id === id)
);

export const selectDistributionSettingRules = (id: number) =>
  createSelector(selectExisting, (rules) => rules.filter((rule) => rule.settings_id === id)
);


export function useRules() {
  const { loaded, loading, error } = useSelector(selectRules);
  const entities = useSelector(selectExisting);
  return { loaded, loading, error, entities };
}
