import { createReducer } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import {
  loadCustomerLeadsFromIncomingIdFailure,
  loadCustomerLeadsFromIncomingIdRequest,
  loadCustomerLeadsFromIncomingIdSuccess,
  updateCustomerLeadsFromIncomingIdFailure,
  updateCustomerLeadsFromIncomingIdRequest,
  updateCustomerLeadsFromIncomingIdSuccess,
} from './actions';
import { CustomerLead } from '../customerLeads';

export interface State extends EntityState<CustomerLead> {}

export const adapter: EntityAdapter<CustomerLead> = createEntityAdapter<CustomerLead>({
  sort: (entities) => orderBy(entities, 'id'),
});

export const reducer = createReducer<State>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadCustomerLeadsFromIncomingIdRequest, (state) => adapter.setLoading(state))
    .addCase(loadCustomerLeadsFromIncomingIdSuccess, (state, { payload }) =>
      adapter.replace(
        payload.response.items,
        state,
        payload.response.total_count,
        payload.response.exclusive_count,
        payload.response.normal_count
      )
    )
    .addCase(loadCustomerLeadsFromIncomingIdFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(updateCustomerLeadsFromIncomingIdRequest, (state) => adapter.setLoading(state))
    .addCase(updateCustomerLeadsFromIncomingIdSuccess, (state, { payload }) =>
      adapter.update(payload.response.data.id, payload.response.data, state)
    )
    .addCase(updateCustomerLeadsFromIncomingIdFailure, (state, { payload }) => adapter.setError(payload.error, state));
});
