import { createRequestActions, makeHttpPayload } from '../../effects';
import { Id } from '../adapter';
import { Nature } from './reducer';

export type NatureData = Partial<Exclude<Nature, 'id'>>;

export const {
  request: loadNaturesRequest,
  success: loadNaturesSuccess,
  failure: loadNaturesFailure,
} = createRequestActions('LOAD_NATURES', () => ({
  http: makeHttpPayload('get', 'natures'),
}));

export const {
  request: createNatureRequest,
  success: createNatureSuccess,
  failure: createNatureFailure,
} = createRequestActions('CREATE_NATURE', ({ body }: { body: NatureData }) => ({
  http: makeHttpPayload('post', 'natures', body),
}));

export const {
  request: updateNatureRequest,
  success: updateNatureSuccess,
  failure: updateNatureFailure,
} = createRequestActions('UPDATE_NATURE', ({ id, body }: { id: Id; body: NatureData }) => ({
  http: makeHttpPayload('post', `natures/${id}`, body),
  data: { id, body },
}));

export const {
  request: deleteNatureRequest,
  success: deleteNatureSuccess,
  failure: deleteNatureFailure,
} = createRequestActions('DELETE_NATURE', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `natures/${id}`, { status: 'deleted' }),
  data: { id },
}));

export const {
  request: restoreNatureRequest,
  success: restoreNatureSuccess,
  failure: restoreNatureFailure,
} = createRequestActions('RESTORE_NATURE', ({ id, body }: { id: Id; body: NatureData }) => ({
  http: makeHttpPayload('post', `natures/${id}`, body),
  data: { id, body },
}));

export const {
  request: recoverNatureRequest,
  success: recoverNatureSuccess,
  failure: recoverNatureFailure,
} = createRequestActions('RECOVER_NATURE', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `natures/${id}`, { status: 'active' }),
  data: { id },
}));
