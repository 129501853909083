import React from 'react';

import { Card, CardContent, Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export function CardSkeleton() {
  return (
    <Card>
      <CardContent>
        <Skeleton variant="text" animation="wave" width={150} height={48} />
      </CardContent>
      <Divider />
      <CardContent>
        <Skeleton variant="text" animation="wave" width={300} height={24} />
      </CardContent>
    </Card>
  );
}
