import { createReducer } from '@reduxjs/toolkit';
import moment from 'moment';
import { Column, Sort } from '../../../components/table';
import { loadInsights, tableActions } from './actions';

export interface InsightsFilter {
  columns: Column[];
  after_date: string;
  before_date: string;
  timezone: string;
  successfulLeads?: boolean;
  countries?: string[];
  natures?: string[];
  autoInvoice?: boolean;
  flags?: boolean;
  limbo?: boolean;
}

export interface State {
  search: string;
  filters: InsightsFilter;
  columns: Column[];
  sort: Sort;
  insights: {
    loading: boolean;
    loaded: boolean;
    error: string | null;
    data: any[];
  };
}

export const initialState: State = {
  search: '',
  filters: {
    after_date: moment().startOf('month').format('YYYY-MM-DD'),
    before_date: moment().endOf('month').format('YYYY-MM-DD'),
    columns: [],
    countries: [],
    natures: [],
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    successfulLeads: true,
  },
  columns: [],
  sort: {
    column: 'name',
    direction: 'asc',
  },
  insights: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
};

export const reducer = createReducer<State>(initialState, (builder) => {
  builder
    .addCase(loadInsights.request, (state) => {
      return {
        ...state,
        columns: [],
        insights: { loaded: false, loading: true, error: null, data: [] },
      };
    })
    .addCase(loadInsights.success, (state, { payload }) => {
      const data = payload.response.items;
      return {
        ...state,
        columns: Object.keys(data?.[0] ?? {}).map((column) => {
          return {
            id: column,
            //Map unwraps Saga-proxy object
            label:
              state.filters.columns.map((column) => ({ ...column })).find(({ id }) => id === column)?.label ?? column,
            visible: true,
          };
        }),
        insights: { loaded: true, loading: false, error: null, data },
      };
    })
    .addCase(loadInsights.failure, (state, { payload }) => {
      return {
        ...state,
        insights: { loaded: true, loading: false, error: payload.error, data: [] },
      };
    })
    .addCase(tableActions.search, (state, { payload }) => ({ ...state, search: payload.search }))
    .addCase(tableActions.setFilters, (state, { payload }) => ({ ...state, filters: payload.filters }))
    .addCase(tableActions.sort, (state, { payload }) => ({ ...state, sort: payload.sort }));
});
