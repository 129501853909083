import { Box, Button, Grid } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { Information } from './Information';

export interface ErrorScreenProps {
  title: string;
  description: string | ReactNode;
  label?: string;
  onAction?: () => any;
}

export function ErrorScreen({ title, description, label, onAction }: ErrorScreenProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
      <Box textAlign="center" p={4}>
        <Grid container alignItems="center" direction="column">
          <img src="/img/warning.svg" alt="No data found" height={160} />
          <Box mt={4}>
            <Information title={title} description={description} />
          </Box>
          {label ? (
            <Box mt={4}>
              <Button variant="contained" size="large" color="primary" onClick={onAction}>
                {label}
              </Button>
            </Box>
          ) : null}
        </Grid>
      </Box>
    </Box>
  );
}
