import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeEvery } from 'redux-saga/effects';
import { FailurePayload, SuccessPayload } from '../../effects';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from '../../ui/notifications';
import {
  addRuleFailure,
  addRuleSuccess,
  copyCustomerRulesFailure,
  copyCustomerRulesSuccess,
  copyReceiverRulesFailure,
  copyReceiverRulesSuccess,
  loadRulesFailure,
  recoverRuleFailure,
  recoverRuleRequest,
  recoverRuleSuccess,
  removeRuleFailure,
  removeRuleRequest,
  removeRuleSuccess,
  restoreRuleFailure,
  restoreRuleRequest,
  restoreRuleSuccess,
  updateRuleFailure,
  updateRuleSuccess,
} from './actions';
import { selectHistory } from './selectors';
import { history } from '../../../history';

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load rules', label: 'Retry', action: request }));
}

export function* addSuccess({ payload: { response } }: PayloadAction<SuccessPayload>) {
  const undoAction = removeRuleRequest({ id: response.data.id });
  yield put(showSuccessNotification({ message: 'Rule added', label: 'Undo', action: undoAction }));
}

export function* addFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to add rule', label: 'Retry', action: request }));
}

export function* updateSuccess({ payload: { data } }: PayloadAction<SuccessPayload>): Generator {
  const entity = yield select(selectHistory(data.id));
  const undoAction = restoreRuleRequest({ id: data.id, body: entity });
  yield put(showSuccessNotification({ message: 'Rule updated', label: 'Undo', action: undoAction }));
}

export function* updateFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to update rule', label: 'Retry', action: request }));
}

export function* removeSuccess({ payload: { data } }: PayloadAction<SuccessPayload>) {
  const undoAction = recoverRuleRequest({ id: data.id });
  yield put(showWarningNotification({ message: 'Rule removed', label: 'Undo', action: undoAction }));
}

export function* removeFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to remove rule', label: 'Retry', action: request }));
}

export function* restoreSuccess() {
  yield put(showSuccessNotification({ message: 'Rule restored' }));
}

export function* restoreFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to restore rule', label: 'Retry', action: request }));
}

export function* recoverSuccess() {
  yield put(showSuccessNotification({ message: 'Rule recovered' }));
}

export function* recoverFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to recover rule', label: 'Retry', action: request }));
}

export function copyRulesForCustomerSuccess({ payload: { meta } }: PayloadAction<SuccessPayload>) {
  history.push(`/customers/${meta.copyTo}`);
}

export function copyRulesForReceiverSuccess({ payload: { meta } }: PayloadAction<SuccessPayload>) {
  history.push(`/receivers/${meta.copyTo}`);
}

export function* copyRulesForCustomerFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to copy customer rules', label: 'Retry', action: request }));
}

export function* copyRulesForReceiverFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to copy receiver rules', label: 'Retry', action: request }));
}

export function* effects() {
  yield takeEvery(loadRulesFailure, loadFailure);
  yield takeEvery(addRuleSuccess, addSuccess);
  yield takeEvery(addRuleFailure, addFailure);
  yield takeEvery(copyCustomerRulesSuccess, copyRulesForCustomerSuccess);
  yield takeEvery(copyReceiverRulesSuccess, copyRulesForReceiverSuccess);
  yield takeEvery(copyCustomerRulesFailure, copyRulesForCustomerFailure);
  yield takeEvery(copyReceiverRulesFailure, copyRulesForReceiverFailure);
  yield takeEvery(updateRuleSuccess, updateSuccess);
  yield takeEvery(updateRuleFailure, updateFailure);
  yield takeEvery(removeRuleSuccess, removeSuccess);
  yield takeEvery(removeRuleFailure, removeFailure);
  yield takeEvery(restoreRuleSuccess, restoreSuccess);
  yield takeEvery(restoreRuleFailure, restoreFailure);
  yield takeEvery(recoverRuleSuccess, recoverSuccess);
  yield takeEvery(recoverRuleFailure, recoverFailure);
}
