import { Typography } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import React, { Component, ErrorInfo } from 'react';
import { ErrorScreen } from './ErrorScreen';

interface State {
  hasError: boolean;
  eventId?: string;
}

export class ErrorBoundary extends Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (!this.tryHandleChunkLoadError(error)) {
      Sentry.withScope((scope) => {
        scope.setExtras({ extras: errorInfo });
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  tryHandleChunkLoadError = (error: Error) => {
    if (error.name === 'ChunkLoadError' && (error as any).type === 'missing') {
      const previousMessage = sessionStorage.getItem('leadbox-chunk-load-error');
      sessionStorage.setItem('leadbox-chunk-load-error', error.message);

      // Only attempt an automatic page reload for a missing chunk once
      if (previousMessage !== error.message) {
        window.location.reload();
        return true;
      }
    }
    return false;
  };

  render() {
    if (this.state.hasError) {
      return (
        <ErrorScreen
          title="Oops!"
          description={
            <>
              <Typography>An error has occured. Nettbureau has been notified.</Typography>
              <Typography>Please try again in case the error was temporary.</Typography>
              <Typography>
                If the error persists and timely access is critical, please contact your account manager.
              </Typography>
            </>
          }
          label="Reload"
          onAction={() => {
            window.location.reload();
          }}
        />
      );
    }

    return this.props.children;
  }
}
