import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import { orderBy } from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import { loadIanaCountriesFailure, loadIanaCountriesRequest, loadIanaCountriesSuccess } from './actions';

export interface IanaCountry {
  code: string;
  name: string;
  timezones: string[];
  callingCode: string;
}

export interface State extends EntityState<IanaCountry> {}

export const adapter: EntityAdapter<IanaCountry> = createEntityAdapter<IanaCountry>({
  //@ts-ignore - TODO: Remove this when countries are refactored into own table. We need this relation system here for now
  selectId: (country) => country.code,
  sort: (entities) => orderBy(entities, 'name'),
});

export const reducer = createReducer<State>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadIanaCountriesRequest, (state) => adapter.setLoading(state))
    .addCase(loadIanaCountriesSuccess, (state, { payload }) => adapter.addMany(payload.response.items, state))
    .addCase(loadIanaCountriesFailure, (state, { payload }) => adapter.setError(payload.error, state));
});
