import { createAction } from '@reduxjs/toolkit';
import { Column, Sort } from '../../../components/table/';
import { LeadsFilters } from '../incoming-leads-table';

export const setReceiverLeadsSearch = createAction<{ search: string }>('SET_RECEIVER_LEADS_SEARCH');
export const setSelectedReceiverLeads = createAction<{ selected_leads: number[] }>('SET_SELECTED_RECEIVER_LEADS');
export const setReceiverLeadsFilters = createAction<{ filters: LeadsFilters }>('SET_RECEIVER_LEADS_FILTERS');
export const setReceiverLeadsColumns = createAction<{ columns: Column[] }>('SET_RECEIVER_LEADS_COLUMNS');
export const resetReceiverLeadsFilters = createAction('RESET_RECEIVER_LEADS_FILTERS');
export const toggleReceiverLeadsColumn = createAction<{ column: Column }>('TOGGLE_RECEIVER_LEADS_COLUMN');
export const sortReceiverLeads = createAction<{ sort: Sort }>('SORT_RECEIVER_LEADS');
