import { createRequestActions, get } from '../effects';

export const {
  request: loadLeadmailRequest,
  success: loadLeadmailSuccess,
  failure: loadLeadmailFailure,
} = createRequestActions('LOAD_LEADMAIL_LEAD', ({ mailHash }: { mailHash: string }) => {
  return {
    http: get('leadmail', {
      hash: mailHash,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }),
  };
});
