import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import { orderBy } from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import {
  createConnectionSuccess,
  loadConnectionsFailure,
  loadConnectionsRequest,
  loadConnectionsSuccess,
  recoverConnectionSuccess,
  removeConnectionSuccess,
  updateConnectionSuccess,
} from './actions';
import { Connection, Status } from './types';
import { loadAssociatedEntitiesSuccess } from '../../associations';

export type State = EntityState<Connection>;

export const adapter: EntityAdapter<Connection> = createEntityAdapter<Connection>({
  selectId: (entity) => entity.id,
  sort: (entities) => orderBy(entities, 'name')
})

export const reducer = createReducer(adapter.initialState(), builder => {
  builder
    .addCase(loadConnectionsRequest, (state) => adapter.setLoading(state))
    .addCase(loadConnectionsSuccess, (state, { payload }) => adapter.addMany(payload.response.items, state))
    .addCase(loadConnectionsFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(updateConnectionSuccess, (state, { payload }) => adapter.update(payload.data.id, payload.data.body, state))
    .addCase(createConnectionSuccess, (state, { payload }) =>
      adapter.add({...payload.response.data, receiver_ids: [], customer_count: 0, status: Status.ACTIVE}, state)
    )
    .addCase(recoverConnectionSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: Status.ACTIVE }, state)
    )
    .addCase(removeConnectionSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: Status.DELETED }, state)
    )
    .addCase(loadAssociatedEntitiesSuccess, (state, { payload }) =>
      payload.meta.associatedEntityName === 'connections' ? adapter.addMany(payload.response.items, state, false) : state
    );
})
