import { orderBy } from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import { recoverCountrySuccess } from '../countries';
import {
  createReceiverSuccess,
  loadAssociatedReceiversRequest,
  loadAssociatedReceiversSuccess,
  loadReceiverFailure,
  loadReceiverRequest,
  loadReceiversFailure,
  loadReceiversRequest,
  loadReceiversSuccess,
  loadReceiverSuccess,
  restoreReceiverSuccess,
  updateReceiverSuccess,
} from './actions';
import { Receiver } from './types';
import { loadAssociatedEntitiesSuccess } from '../../associations';

export interface State extends EntityState<Receiver> {}

export const adapter: EntityAdapter<Receiver> = createEntityAdapter<Receiver>({
  sort: (entities) => orderBy(entities, 'name'),
});

export const reducer = createReducer<State>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadReceiversRequest, (state) => adapter.setLoading(state))
    .addCase(loadReceiversSuccess, (state, { payload }) => adapter.addMany(payload.response.items, state))
    .addCase(loadReceiversFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(loadReceiverRequest, (state) => adapter.setLoading(state))
    .addCase(loadReceiverSuccess, (state, { payload }) => adapter.addMany(payload.response.items, state))
    .addCase(loadReceiverFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(loadAssociatedReceiversRequest, (state) => adapter.setLoading(state))
    .addCase(loadAssociatedReceiversSuccess, (state, { payload }) => adapter.addMany(payload.data, state))
    .addCase(createReceiverSuccess, (state, { payload }) => adapter.add(payload.response.data, state))
    .addCase(updateReceiverSuccess, (state, { payload }) => adapter.update(payload.data.id, payload.data.body, state))
    .addCase(restoreReceiverSuccess, (state, { payload }) => adapter.update(payload.data.id, payload.data.body, state))
    .addCase(recoverCountrySuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: 'active' }, state)
    )
    .addCase(loadAssociatedEntitiesSuccess, (state, { payload }) =>
      payload.meta.associatedEntityName === 'receivers' ? adapter.addMany(payload.response.items, state, false) : state
    );
});
