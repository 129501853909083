import { createAction } from '@reduxjs/toolkit';

import { Column, Sort } from '../../../components/table';
import { createRequestActions, makeHttpPayload } from '../../effects';
import { CustomerFilters, Rule } from './reducer';
import { buildFieldQueryString } from '../../../views/Leads/LeadUtils/components/LeadsHelpers';

export const searchCustomers = createAction<{ search: string }>('SEARCH_CUSTOMERS');
export const setCustomerFilters = createAction<{ filters: CustomerFilters }>('SET_CUSTOMER_FILTERS');
export const resetCustomerFilters = createAction('RESET_CUSTOMER_FILTERS');
export const toggleCustomerColumn = createAction<{ column: Column }>('TOGGLE_CUSTOMER_COLUMN');
export const sortCustomers = createAction<{ sort: Sort }>('SORT_CUSTOMERS');

export const {
  request: filterCustomersRequest,
  success: filterCustomersSuccess,
  failure: filterCustomersFailure,
} = createRequestActions('FILTER_CUSTOMERS', (rules: Rule[]) => {
  return {
    http: makeHttpPayload('get', `customers?${buildFieldQueryString(rules, 'rules')}`),
  };
});
