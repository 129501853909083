import React from 'react';
import { RouteComponentProps } from 'react-router';

export interface Navigation {
  items: NavigationItem[];
  main: string;
}

export interface NavigationItem {
  title?: boolean;
  name: string;
  url: string;
  open?: boolean;
  icon?: string;
  component?: React.ComponentType<RouteComponentProps>;
  children?: Array<NavigationItem>;
}

export function getNavigation(groups: string[]) {
  const navigation: Navigation = {
    main: '/',
    items: [
      {
        title: true,
        name: 'Menu',
        url: '/',
      },
    ],
  };

  if (groups.includes('admin')) {
    navigation.main = '/leads';
    navigation.items.push(
      {
        name: 'Admin Leads',
        url: '/leads',
        icon: 'icon-envelope-letter',
        open: true,
        component: React.lazy(() => import('./AdminLeads')),
      },
      {
        name: 'Specific leads',
        url: '/specific-leads',
        icon: 'icon-list',
        component: React.lazy(() => import('./AdminLeads')),
        children: [
          {
            name: 'Customer leads',
            url: '/specific-leads/customer',
          },
          {
            name: 'Outgoing leads',
            url: '/specific-leads/outgoing',
          },
        ],
      },
      {
        name: 'Customers',
        url: '/customers',
        icon: 'icon-people',
        open: true,
        component: React.lazy(() => import('./ManageCustomers')),
      },
      {
        name: 'Receivers',
        url: '/receivers',
        icon: 'icon-people',
        open: true,
        component: React.lazy(() => import('./ManageReceivers')),
      },
      {
        name: 'Tools',
        url: '/tools',
        icon: 'icon-wrench',
        open: true,
        component: React.lazy(() => import('./AdminTools')),
        children: [
          {
            name: 'Invoice',
            url: '/tools/invoice',
          },
        ],
      },
      {
        name: 'Admin tools',
        url: '/admin-tools',
        icon: 'icon-wrench',
        component: React.lazy(() => import('./AdminTools')),
        children: [
          {
            name: 'Distribution settings',
            url: '/admin-tools/distribution-settings',
          },
          {
            name: 'Field translations',
            url: '/admin-tools/field-translations',
          },
          {
            name: 'Rule mappings',
            url: '/admin-tools/rule-mappings',
          },
          {
            name: 'Natures',
            url: '/admin-tools/natures',
          },
          {
            name: 'Spam filters',
            url: '/admin-tools/spam-filters',
          },
        ],
      }
    );
  } else if (groups.includes('customer')) {
    navigation.main = '/leads';
    navigation.items.push(
      {
        name: 'Leads',
        url: '/leads',
        open: true,
        icon: 'icon-envelope-letter',
        component: React.lazy(() => import('./CustomerLeads')),
      },
      {
        name: 'Invoice Insights',
        url: '/invoice-insights',
        icon: 'icon-people',
        open: true,
        component: React.lazy(() => import('./CustomerInsights')),
      }
    );
  } else if (groups.includes('sales')) {
    navigation.main = '/leads';
    navigation.items.push({
      name: 'Leads',
      url: '/leads',
      icon: 'icon-envelope-letter',
      open: true,
      component: React.lazy(() => import('./SalesLeads')),
    });
    navigation.items.push({
      name: 'Customers',
      url: '/customers',
      icon: 'icon-people',
      open: true,
      component: React.lazy(() => import('./ManageCustomers')),
    });
  }

  if (groups.includes('dev')) {
    navigation.items.push({
      name: 'Dev tools',
      url: '/dev-tools',
      icon: 'icon-cup',
      component: React.lazy(() => import('./DevTools')),
      children: [
        {
          name: 'Field access',
          url: '/dev-tools/field-access',
        },
        {
          name: 'Connections',
          url: '/dev-tools/connections',
        },
      ],
    });
  }

  return navigation;
}
