import { createReducer } from '@reduxjs/toolkit';
import moment from 'moment';
import { Column, Sort } from '../../../components/table';
import { baseFieldIds, LeadsFilters } from '../incoming-leads-table';
import {
  resetReceiverLeadsFilters,
  setReceiverLeadsColumns,
  setReceiverLeadsFilters,
  setReceiverLeadsSearch,
  setSelectedReceiverLeads,
  sortReceiverLeads,
  toggleReceiverLeadsColumn,
} from './actions';

export interface State {
  search: string;
  selected_leads: number[];
  filters: LeadsFilters;
  filteredIds: number[] | null;
  columns: Column[];
  sort: Sort;
}

export const initialState: State = {
  search: '',
  selected_leads: [],
  filters: {
    field_ids: baseFieldIds,
    after_date: moment
      .utc()
      .startOf('day')
      .subtract(2, 'months')
      .locale('YYYY-MM-DD HH:mm:ss')
      .format('YYYY-MM-DD HH:mm:ss'),
    before_date: moment.utc().endOf('day').locale('YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
    limit: 25,
    offset: 0,
    page: 0,
    customers: [],
  },
  filteredIds: null,
  columns: [],
  sort: {
    column: 'id',
    direction: 'desc',
  },
};

export const receiverLeadsTableReducer = createReducer<State>(initialState, (builder) => {
  builder
    .addCase(setReceiverLeadsSearch, (state, { payload }) => ({ ...state, search: payload.search }))
    .addCase(setSelectedReceiverLeads, (state, { payload }) => ({ ...state, selected_leads: payload.selected_leads }))
    .addCase(setReceiverLeadsFilters, (state, { payload }) => ({
      ...state,
      filters: {
        ...payload.filters,
        after_date: moment(payload.filters.after_date).format('YYYY-MM-DD HH:mm:ss'),
        before_date: moment(payload.filters.before_date).format('YYYY-MM-DD HH:mm:ss'),
      },
      filteredIds: payload.filters.field_ids.length ? state.filteredIds : null,
    }))
    .addCase(setReceiverLeadsColumns, (state, { payload }) => ({ ...state, columns: payload.columns }))
    .addCase(resetReceiverLeadsFilters, (state) => ({ ...state, filters: initialState.filters, filteredIds: null }))
    .addCase(toggleReceiverLeadsColumn, (state, { payload }) => ({
      ...state,
      columns: state.columns.map((column) => (column.id === payload.column.id ? payload.column : column)),
    }))
    .addCase(sortReceiverLeads, (state, { payload }) => ({ ...state, sort: payload.sort }));
});
