import { createAction } from '@reduxjs/toolkit';
import { Column, Sort } from '../../../components/table';
import { OutgoingLeadsFilters } from './reducer';

export const setOutgoingLeadsSearch = createAction<{ search: string }>('SET_OUTGOING_LEADS_SEARCH');
export const setSelectedOutgoingLeads = createAction<{ selected_leads: number[] }>('SET_SELECTED_OUTGOING_LEADS');
export const setOutgoingLeadsFilters = createAction<{ filters: OutgoingLeadsFilters }>('SET_OUTGOING_LEADS_FILTERS');
export const setOutgoingLeadsColumns = createAction<{ columns: Column[] }>('SET_OUTGOING_LEADS_COLUMNS');
export const resetOutgoingLeadsFilters = createAction('RESET_OUTGOING_LEADS_FILTERS');
export const toggleOutgoingLeadsColumn = createAction<{ column: Column }>('TOGGLE_OUTGOING_LEADS_COLUMN');
export const sortOutgoingLeads = createAction<{ sort: Sort }>('SORT_OUTGOING_LEADS');
