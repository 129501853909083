import { createSelector } from '@reduxjs/toolkit';
import { intersectionWith } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { DistributionSetting, DistributionSettingWithRelations } from '../distribution-settings';
import { loadCustomersRequest } from './actions';
import { adapter } from './reducer';
import { CustomerState, CustomerTimeEventStatus, DateRange } from './types';
import { AssociationsState, selectAssociations } from '../../associations';
import { Nature, selectAll as selectAllNatures } from '../natures';
import { selectAll as selectAllDistributionSettings } from '../distribution-settings';

export const customersSelector = (state: RootState) => state.entities.customers;
export const { selectAll, selectById, selectHistory } = adapter.selectors<CustomerState>(customersSelector);

export function selectCustomers() {
  return createSelector(selectAll, customersSelector, (customers, { loading, loaded, error }) => ({
    entities: customers,
    loading,
    loaded,
    error,
  }));
}

type Association = AssociationsState['customers']['natures'][0];

export function selectBySettings(setting: DistributionSettingWithRelations) {
  return createSelector(selectAll, selectAssociations, (customers, associations) => {
    const settingNatureAssociations = associations?.distributionSettings?.natures?.[setting.id]?.ids ?? [];
    const customersWithAssociatingCountries = customers.filter(
      (customer) => intersectionWith(customer.languages, setting.languages, (a, b) => a === b.code).length
    );

    const customersWithAssociatingNatures = associations?.customers?.natures
      ? Object.entries(associations.customers.natures)
          .map(([id, association]) => {
            const includesIds = (association as Association).ids.some((id) => settingNatureAssociations.includes(id));
            return includesIds ? customers.find((customer) => customer.id === Number(id)) : null;
          })
          .filter((customer) => !!customer)
      : [];

    return intersectionWith(
      customersWithAssociatingNatures,
      customersWithAssociatingCountries,
      (a, b) => a?.id === b.id
    );
  });
}

export type DistributionSettingWithNatureIds = Omit<DistributionSetting, 'natures'> & { natures: number[] };

export function selectFirstMatchingDistributionSetting(natures: Nature[], languages: string[]) {
  const natureIds = natures.map(({ nature_id }) => nature_id);
  return createSelector(selectAllDistributionSettings, selectAllNatures, (settings, allNatures) => {
    return (settings as unknown as DistributionSettingWithNatureIds[]).find(
      (setting) =>
        setting.languages.some((language) => languages.includes(language)) &&
        setting.natures
          .map((id) => allNatures.find((nature) => nature.id === id))
          .some((nature) => nature && natureIds.includes(nature.nature_id))
    );
  });
}

export function selectCustomerTimeEventStatus(customer: CustomerState): CustomerTimeEventStatus {
  if (customer.subscription_end && moment().isAfter(customer.subscription_end)) {
    return { type: 'expired', date: customer.subscription_end };
  } else if (customer.subscription_start && moment().isBefore(customer.subscription_start)) {
    return { type: 'scheduled', date: customer.subscription_start };
  } else if (dayIsExcluded(customer.active_days)) {
    return { type: 'scheduled', date: nextScheduledDay(customer.active_days) };
  } else if (dateIsExcluded(customer.date_ranges)) {
    return { type: 'scheduled', date: nextScheduledDate(customer.date_ranges) };
  }
  return null;
}

function dayIsExcluded(activeDays: number[]): boolean {
  return !!activeDays.length && !activeDays.includes(moment().weekday());
}

function nextScheduledDay(activeDays: number[]): string {
  let scheduledDay = moment();

  while (scheduledDay.isBefore(moment().add(1, 'week'))) {
    scheduledDay.add(1, 'day');
    if (activeDays.includes(scheduledDay.weekday())) {
      return scheduledDay.format();
    }
  }

  return scheduledDay.format();
}

function dateIsExcluded(dateRanges: DateRange[]): boolean {
  for (const dateRange of dateRanges) {
    if (dateRange.start && !dateRange.end) {
      if (moment().isSameOrAfter(dateRange.start)) {
        return true;
      }
    } else if (!dateRange.start && dateRange.end) {
      if (moment().isSameOrBefore(dateRange.end)) {
        return true;
      }
    } else if (dateRange.start && dateRange.end) {
      if (moment().isBetween(dateRange.start, dateRange.end)) {
        return true;
      }
    }
  }

  return false;
}

function nextScheduledDate(dateRanges: DateRange[]): string {
  for (const dateRange of dateRanges) {
    if (dateRange.start && !dateRange.end) {
      if (moment().isSameOrAfter(dateRange.start)) {
        return 'No end-date';
      }
    } else if ((!dateRange.start && dateRange.end) || (dateRange.start && dateRange.end)) {
      if (moment().isSameOrBefore(dateRange.end)) {
        return dateRange.end;
      }
    }
  }

  return moment().format();
}

export function useAndFetchCustomers() {
  const { loaded, loading, error } = useSelector(customersSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded && !loading && !error) {
      dispatch(loadCustomersRequest());
    }
  }, [dispatch, loaded, loading, error]);

  const entities = useSelector(selectAll);
  return { loaded, loading, error, entities };
}
