import { PayloadAction } from '@reduxjs/toolkit';
import { FailurePayload, SuccessPayload } from '../../effects';
import { showErrorNotification, showSuccessNotification } from '../../ui/notifications';
import { put, takeEvery } from 'redux-saga/effects';
import {
  creditLeadsFailure,
  creditLeadsForCustomerFailure,
  creditLeadsForCustomerSuccess,
  creditLeadsSuccess,
  loadCustomerLeadsFailure,
  searchCustomerLeadsFailure,
  updateCustomerLeadFailure,
  updateCustomerLeadRequest,
  updateCustomerLeadSuccess,
} from './actions';

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load', label: 'Retry', action: request }));
}

export function* updateSuccess({ payload: { response } }: PayloadAction<SuccessPayload>) {
  const undoAction = updateCustomerLeadRequest({ id: response.data.id, flag: response.data.flag });
  yield put(showSuccessNotification({ message: 'Updated customer lead', label: 'Undo', action: undoAction }));
}

export function* creditFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to credit leads', label: 'Credit', action: request }));
}

export function* creditSuccess({ payload: { response } }: PayloadAction<SuccessPayload>) {
  const { failed, success } = response.data;

  if (failed === 0 && success === 0) {
    yield put(
      showSuccessNotification({
        message: `${response.data.status.message}`,
      })
    );
  } else if (failed === 0) {
    yield put(
      showSuccessNotification({
        message: `Credit leads result, successfully credited leads: ${success}`,
      })
    );
  } else {
    yield put(
      showErrorNotification({
        message: `Credit leads result, failed to credit (id): ${failed}, successfully credited leads: ${success}`,
      })
    );
  }
}

export function* effects() {
  yield takeEvery(loadCustomerLeadsFailure, loadFailure);
  yield takeEvery(searchCustomerLeadsFailure, loadFailure);
  yield takeEvery(updateCustomerLeadFailure, loadFailure);
  yield takeEvery(updateCustomerLeadSuccess, updateSuccess);
  yield takeEvery(creditLeadsFailure, creditFailure);
  yield takeEvery(creditLeadsSuccess, creditSuccess);
  yield takeEvery(creditLeadsForCustomerFailure, creditFailure);
  yield takeEvery(creditLeadsForCustomerSuccess, creditSuccess);
}
