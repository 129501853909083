import { times } from 'lodash';
import React from 'react';

import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export interface TableSkeletonProps {
  rows?: number;
  columns?: number;
}

export function TableSkeleton({ rows = 15, columns = 5 }: TableSkeletonProps) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {times(columns).map((key: number) => (
            <TableCell key={key}>
              <Skeleton variant="text" animation="wave" width={140} height={24} />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {times(rows).map((key: number) => (
          <TableRow key={key}>
            {times(columns).map((key: number) => (
              <TableCell key={key}>
                <Skeleton variant="text" animation="wave" width={140} height={24} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
