import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../reducer';
import { loadCountriesRequest } from './actions';
import { adapter, Country } from './reducer';

export const selectCountries = (state: RootState) => state.entities.countries;
export const { selectAll, selectById, selectHistory } = adapter.selectors(selectCountries);
export const selectExisting = createSelector(selectAll, (countries: Country[]) =>
  countries.filter((country) => country.status !== 'deleted')
);

export function useCountries() {
  const { loaded, loading, error } = useSelector(selectCountries);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded && !loading && !error) {
      dispatch(loadCountriesRequest());
    }
  }, [dispatch, loaded, loading, error]);

  return useSelector<RootState, Country[]>(selectExisting);
}
