import { combineReducers } from 'redux';
import { reducer as associationsReducer } from './associations';
import { authReducer } from './auth';
import { dateRange } from './DateRange/dateRangeReducer';
import { entitiesReducer } from './entities';
import { enumsReducer } from './enums/reducer';
import { invoice } from './Invoice/invoiceReducer';
import { reducer } from './LeadMail';
import { supportMailReducer } from './support-mail/reducer';
import { reducer as tools } from './tools/reducer';
import { uiReducer } from './ui/reducer';
import { editWelcomeEmail, welcomeEmail } from './WelcomeEmail/welcomeEmailReducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  entities: entitiesReducer,
  associations: associationsReducer,
  enums: enumsReducer,
  supportMail: supportMailReducer,
  leadMail: reducer,
  ui: uiReducer,
  tools,
  dateRange,
  editWelcomeEmail,
  invoice,
  welcomeEmail,
});

export type RootState = ReturnType<typeof rootReducer>;
