import { createReducer } from '@reduxjs/toolkit';

import { orderBy } from 'lodash';

import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';

import {
  addFieldAccessSuccess,
  loadFieldAccessFailure,
  loadFieldAccessRequest,
  loadFieldAccessSuccess,
  recoverFieldAccessSuccess,
  removeFieldAccessSuccess,
  restoreFieldAccessSuccess,
  updateFieldAccessSuccess,
} from './actions';

export type Status = 'active' | 'disabled' | 'deleted';

export interface FieldAccess {
  id: number;
  status: Status;
  name: string;
  location: 'form' | 'system' | 'generated';
  rules: FieldAccessRule[];
}

export interface FieldAccessRule {
  access: Array<'leadbox' | 'email' | 'connection' | 'view'>;
  rows: Array<{
    field: string;
    value: string;
    location: FieldAccessLocation;
  }>;
}

export type FieldAccessLocation = 'receiver' | 'customer' | 'form' | 'system' | 'generated';

export const adapter: EntityAdapter<FieldAccess> = createEntityAdapter<FieldAccess>({
  selectId: (entity) => entity.id,
  sort: (entities) => orderBy(entities, (entity) => entity.name),
});

export const reducer = createReducer<EntityState<FieldAccess>>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadFieldAccessRequest, (state) => adapter.setLoading(state))
    .addCase(loadFieldAccessSuccess, (state, { payload }) => adapter.addMany(payload.response.items, state))
    .addCase(loadFieldAccessFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(addFieldAccessSuccess, (state, { payload }) => adapter.add(payload.response.data, state))
    .addCase(updateFieldAccessSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, payload.data.body, state)
    )
    .addCase(restoreFieldAccessSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, payload.data.body, state)
    )
    .addCase(recoverFieldAccessSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: 'active' }, state)
    )
    .addCase(removeFieldAccessSuccess, (state, { payload }) =>
      adapter.update(payload.data.id, { status: 'deleted' }, state)
    );
});
