import { times } from 'lodash';
import React from 'react';

import { Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export interface ListSkeletonProps {
  items?: number;
}

export function ListSkeleton({ items = 5 }: ListSkeletonProps) {
  return (
    <List>
      {times(items).map((key: number) => (
        <ListItem key={key}>
          <ListItemAvatar>
            <Skeleton variant="circle" animation="wave" width={40} height={40} />
          </ListItemAvatar>
          <ListItemText>
            <Skeleton variant="text" animation="wave" width={200} height={24} />
          </ListItemText>
          <ListItemSecondaryAction>
            <Grid container spacing={1} alignItems="center">
              {times(3).map((key: number) => (
                <Grid item key={key}>
                  <Skeleton variant="circle" animation="wave" width={40} height={40} />
                </Grid>
              ))}
            </Grid>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}
