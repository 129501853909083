import { createReducer } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import {
  loadOutgoingLeadsFromIncomingIdFailure,
  loadOutgoingLeadsFromIncomingIdRequest,
  loadOutgoingLeadsFromIncomingIdSuccess,
} from './actions';
import { OutgoingLead } from '../outgoingLeads';

export interface State extends EntityState<OutgoingLead> {}

export const adapter: EntityAdapter<OutgoingLead> = createEntityAdapter<OutgoingLead>({
  sort: (entities) => orderBy(entities, 'id'),
});

export const reducer = createReducer<State>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadOutgoingLeadsFromIncomingIdRequest, (state) => adapter.setLoading(state))
    .addCase(loadOutgoingLeadsFromIncomingIdSuccess, (state, { payload }) =>
      adapter.replace(payload.response.items, state, payload.response.total_count)
    )
    .addCase(loadOutgoingLeadsFromIncomingIdFailure, (state, { payload }) => adapter.setError(payload.error, state));
});
