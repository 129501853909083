import { createReducer } from '@reduxjs/toolkit';
import { hideSidebar, showSidebar } from './actions';

type State = {
  open: boolean;
};

export const sidebarReducer = createReducer<State>({ open: false }, (builder) => {
  builder.addCase(showSidebar, () => ({ open: true }))
  builder.addCase(hideSidebar, () => ({ open: false }))
})
