import { createReducer } from '@reduxjs/toolkit';
import { loadLeadmailFailure, loadLeadmailRequest, loadLeadmailSuccess } from './actions';

export interface LeadMail {
  mailData: {
    status: string;
    timestamp: number;
    message: {
      to: string[];
      tls: boolean;
      reply: boolean;
      subject: string;
      body: {
        text: string;
        html: string;
      };
    };
    events: Array<{
      /** It will most likely be one of the string literals, but it's not guaranteed. **/
      type: ('delivered' | 'bounced' | 'opened' | 'spam') | string;
      vendor: { alias: string; name: string };
      timestamp: number;
    }>;
  };
  loading: boolean;
  error?: string;
}

export const reducer = createReducer<Partial<LeadMail>>({}, (builder) => {
  builder.addCase(loadLeadmailRequest, (state) => ({ ...state, loading: true }));
  builder.addCase(loadLeadmailFailure, (state, { payload: { error } }) => ({ ...state, loading: false, error }));
  builder.addCase(loadLeadmailSuccess, (state, { payload }) => ({ mailData: payload.response as any, loading: false }));
});
