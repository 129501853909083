import { createRequestActions, get } from '../../effects';

export const {
  request: loadReceiverLeadRequest,
  success: loadReceiverLeadSuccess,
  failure: loadReceiverLeadFailure,
} = createRequestActions('LOAD_RECEIVER_LEAD', ({ receiverLeadId }: { receiverLeadId: string }) => {
  return {
    http: get('leads/receiver/id', {
      customer_lead_id: receiverLeadId,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }),
  };
});
