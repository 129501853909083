import { createSelector } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { EntityState } from '../adapter';
import { loadFieldAccessRequest } from './actions';
import { adapter, FieldAccess } from './reducer';

export const selectFieldAccess = (state: RootState): EntityState<FieldAccess> => state.entities.fieldAccesses;

export const { selectAll, selectById, selectHistory } = adapter.selectors(selectFieldAccess);

export const selectExisting = createSelector(selectAll, (entities: FieldAccess[]) =>
  entities.filter((entity) => entity.status !== 'deleted')
);

export function useFieldAccess() {
  const { loaded, loading, error } = useSelector(selectFieldAccess);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded && !loading && !error) {
      dispatch(loadFieldAccessRequest());
    }
  }, [dispatch, loaded, loading, error]);

  const entities = useSelector<RootState, FieldAccess[]>(selectExisting);
  return { loaded, loading, error, entities };
}
