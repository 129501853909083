import { toast } from 'react-toastify';

/**
 * Most of these functions are used in legacy views, and you should try to avoid using them. Exceptions are 'downloadAsFile' and 'getDefaultConnectionId'.
 */

export function notifyMessage(success: boolean, message: string) {
  return success ? toast(message) : toast.error('Sorry, an error occured. ' + message);
}

export function isJson(item: string | object) {
  item = typeof item !== "string"
    ? JSON.stringify(item)
    : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  return typeof item === "object" && item !== null;
}

export function getDefaultConnectionId() {
  const connectionId = process.env.REACT_APP_DEFAULT_RECEIVER_CONNECTIONS;

  if (!connectionId) {
    return;
  }

  return Number(connectionId);
}

export function insertNextToLast<T>(list: T[], itemToInsert: T | T[]) {
  const listToInsert = Array.isArray(itemToInsert) ? itemToInsert : [itemToInsert];
  const first = list.slice(0, list.length - 1);
  const last = list[list.length - 1];
  return [...first, ...listToInsert, last];
}

export function isEmailHeaderColumn(item: string) {
  return item.includes('h1') || item.includes('h2') || item.includes('h3') || item.includes('h4');
}

type FileFormat = 'json' | 'csv' | 'xlsx';

export async function downloadAsFile(fileName: string, data: object | any[], format: FileFormat) {
  const content = await parseDocumentData(format, data);
  const url = window.URL.createObjectURL(content);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function parseDocumentData(format: FileFormat, data: any) {
  switch (format) {
    case 'json':
      return new Blob([JSON.stringify(data)], { type: 'application/json;charset=utf-8;' });
    case 'csv':
      return new Blob(['\ufeff' + data], { type: 'text/csv;charset=utf-8;' });
    case 'xlsx':
      return fetch(
        `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`
      ).then(result => result.blob());
    default:
      throw new Error(`Could not download file, unknown format ${format}`);
  }
}
