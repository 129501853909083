import { createReducer } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import { ReceiverLead } from '../receiverLeads';
import { loadReceiverLeadFailure, loadReceiverLeadRequest, loadReceiverLeadSuccess } from './actions';

export interface State extends EntityState<ReceiverLead> {}

export const adapter: EntityAdapter<ReceiverLead> = createEntityAdapter<ReceiverLead>({
  sort: (entities) => orderBy(entities, 'id'),
});

export const reducer = createReducer<State>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadReceiverLeadRequest, (state) => adapter.setLoading(state))
    .addCase(loadReceiverLeadSuccess, (state, { payload }) =>
      adapter.replace(payload.response.items, state, payload.response.total_count)
    )
    .addCase(loadReceiverLeadFailure, (state, { payload }) => adapter.setError(payload.error, state));
});
