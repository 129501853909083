import { Id } from '../adapter';
import { createRequestActions, makeHttpPayload } from '../../effects';

import { FieldAccess } from './reducer';

export type FieldAccessData = Partial<Exclude<FieldAccess, 'id'>>;

export const {
  request: loadFieldAccessRequest,
  success: loadFieldAccessSuccess,
  failure: loadFieldAccessFailure,
} = createRequestActions('LOAD_FIELD_ACCESS', () => ({
  http: makeHttpPayload('get', 'field-access'),
}));

export const {
  request: addFieldAccessRequest,
  success: addFieldAccessSuccess,
  failure: addFieldAccessFailure,
} = createRequestActions('ADD_FIELD_ACCESS', ({ body }: { body: FieldAccessData }) => ({
  http: makeHttpPayload('post', 'field-access', body),
}));

export const {
  request: updateFieldAccessRequest,
  success: updateFieldAccessSuccess,
  failure: updateFieldAccessFailure,
} = createRequestActions('UPDATE_FIELD_ACCESS', ({ id, body }: { id: Id; body: FieldAccessData }) => ({
  http: makeHttpPayload('post', `field-access/${id}`, body),
  data: { id, body },
}));

export const {
  request: removeFieldAccessRequest,
  success: removeFieldAccessSuccess,
  failure: removeFieldAccessFailure,
} = createRequestActions('REMOVE_FIELD_ACCESS', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `field-access/${id}`, { status: 'deleted' }),
  data: { id },
}));

export const {
  request: restoreFieldAccessRequest,
  success: restoreFieldAccessSuccess,
  failure: restoreFieldAccessFailure,
} = createRequestActions('RESTORE_FIELD_ACCESS', ({ id, body }: { id: Id; body: FieldAccessData }) => ({
  http: makeHttpPayload('post', `field-access/${id}`, body),
  data: { id, body },
}));

export const {
  request: recoverFieldAccessRequest,
  success: recoverFieldAccessSuccess,
  failure: recoverFieldAccessFailure,
} = createRequestActions('RECOVER_FIELD_ACCESS', ({ id }: { id: Id }) => ({
  http: makeHttpPayload('post', `field-access/${id}`, { status: 'active' }),
  data: { id },
}));
