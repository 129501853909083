import { RootState } from '../../reducer';
import { loadIanaCountriesRequest } from './actions';
import { adapter, IanaCountry } from './reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export const selectIanaCountries = (state: RootState) => state.entities.ianaCountries;
export const { selectAll } = adapter.selectors(selectIanaCountries);

export function useIanaCountries() {
  const { loaded, loading, error } = useSelector(selectIanaCountries);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded && !loading && !error) {
      dispatch(loadIanaCountriesRequest());
    }
  }, [dispatch, loaded, loading, error]);

  return useSelector<RootState, IanaCountry[]>(selectAll);
}
