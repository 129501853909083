import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import { FailurePayload, SuccessPayload } from '../../effects';
import { showErrorNotification, showSuccessNotification } from '../../ui/notifications';
import {
  loadCustomerLeadsFromIncomingIdFailure,
  updateCustomerLeadsFromIncomingIdFailure,
  updateCustomerLeadsFromIncomingIdRequest,
  updateCustomerLeadsFromIncomingIdSuccess,
} from './actions';

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load', label: 'Retry', action: request }));
}

export function* updateSuccess({ payload: { response } }: PayloadAction<SuccessPayload>) {
  const undoAction = updateCustomerLeadsFromIncomingIdRequest({ id: response.data.id, flag: response.data.flag });
  yield put(showSuccessNotification({ message: 'Updated customer lead', label: 'Undo', action: undoAction }));
}

export function* effects() {
  yield takeEvery(loadCustomerLeadsFromIncomingIdFailure, loadFailure);
  yield takeEvery(updateCustomerLeadsFromIncomingIdFailure, loadFailure);
  yield takeEvery(updateCustomerLeadsFromIncomingIdSuccess, updateSuccess);
}
