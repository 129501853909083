import { all, put, select, takeEvery } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';
import { history } from '../../../history';
import { FailurePayload, SuccessPayload } from '../../effects';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from '../../ui/notifications';
import {
  createReceiverFailure,
  createReceiverSuccess,
  loadReceiversFailure,
  loadReceiversRequest,
  recoverReceiverFailure,
  recoverReceiverRequest,
  recoverReceiverSuccess,
  restoreReceiverFailure,
  restoreReceiverRequest,
  restoreReceiverSuccess,
  updateReceiverFailure,
  updateReceiverSuccess,
} from './actions';
import { selectHistory } from './selectors';
import { loadNaturesRequest } from '../natures';
import { loadCountriesRequest } from '../countries';
import { loadFieldsRequest } from '../../enums/fields';
import { addAssociationsRequest } from '../../associations';
import { copyReceiverRulesRequest } from '../rules';

export function* loadRequest() {
  yield all([put(loadNaturesRequest()), put(loadCountriesRequest()), put(loadFieldsRequest())]);
}

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load', label: 'Retry', action: request }));
}

export function* createSuccess({ payload: { response, meta } }: PayloadAction<SuccessPayload>) {
  yield put(showSuccessNotification({ message: 'Receiver created', label: 'Undo' }));

  if (meta?.copyRules) {
    yield put(
      copyReceiverRulesRequest({
        body: { copyFrom: meta.copyFrom, copyTo: response.data.id },
        meta: { copyTo: response.data.id },
      })
    );
  }

  if (meta.redirectAfterCreation && !meta.copyRules){
    history.push(`/receivers/${response.data.id}`);
  }
}

export function* addDefaultConnection({ payload: { response, meta } }: PayloadAction<SuccessPayload>) {
  // When you create a new receiver it should in most cases be created with a default connection-id. This should ideally be configurable.
  if (meta.defaultConnectionId) {
    yield put(
      addAssociationsRequest({
        ids: [meta.defaultConnectionId],
        entityId: response.data.id,
        entityName: 'receivers',
        associatedEntityName: 'connections',
      })
    );
  }
}

export function* createFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to create', label: 'Retry', action: request }));
}

export function* updateSuccess({ payload: { data } }: PayloadAction<SuccessPayload>): Generator {
  const receiver: any = yield select(selectHistory(data.id));
  const undoAction = restoreReceiverRequest({ id: receiver.id, body: receiver });
  yield put(showSuccessNotification({ message: 'Receiver updated', label: 'Undo', action: undoAction }));
}

export function* updateFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to update', label: 'Retry', action: request }));
}

export function* removeSuccess({ payload: { data } }: PayloadAction<SuccessPayload>) {
  const undoAction = recoverReceiverRequest({ id: data.id });
  yield put(showWarningNotification({ message: 'Receiver removed', label: 'Undo', action: undoAction }));
}

export function* removeFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to remove', label: 'Retry', action: request }));
}

export function* restoreSuccess() {
  yield put(showSuccessNotification({ message: 'Receiver restored' }));
}

export function* restoreFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to restore', label: 'Retry', action: request }));
}

export function* recoverSuccess() {
  yield put(showSuccessNotification({ message: 'Receiver recovered' }));
}

export function* recoverFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to recover', label: 'Retry', action: request }));
}

export function* effects() {
  yield takeEvery(loadReceiversRequest, loadRequest);
  yield takeEvery(loadReceiversFailure, loadFailure);
  yield takeEvery(createReceiverSuccess, createSuccess);
  yield takeEvery(createReceiverSuccess, addDefaultConnection);
  yield takeEvery(createReceiverFailure, createFailure);
  yield takeEvery(updateReceiverSuccess, updateSuccess);
  yield takeEvery(updateReceiverFailure, updateFailure);
  yield takeEvery(restoreReceiverSuccess, restoreSuccess);
  yield takeEvery(restoreReceiverFailure, restoreFailure);
  yield takeEvery(recoverReceiverSuccess, recoverSuccess);
  yield takeEvery(recoverReceiverFailure, recoverFailure);
}
