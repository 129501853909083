import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeEvery } from 'redux-saga/effects';
import { FailurePayload, SuccessPayload } from '../../effects';
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from '../../ui/notifications/actions';
import {
  addFieldTranslationFailure,
  addFieldTranslationSuccess,
  loadFieldTranslationsFailure,
  recoverFieldTranslationFailure,
  recoverFieldTranslationRequest,
  recoverFieldTranslationSuccess,
  removeFieldTranslationFailure,
  removeFieldTranslationRequest,
  removeFieldTranslationSuccess,
  restoreFieldTranslationFailure,
  restoreFieldTranslationRequest,
  restoreFieldTranslationSuccess,
  updateFieldTranslationFailure,
  updateFieldTranslationSuccess,
} from './actions';
import { selectHistory } from './selectors';

export function* loadFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to load', label: 'Retry', action: request }));
}

export function* addSuccess({ payload: { response } }: PayloadAction<SuccessPayload>) {
  const undoAction = removeFieldTranslationRequest({ id: response.data.id });
  yield put(showSuccessNotification({ message: 'Translations added', label: 'Undo', action: undoAction }));
}

export function* addFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to add', label: 'Retry', action: request }));
}

export function* updateSuccess({ payload: { data } }: PayloadAction<SuccessPayload>): Generator {
  const entity = yield select(selectHistory(data.id));
  const undoAction = restoreFieldTranslationRequest({ id: data.id, body: entity });
  yield put(showSuccessNotification({ message: 'Translations updated', label: 'Undo', action: undoAction }));
}

export function* updateFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to update', label: 'Retry', action: request }));
}

export function* removeSuccess({ payload: { data } }: PayloadAction<SuccessPayload>) {
  const undoAction = recoverFieldTranslationRequest({ id: data.id });
  yield put(showWarningNotification({ message: 'Translations removed', label: 'Undo', action: undoAction }));
}

export function* removeFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to remove', label: 'Retry', action: request }));
}

export function* restoreSuccess() {
  yield put(showSuccessNotification({ message: 'Translations restored' }));
}

export function* restoreFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to restore', label: 'Retry', action: request }));
}

export function* recoverSuccess() {
  yield put(showSuccessNotification({ message: 'Translations recovered' }));
}

export function* recoverFailure({ payload: { request } }: PayloadAction<FailurePayload>) {
  yield put(showErrorNotification({ message: 'Failed to recover', label: 'Retry', action: request }));
}

export function* effects() {
  yield takeEvery(loadFieldTranslationsFailure, loadFailure);
  yield takeEvery(addFieldTranslationSuccess, addSuccess);
  yield takeEvery(addFieldTranslationFailure, addFailure);
  yield takeEvery(updateFieldTranslationSuccess, updateSuccess);
  yield takeEvery(updateFieldTranslationFailure, updateFailure);
  yield takeEvery(removeFieldTranslationSuccess, removeSuccess);
  yield takeEvery(removeFieldTranslationFailure, removeFailure);
  yield takeEvery(restoreFieldTranslationSuccess, restoreSuccess);
  yield takeEvery(restoreFieldTranslationFailure, restoreFailure);
  yield takeEvery(recoverFieldTranslationSuccess, recoverSuccess);
  yield takeEvery(recoverFieldTranslationFailure, recoverFailure);
}
