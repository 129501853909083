import { createEntityAdapter, EntityAdapter, EntityState } from '../adapter';
import { orderBy } from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import {
  loadReceiverLeadsFailure,
  loadReceiverLeadsRequest,
  loadReceiverLeadsSuccess,
  searchReceiverLeadsFailure,
  searchReceiverLeadsRequest,
  searchReceiverLeadsSuccess,
} from './actions';
import { leadData } from '../incomingLeads';

export interface State extends EntityState<ReceiverLead> {}

export interface ReceiverLead {
  id: number;
  status: undefined;
  lead_data: leadData;
  [key: string]: any;
}
export const adapter: EntityAdapter<ReceiverLead> = createEntityAdapter<ReceiverLead>({
  sort: (entities) => orderBy(entities, 'id'),
});

export const reducer = createReducer<State>(adapter.initialState(), (builder) => {
  builder
    .addCase(loadReceiverLeadsRequest, (state) => adapter.setLoading(state))
    .addCase(loadReceiverLeadsSuccess, (state, { payload }) =>
      adapter.replace(
        payload.response.items,
        state,
        payload.response.total_count,
        payload.response.exclusive_count,
        payload.response.normal_count
      )
    )
    .addCase(loadReceiverLeadsFailure, (state, { payload }) => adapter.setError(payload.error, state))
    .addCase(searchReceiverLeadsRequest, (state) => adapter.setLoading(state))
    .addCase(searchReceiverLeadsSuccess, (state, { payload }) =>
      adapter.replace(
        payload.response.items,
        state,
        payload.response.total_count,
        payload.response.exclusive_count,
        payload.response.normal_count
      )
    )
    .addCase(searchReceiverLeadsFailure, (state, { payload }) => adapter.setError(payload.error, state));
});
